import styled from 'styled-components';
import {dFlex, mobileMedia} from '../../../../globalVariables/GlobalVariables';
import {alignStart, dFlexColumn, dFlexColumnAlineEnd, dFlexStartBetween, flexRow, justifyBetween, justifyEnd} from '../../../../globalVariables/GlobalFlex';
import {wrapper} from '../stepRight/StepRight.css';
import {wrapper as wrapperLeft} from '../StepLeft/StepLeft.css';

export const wrap = styled.div`
   ${dFlexStartBetween}
   svg{
       height:190px;
       width: 190px;
        ${mobileMedia}{
            height:150px;
            width: 150px;
            padding-block-start: 25px;
        }
    }
`;

export const wrapperDetails = styled.div`
   ${dFlexColumn}
    font-size: 14px;
    padding-block-start: 25px;
`;
export const title = styled.div`
    font-weight: bold;
`;
export const text = styled.div`
    
`;
export const massageText = styled.div`
    font-size: 12px;
`;
export const wrapReadyContent=styled.div< {$hasPop : boolean}>`
  ${dFlexColumnAlineEnd}
    width: 100%;
    padding-block-end:15px;
    ${wrapper}{
        width: 100%;
    }
    ${wrapperLeft}{
        width: 100%;
        ${flexRow};
        ${alignStart};
        ${p=>p.$hasPop?justifyEnd:justifyBetween};
        ${mobileMedia}{
            ${dFlex};
            ${flexRow};
            gap: 10px;
        }
    }
    .skip{
        display: none;
    }
`;