import styled from 'styled-components';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';
import zIndex from '../../../../globalVariables/zIndex';

export const pixelCircle=styled.div`
    z-index: ${zIndex.baseRaised};
	width:40px;
	height:40px;
    border-radius: 50%;
    position: absolute;
    transition:all 250ms;
    inset-inline-end: -20px;
    background-color: transparent;
    &:not(.edit){
        background-color: #FFFFFF;
    }

`;

export const pixelIcon=styled.div`
	width:40px;
	height:40px;
	${p=>BGImageCenter(p.icon,'23px')}
    position: absolute;
    transition:all 250ms;
    inset-inline-end: 0px;
    &.edit{
        inset-inline-end: 35px;
    }
`;

