import * as css from './videoForm.css';
import {useSelector} from 'react-redux';
import {WHITE} from '../../../globalVariables/GlobalVariables';
import Btn from '../../../component/button/btn/Btn';
import {useTranslation} from 'react-i18next';

function VideoFormPopBottom({formRef, remove, hasVideoId}){
    const {editVideoXHR, createVideoXHR, deleteVideoXHR} = useSelector((s: any)=>s.management);
    const {t} = useTranslation('layout');

    return (<css.bottom>
        {hasVideoId && <Btn style={`background-color: ${WHITE};`} className={deleteVideoXHR} onClick={remove} disabled={false} >
            {t('remove')}
        </Btn>}
        <Btn className={hasVideoId?editVideoXHR: createVideoXHR} onClick={formRef.current?.handleSubmit} disabled={false}>
            {t('save')}
        </Btn>
    </css.bottom>);
}

export default VideoFormPopBottom;