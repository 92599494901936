import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as css from './popupAfterAdvertise.css';
import {useSelector} from 'react-redux';
import copyToClipboard from '../../library/copyToClipboard';
import {addGooglePing, updateEventSettings} from '../../redux/event/eventActions';
import AttachedMessageWrapper from '../../pages/components/AttachedMessageWrapper/AttachedMessageWrapper';
import Btn from '../button/btn/Btn';
import {blackDuplicate, copy, search} from '../../assets/symbols/symbols/Symbols.css';
import {rocket} from '../../assets/symbols/images/rocket';
import useTimeout from '../../hooks/useTimeout';
import {useParams} from 'react-router-dom/cjs/react-router-dom.min';
import {useEffect} from 'react';
import Link from '../link/Link';
import useRestartXHR from '../../hooks/useRestartXHR';
import {getDomain} from '../../layout/eventMenu/formButtton/FormButtton';

function PopupAfterAdvertise({close, showClass}) {
    const {page} = useParams();
    const {t} = useTranslation('event');
    const {name, type, domain, landingPageUrl, settings ,addGooglePingXHR} = useSelector((s) => s.event);
    const {isIndexed} = settings.event;
    const background = '76D6E8';
    const link = getDomain(domain, landingPageUrl, name, type);
    const [copyText, setCopyText] = useState(t('copyLink'));
    const {isIndexedXHR} = useSelector(s => s.forms.eventSettings);

    useRestartXHR(isIndexedXHR, 'forms.eventSettings.isIndexedXHR');
    useRestartXHR(addGooglePingXHR, 'event.addGooglePingXHR');

    const addPingXHR = isIndexed && (addGooglePingXHR || isIndexedXHR);

    const timer = useTimeout();

    const copyLink = () =>{
        copyToClipboard(link).then();
        setCopyText(t('copied'));
        timer.current=setTimeout(() => {
            setCopyText(t('copyLink'));
        },3000);        
    };

    useEffect(()=>{
        if(page!='design'&& type!=='hub')
            close();
    },[page]);

    const onClickGooglePing=(isIndexed)=>{
        updateEventSettings('isIndexed',isIndexed,'event');
    };

    const btnArr= [
        {text:copyText,onClick:copyLink,icon:blackDuplicate},
        {text:addPingXHR==='SUCCESS'? t('advertised') : t('googleAdvertising'), onClick:()=>{isIndexed?addGooglePing():onClickGooglePing(1);},icon:search, className: addPingXHR}];
    return (
        <css.wrapper data-cy='popupAfterAdvertise'>
            <AttachedMessageWrapper close={close} showClass={showClass} background={background} >
                <css.main>
                    <css.icon icon={rocket}/>
                    <css.title >{t('pageIsLive')}</css.title>                    
                </css.main>
                <css.buttons>
                    <Link href={link}>
                        <Btn className={'white'}><css.btnIcon icon={copy}/>{t('viewThePage')}</Btn>
                    </Link>
                    {btnArr?.map((btn, index) => {
                        return <Btn key={index} onClick={btn.onClick} className={`white ${btn.className}`}><css.btnIcon className={btn.className} icon={btn.icon}/>{btn.text}</Btn>;})}
                </css.buttons>
                <css.remove>
                    {!!isIndexed && <Btn data-cy='remove' onClick={()=>onClickGooglePing(0)}>{t('RemoveFromGoogleSearch')}</Btn> }
                </css.remove>
            </AttachedMessageWrapper>
        </css.wrapper>
    );
}
export default PopupAfterAdvertise;