import styled from 'styled-components';
import {WHITE, LIGHT_BLUE3, BRIGHT_GREEN, BRIGHT_RED, BLACK, LIGHT_GRAY, mobileMedia} from '../../../../../../globalVariables/GlobalVariables';
import realtimeFonts from '../../../../fonts';
import {alignCenter, dFlex, dFlexColumn,  justifyEnd} from '../../../../../../globalVariables/GlobalFlex';

export const terminals = styled.div`
    ${dFlex}
    flex-direction: column;
    height: 100%;
    padding: 15px;
`;

export const wrapper = styled.div`
    background: ${WHITE};
    border-radius: 24px;
    border: 1px solid ${LIGHT_BLUE3};
    padding: 5px 10px;
    width: 247px;
    height: 44px;
    ${dFlex}
    ${alignCenter}
    ${mobileMedia}{
        width: 100%;
    }
`;

export const name = styled.h3`
    margin: 0;
    color: ${BLACK};
    ${realtimeFonts.textBold}
`;

export const profile = styled.div`
    height: 35px;
    width: 35px;
    background-color: ${LIGHT_GRAY};
    border-radius: 50%;
    padding-block: 5px;
    padding-inline: 10px;
`;

export const status = styled.span<{$isActive: boolean}>`
    position: relative;
    inset-inline-start: -8px;
    inset-block-start: -8px;
    background-color: ${p => p.$isActive ? BRIGHT_GREEN : BRIGHT_RED};
    height: 10px;
    width: 10px;
    border-radius: 50%;
`;

export const content = styled.div`

`;

export const validations = styled.div`
    color: ${BLACK};
    ${realtimeFonts.numbers}
`;

export const buttons = styled.div`
    ${dFlex};
    ${justifyEnd}
`;

export const terminalTitle = styled.h3`
    margin: 0 0 15px 0;
    ${realtimeFonts.windowTitle}
`;

export const terminalList = styled.div`
    ${dFlexColumn}
    ${alignCenter}
    gap: 10px;
    flex: 1;
`;

