import React, {useEffect, useRef, useState} from 'react';
import UserImage from '../../userImage/UserImage';
import * as All from '../../layoutUserProfileMenu/LayoutUserProfileMenu.css';
import EditUserDetails from './editUserDetails/EditUserDetails';
import {useSelector} from 'react-redux';
import {updateUserDetails} from '../../../../../redux/user/userActions';
import UserImageMenu from './editUserImage/UserImageMenu';
import {useTranslation} from 'react-i18next';
import ImageEditor from '../../../../../component/imageEditor/ImageEditor';
import Button from '../../../../../component/button/button/Button';
import {tsToDate} from '../../../../../library/dates';
import setGlobalState from '../../../../../redux/setGlobalState';
import useShow from '../../../../../hooks/useShow';
import CircleImageEdit from '../../../../../component/circleImageEdit/CircleImageEdit';

const EditUserProfile = ({close}) => {
    const {t} = useTranslation('layout');
    const user=useSelector(s=>s.user);
    const openEditImage = useShow();

    const [inputFields, setInputFields] = useState([{name:'firstName', value:user.nameFirst, hasSvg:true, isActive:false, enabled:true, title:t('firstName')},
        {name:'lastName', value:user.nameLast, hasSvg:true, isActive:false, enabled:true, title:t('lastName')},
        {name:'phone', value:user.phone, hasSvg:true, isActive:false, enabled:false, title:t('phone')},
        {name:'email', value:user.email, hasSvg:true, isActive:false, enabled:false, title:t('email')},
        {name:'langloc', value:user.langloc, hasSvg:true, isActive:false, enabled:false, title:t('language'), isSelect: true},
    ]);

    const [activeInputIndex, setActiveInputIndex] = useState(-1);

    const [editImgMenu, setEditImgMenu] = useState(false);
    const [image, setImage] = useState();
    const [saveState, setSaveState] = useState('');

    const updateEditImgMenu = () => {
        let temp = ! editImgMenu;
        setEditImgMenu(temp);
    };

    const clickFieldCtrHandler = (index) => {
        if(inputFields[index].name === 'phone' && inputFields[index].isActive){return;}
        if(!inputFields[index].enabled){return;}
        let temp = [...inputFields];
        if(activeInputIndex !== -1)
            temp[activeInputIndex].isActive = false;
        if(index !== activeInputIndex)
            temp[index].isActive = !temp[index].isActive;
        else
            index = -1;
        setActiveInputIndex(index);
        setInputFields(temp);
    };

    const updateUserDetailsHandler = (e) => {
        const {name, value} = e.target;
        let temp = inputFields.map((ipt) =>{
            if(ipt.name !== name){
                return ipt;
            }
            ipt.value = value;
            return ipt;
        });
        setInputFields(temp);
    };

    const updateUser = () =>{
        let updateUser = {};
        inputFields.map(ipt => {
            updateUser[ipt.name] = ipt.value;
        });
        localStorage.setItem('user.langloc', JSON.stringify(updateUser.langloc));
        const [language, locale] = updateUser.langloc.split('_');
        updateUserDetails({...updateUser, language, locale});
        close();
    };
    const saveImage=(img)=>{
        updateUserDetails({picture:img});
    };
    const uploadImage=(files)=>{
        setImage(files);
        openEditImage.open();
    };

    useEffect(()=>{
        let saveS=user.updateUserDetailsXHR;
        saveS=saveS==='LOADING'?'process':saveS==='FAILED'?'error':saveS;
        setSaveState(saveS);
        if(saveS==='SUCCESS' ||saveS==='error'){
            setTimeout(function (){
                setGlobalState('user.updateUserDetailsXHR',null);
            },1500);
        }
    },[user.updateUserDetailsXHR]);
    
    const deleteImage = () =>{
        updateUserDetails({picture:'default'});
    };

    const saveBtnProps = {
        text:  user.updateUserDetailsXHR==='SUCCESS'?t('saved'):t('save'),
        textColor: user.updateUserDetailsXHR==='SUCCESS'?'#9CB900':'#484848',
        backgroundColor: '',
        borderColor: user.updateUserDetailsXHR==='SUCCESS'?'#fff':'#484848',
        successColor:'9CB900',
        hoverTextColor: '#76d6e8',
        hoverBackgroundColor: '#fff',
        hoverBorderColor: '#76d6e8',
        class: 'save '+saveState,
        onClick: updateUser,
    };
    let lastConnection = JSON.parse(user.lastConnect);
    let formatted = tsToDate(lastConnection?.last, user.langloc, user?.timeZone, 'dateAndTime');

    const isDefaultImg = user.picture?.endsWith('userProfile.jpg');

    return <All.WrapperEditProfileDiv>
        <All.EditProfileHeaderCtr>
            <All.EditUserTop >
                <All.EditUserSave><Button BtnProps={saveBtnProps} /></All.EditUserSave>
                <CircleImageEdit image={user.picture}
                    setImage={uploadImage} 
                    deleteImage={deleteImage}
                    saveImage={saveImage} 
                    size={{height:125,width:125}} 
                    isDefaultImg={isDefaultImg}
                />
                <All.UserNameStyled className={'bold'}>{user.nameFirst + ' '+user.nameLast}</All.UserNameStyled>
                <All.UserConnectionTimeStyled>{t('lastLogin') + formatted}</All.UserConnectionTimeStyled>
            </All.EditUserTop>
        </All.EditProfileHeaderCtr>
        <All.EditProfileBodyCtr>
            <EditUserDetails inputFields={inputFields}
                inputChange={updateUserDetailsHandler}
                activeIdx={activeInputIndex}
                clickOut = {clickFieldCtrHandler}
            />
        </All.EditProfileBodyCtr>
    </All.WrapperEditProfileDiv>;
};

export default EditUserProfile;
