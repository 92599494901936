import * as css from './PixelOptions.css';
import ModeBtn from '../../../../component/modeBtn/ModeBtn';
import {pencil, remove} from '../../../../assets/symbols/symbols/Symbols.css';
import {useTranslation} from 'react-i18next';
import Tooltip from '../../../../component/tooltip/Tooltip';
import {useContext} from 'react';
import {pixelContext} from '../../PixelsPage/PixelsPage';
import useTimeout from '../../../../hooks/useTimeout';
import {deleteCustomPixel, deleteSpecialPixel, updateCustomPixel, updateSpecialPixel} from '../../../../redux/publish/publishActions';
import {ExtractContent, RemoveTagContent} from '../../../../library/ExtractContent';

function PixelOptions({pixel,setIsEditMode,setIsDeleted}) {
    const {t}=useTranslation('publish');
    const {pixelId,pixelEnable}= pixel;
    const {getOpenPixel}=useContext(pixelContext);
    const onOpenPixel=getOpenPixel(pixel);

    const [onDelete,onUpdate]=pixel.key==='customPixel'?
        [deleteCustomPixel,updateCustomPixel]:
        [deleteSpecialPixel,updateSpecialPixel];
        
    const html= pixel? RemoveTagContent(pixel?.pixel) : '';
    const js= pixel? ExtractContent(pixel?.pixel, 'script'): '';
    const Css= pixel? ExtractContent(pixel?.pixel, 'style'): '';

    const timer=useTimeout();
    
    const onDeletePixel=(e)=>{
        e.stopPropagation();
        setIsDeleted(true);
        timer.current=setTimeout(() => {
            onDelete(pixelId);
        }, 300);
    };
    return (
        <>  
            <Tooltip text={t('delete')}>
                <css.icon icon={remove} onClick={onDeletePixel} data-cy='deletePixel'/>
            </Tooltip>  
            <Tooltip text={t('edit')}>
                <css.icon icon={pencil} onClick={onOpenPixel||(()=>setIsEditMode(true))} data-cy='editPixel'/>
            </Tooltip>              
            <ModeBtn className=" small " status={pixelEnable} click={(_status,e)=>{
                e.stopPropagation();
                onUpdate(pixelId,{pixelEnable:!pixelEnable,html,js,css:Css});}} dataCyName={'pixel'}/>        
        </>
    );
}
export default PixelOptions;
