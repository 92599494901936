import {useTranslation} from 'react-i18next';
import * as css from './insightsCardData.css';
import ButtonIcon from '../../../../../../component/button/buttonIcon/buttonIcon';
function InsightsCardData({mainStats, secondStats, showButtons=false}){

    const {t} = useTranslation('promotionMessage');
        
    return (
        <>
            <css.rightSection>
                <css.mainLine>
                    {mainStats?.map((stat, index) => (stat.number || stat.number ===0) && <MainData key={index} index={index} {...stat} /> )}
                </css.mainLine>
                <css.secondLine>
                    {secondStats?.map((stat, index) => (
                        (stat.number || stat.number ===0) && <SecondData key={index} index={index} {...stat} />
                    ))}
                </css.secondLine>
            </css.rightSection>
            {showButtons && <css.leftSection>
                <css.Buttons>
                    <ButtonIcon>{t('allEvents')}</ButtonIcon>
                    <ButtonIcon iconName='pen'>{t('editText')}</ButtonIcon>
                </css.Buttons>
            </css.leftSection>}
        </>
    );
}

export default InsightsCardData;

export function MainData ({index, number, text, icon}){

    const {t} = useTranslation('promotionMessage');

    return (
        <css.item key={index}>
            {icon && <css.icon icon={typeof icon === 'string'? icon: ''} >{typeof icon === 'string'?'':icon()}</css.icon> }
            <css.number >{number}</css.number>
            <css.text>{t(text)}</css.text>
        </css.item>
    );
}

export function SecondData({index, number, text}){

    const {t} = useTranslation('promotionMessage');

    return (
        <css.secondItem key={index}>
            <css.number >{number}</css.number>
            <css.text>{t(text)}</css.text>
        </css.secondItem>
    );
}