import * as css from './TicketsRow.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Part from '../../../../../tickets/components/ticketRow/part/Part';
import Detail from '../../../../../tickets/components/ticketRow/Detail/Detail';
import useShow from '../../../../../../hooks/useShow';
import TicketsRow, {getMaxPrice, getMinPrice} from './TicketsRow';

export const getDiscount = (ticket) =>{
    let discount = 0;
    if(ticket?.prices.length >1){
        ticket?.prices.forEach((prices)=>{
            discount += ((prices.soldPrice??0) - ((prices.sold??0) * prices.price));
        });
    } else {
        discount = (ticket?.soldPrice??0) - ((ticket?.sold??0) * ticket?.price);
    }
    return discount;
};

const TicketRowTicket = ({ticket,active,index,setActiveIndex}) => {
    const {t} = useTranslation('ticket');
    const currencySymbol = useSelector((state:any) => state.event.currencySymbol);
    const pricesShow = useShow();
    const maxPrice = getMaxPrice(ticket);
    const minPrice = getMinPrice(ticket);
    const unlimited=ticket?.amount===null;
    const sold=ticket?.sold??0;
    return (
        <TicketsRow pricesShow={pricesShow} ticket={ticket} active={active} index={index} setActiveIndex={setActiveIndex} >
            {(ticketOnClick)=><>
                <Part padding={'0'} field={'price'} className={''} cssStyle={undefined} onClick={ticketOnClick}>
                    <css.wrapper>
                        {(minPrice !== maxPrice) && <Detail text='' value={minPrice} isSymbol sign={currencySymbol} className={'click numberRange'} number={true}/>}
                        <Detail text='' value={maxPrice} isSymbol sign={currencySymbol} className={'click'} number={true}/>
                    </css.wrapper>
                </Part>
                <Part field={'amount'} className={''} cssStyle={undefined} padding={undefined} onClick={ticketOnClick}>
                    <Detail text='' value={ticket?.amount??t('unlimited')} sign={!unlimited && t('ticketPcs')} className={`click ${unlimited &&'unlimited'}`} number={true}/>
                </Part>
                <Part field={'sold'} className={''} cssStyle={undefined} padding={undefined} onClick={ticketOnClick}>
                    <Detail text='' value={sold} sign={t('ticketPcs')} className={'click'}/>
                </Part>
                <Part field={'left'} className={''} cssStyle={undefined} padding={undefined} onClick={ticketOnClick}>
                    <Detail text='' value={unlimited?'-':(ticket?.amount - sold)} sign={!unlimited&&t('ticketPcs')} className={'click'} number={true}/>
                </Part>
                <Part field={'discount'} className={''} cssStyle={undefined} padding={undefined} onClick={ticketOnClick}>
                    <Detail text='' value={ticket?.discount ?? getDiscount(ticket)} sign={currencySymbol} isSymbol className={'click'} number={true}/>
                </Part> 
                <Part field={'soldPrice'} className={''} cssStyle={undefined} padding={undefined} onClick={ticketOnClick}>
                    <Detail text='' value={ticket?.soldPrice} sign={currencySymbol} isSymbol className={'click'} number={true}/>
                    {ticket?.prices?.length > 1 && <css.arrow isOpen={pricesShow.isOpen}/>}
                </Part>
            </>
            }
        </TicketsRow>
    );
};
export default TicketRowTicket;