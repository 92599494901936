import * as css from './EventDay.css';
import {useSelector} from 'react-redux';
import {XHR_LOADING_STATE} from '../../../../assets/constants/constants';
import {ValidationsChart} from './components/ValidationsChart/ValidationsChart';
import EventDayTemplate from './EventDayTemplate';
import {TicketsSection} from './components/TicketsSection/TicketsSection';
import {TerminalList} from './components/terminal/TerminalList';
import {getEventDay} from '../../../../redux/realtime/realtimeActions';
import {useEffect, useState} from 'react';
import {EventDayHeader} from './components/EventDayHeader';
import {UpdateStatus} from './components/UpdateStatus';
import {useUpdateTimer} from './hooks/useUpdateTimer';
import {getColor} from '../../eventRealtime/EventRealtime.css';
import setGlobalState from '../../../../redux/setGlobalState';
import {CloseEventDay} from './components/CloseEventDay/CloseEventDay';

export function EventDay() {
    const getEventDayStatsXHR = useSelector((s:any)=>s.realtime?.getEventDayStatsXHR);
    const tickets = useSelector((s:any)=>s.realtime?.eventDay?.tickets);
    const isLoading = getEventDayStatsXHR === XHR_LOADING_STATE;
    const socketNewEnter = useSelector((s:any)=>s.realtime?.eventDay?.socketNewEnter);
    const isDarkMode = useSelector((s:any) => s.realtime.user?.realtimeDesign?.darkDisplay);
    const event = useSelector((s:any)=>s.event);
    const [isClosed, setIsClosed] = useState(true);

    const {remainingTime, handleUpdateNow} = useUpdateTimer({
        onUpdate: () => event?.eid && getEventDay(event.eid),
        enabled: !!socketNewEnter,
    });

    useEffect(() => {
        if(event?.eid && !isLoading) {
            getEventDay(event.eid);
        }
    }, [event?.eid]);

    useEffect(() => {
        if(socketNewEnter) { 
            const timer = setTimeout(() => {
                setGlobalState('realtime.eventDay.socketNewEnter', 0);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [socketNewEnter]);

    if(isLoading) 
        return <EventDayTemplate />;
    if((!tickets || tickets.length === 0) && getEventDayStatsXHR === 'SUCCESS')
        return <></>;

    return (
         
        <css.wrapper $compact={isClosed}> {isClosed ? <CloseEventDay callback={setIsClosed}/> :  
            <>
                <EventDayHeader 
                    isDarkMode={isDarkMode}
                    event={event}
                    getColor={getColor}
                    closeEventDay={() => setIsClosed(true)}
                />
                <css.content>
                    <UpdateStatus 
                        socketNewEnter={socketNewEnter}
                        remainingTime={remainingTime}
                        onUpdateNow={handleUpdateNow}
                    />
                    <ValidationsChart />
                    <TerminalList />
                    <TicketsSection />
                </css.content>
            </>}
        </css.wrapper>
    );
}
