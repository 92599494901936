import {useTranslation} from 'react-i18next';
import MobilePopupModal from '../../../component/newPopup/mobilePopup/MobilePopupModal';
import FormContext from '../../components/formContext/FormContext';
import {Form} from 'formik';
import * as Yup from 'yup';
import {addSpecialPixel, updateSpecialPixel} from '../../../redux/publish/publishActions';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import FacebookPixelPopContent from '../facebookPixelPopContent/FacebookPixelPopContent';
import useRestartXHR from '../../../hooks/useRestartXHR';

function FacebookPixelPopup({close,showClass,pixel}) {
    const {t} = useTranslation('publish');
    const domain = pixel?.domain;
    const {addSpecialPixelXHR,updateSpecialPixelXHR} = useSelector((state) => state.publish);
    const event = useSelector((s) => s.event);
    const tickchakDomain = domain?.includes('tickchak.co.il');
    const [isTickchakDomain,setIsTickchakDomain]=useState(domain?tickchakDomain:true);
    useRestartXHR(addSpecialPixelXHR,'publish.addSpecialPixelXHR');
    useRestartXHR(updateSpecialPixelXHR,'publish.updateSpecialPixelXHR');

    const domainValidation = Yup.string()
        .test('domainFormat', t('global:errorTemplate'), function (value) {
            if(value === undefined || value === '') return true; 
            if(isTickchakDomain) {
                return /^[a-z0-9-]+$/.test(value);
            } else {
                return /^(?!:\/\/)(?!.*--)(?!^-)(?!.*-$)(?!.*[a-z0-9]-\.)[a-z0-9](?:[a-z0-9-]*[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)*\.[A-Za-z]{1,63}(?::[0-9]{1,5})?$/.test(value); 
            }
        })
        .when('domainVerification', {
            is: (domainVerification) => !!domainVerification,
            then: Yup.string().required(),
        });

    const initialValues = {
        id: pixel?.id || '',
        domain: (isTickchakDomain ? domain?.split('.')[0] : domain) || '',
        domainVerification: pixel?.domainVerification || ''
    };

    const validationSchema = Yup.object({
        id: Yup.string().required().matches(/^[a-zA-Z0-9-]{1,25}$/),
        domain: isTickchakDomain?domainValidation:domainValidation.required(),  
        domainVerification: Yup.string().matches(/^[a-zA-Z0-9]+$/),
    });

    const onSave = (values,changedValues) => {
        const {domain} = changedValues;
        const fullDomain = isTickchakDomain ? `${domain}.tickchak.co.il`: domain;
        const isDomainChanged= event.domain!==fullDomain;

        const updatedValues = {
            ...changedValues,
            type: 'Facebook Pixel',
            domain: isDomainChanged?domain:'' ,
        };
        if(pixel?.pixelId)
            updateSpecialPixel(pixel.pixelId,updatedValues).then(()=>close());
        else
            addSpecialPixel(updatedValues).then(()=>close());
    };

    return (
        <MobilePopupModal close={close} showClass={showClass} dataCyName ="facebookPixel" height={'615px; overflow:unset'} >
            <>
                <FormContext 
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSave}
                >{({submitForm})=>
                        <Form>
                            <FacebookPixelPopContent 
                                pixel={pixel}
                                submitForm={submitForm} 
                                tickchakDomain={{isTickchakDomain:isTickchakDomain,setIsTickchakDomain:setIsTickchakDomain}}
                            />
                        </Form>
                    }
                </FormContext>
            </>
        </MobilePopupModal>
    );
}
export default FacebookPixelPopup;
