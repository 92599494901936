import React from 'react';

function DownloadIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M15.2448 16.498C15.4347 16.4983 15.6175 16.5707 15.7561 16.7005C15.8948 16.8303 15.979 17.0079 15.9918 17.1974C16.0047 17.3869 15.9451 17.5742 15.8252 17.7215C15.7053 17.8688 15.5339 17.9651 15.3458 17.991L15.2448 17.998H4.74977C4.55975 17.9979 4.37683 17.9258 4.23798 17.796C4.09913 17.6663 4.0147 17.4887 4.00175 17.2991C3.98879 17.1095 4.04829 16.9221 4.1682 16.7747C4.28812 16.6273 4.45952 16.5309 4.64777 16.505L4.74977 16.498H15.2448ZM10.0038 2C10.185 2.00001 10.3601 2.06564 10.4967 2.18477C10.6333 2.30389 10.7221 2.46845 10.7468 2.648L10.7538 2.75L10.7528 12.943L13.7188 9.973C13.8457 9.84557 14.0142 9.76799 14.1935 9.75441C14.3729 9.74083 14.5511 9.79214 14.6958 9.899L14.7798 9.971C14.9071 10.098 14.9844 10.2666 14.9978 10.4459C15.0112 10.6253 14.9598 10.8034 14.8528 10.948L14.7808 11.032L10.5378 15.282L10.4678 15.345L10.3758 15.404L10.3398 15.425L10.2488 15.463L10.1288 15.493L10.0588 15.501L9.99877 15.503C9.94834 15.5031 9.89805 15.4977 9.84877 15.487L9.76677 15.464C9.67169 15.4342 9.58407 15.3844 9.50977 15.318L5.21977 11.033C5.08523 10.8993 5.00632 10.7197 4.99893 10.5302C4.99153 10.3407 5.05619 10.1554 5.1799 10.0117C5.30361 9.86794 5.47718 9.77641 5.66567 9.7555C5.85416 9.73459 6.04357 9.78587 6.19577 9.899L6.27977 9.972L9.25277 12.939V2.75C9.25277 2.55109 9.33179 2.36032 9.47244 2.21967C9.61309 2.07902 9.80486 2 10.0038 2Z" fill="black"/>
        </svg>
    );
}

export default DownloadIcon;