export const maskEmail = (email) => {
    if(!email) return '';
    const [localPart, domain] = email.split('@');
    if(!domain) return email;
    
    const maskedLocal = localPart.length > 4 
        ? localPart.slice(0, 4) + '*'.repeat(localPart.length - 4)
        : localPart;
    
    return `${maskedLocal}@${domain}`;
};
