import createReducer from '../createReducer';
import {TRealtime} from './realtime.type';

export const initialStateRealtime:TRealtime = {
    user:{
        countEvents:0,
        eventsTypes:[],
        realtimeDesign: null,
        getStatsXHR:null,
        oldSystem: false
    },
    event:{
        eventStats: {
            today: [],
            yesterday: [],
            week: [],
            month: [],
            all: [],
            other: [],
            now:{}
        },
        realtimeText: {},
        cash: {},
        tickets: {},
        seats: {},
        moreInfo: {},
        multiplierData: {},
        soldTickets: null,
        targets: null,
        lastUpdate: null,
        getEventStatsXHR: null,
        givechakMultiplierXHR:null,
        getRealtimeXHR: null,
        soldTicketsXHR: null,
        weatherXHR: null,
        getRealtimeTextXHR:null,
        getTargetsXHR:null

    },
    eventDay: {
        tickets: [],
        transactions: 0,
        hourlyEntries: [],
        terminals: [],
        socketNewEnter: 0,
        totalEnters: 0,
        getEventDayStatsXHR: null,
    },
    systemMessage: {popMessages: [], windowMessages: [],isViewed:false}
};

const reducer = createReducer('realtime',initialStateRealtime);

export default reducer;