import styled from 'styled-components';
import {btn} from '../../../../component/button/btn/Btn.css';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';
import {dFlex, flexColumn, justifyEnd} from '../../../../globalVariables/GlobalFlex';

export const LEFT_WIDTH='130px';

export const wrapper=styled.div`
    width: ${LEFT_WIDTH};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    ${(p) => p.cssStyle}
    ${btn}{
        width: ${p => p.theme.rtl ? '95' : '105'}px;
        border-color: black;
        margin-top: 10px;
        &:hover{
            color: black;
            border-color: black;
            background-color: #EEE;
        }
        &.valid{
          border-color: transparent;
          &:hover{
            border-color:#BEBEBE;
          }
        }
        &.skip{
          background-color: rgb(227 227 227);
          border: none;
          font-weight: 500;
            &:hover{
                background-color: #AEAEAE;
            }
        }
        &.LOADING{
            width:30px;
        }
    }
  ${mobileMedia}{
    width: 100%;
    ${justifyEnd};
    &.alignToLeft{
      margin-inline-end:0 ;
    }
  }
`;

export const wrapButtons=styled.div`
   ${mobileMedia}{
    ${dFlex};
    ${flexColumn};
   }
`;
