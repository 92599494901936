import {memo, useEffect, useMemo} from 'react';
import * as css from './EventRealtime.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Window from '../components/window/window';
import Transactions from '../components/windows/transactions/transactions';
import {Cash} from '../components/cash/Cash';
import {CashQuestion} from '../components/cashQuestion/CashQuestion';
import {CashExplain} from '../components/cashExplain/CashExplain';
import {getWeather, updateRealtimeDesign} from '../../../redux/realtime/realtimeActions';
import useMobile from '../../../hooks/useMobile';
import SimpleExplanationWindow from '../components/simpleExplanationWindow/simpleExplanationWindow';
import SoldTickets from '../components/windows/soldTickets/soldTickets';
import RealtimeData from '../components/windows/realtimeData/RealtimeData';
import useRealtimeGrid from '../useRealtimeGrid';
import GridDrag from '../../../component/gridDrag/GridDrag';
import Weather from '../components/windows/weather/weather';
import SystemMessageSwiper from '../components/windows/systemMessage/systemMessageSwiper';
import useEffectOnUpdate from '../../../hooks/useEffectOnUpdate';
import PublishPageBtn from '../../components/publishPageBtn/PublishPageBtn';
import EventActive from '../../components/eventActive/EventActive';
import TicketsTicket from '../components/windows/tickets/TicketsTicket/TicketsTicket';
import {EventDay} from '../components/eventDay/EventDay';
import {getTs, subtractTs} from '../../../library/dates';
import GivechakTarget from '../components/windows/soldTickets/givechakTarget';
import Multiplication from '../components/windows/transactions/multiplication';
import Ambassadors from '../components/windows/ambassadors/Ambassadors';
export const eventStatusComp={hub:PublishPageBtn, default:EventActive};

function Windows (){
    const event = useSelector(({event}:any) => ({
        type: event.type,
        timeStart: event.timeStart
    }));
    const tickets = useSelector((s:any) => s.tickets.tickets);
    const {weather, weatherXHR, isMultiplier} = useSelector(({realtime}:any) => ({
        weather: realtime.event.weather,
        weatherXHR: realtime.event.weatherXHR,
        isMultiplier: (!realtime.event.givechakMultiplierXHR || realtime.event.multiplierData.multiplied>1)
    }));
    const showMessage = useSelector((s:any) => (!!s.realtime.systemMessage?.windowMessages?.length || !s.realtime.systemMessageXHR) && s.realtime.systemMessage.windowMessages?.length);
    const realtimeMessage = useSelector((s:any) => s.realtime.systemMessage?.windowMessages);
    const isMasterUser = useSelector((s: any)=>s.user.isMaster);
    const isMobile = useMobile();
    const {windowsList, reorderGrid, showHideWin} = useRealtimeGrid(newGrid=>updateRealtimeDesign('gridAreas', newGrid));
    const {t} = useTranslation('realtime');

    useEffect(() => {
        if(!weather && !weatherXHR) getWeather().then();
    },[]);

    const showEventDay = useMemo(() => {
        const weekBeforeTs = subtractTs(event.timeStart, 4, 'day');
        const nowTs = getTs();
        return nowTs >= weekBeforeTs && (event.type === 'ticket' || event.type === 'area') && event.timeStart;
    }, [event.timeStart]);

    const showMultiplication =event.type === 'givechak' && isMultiplier;

    const components = useMemo(()=> ({eventDay: {comp:  <EventDay />,
        draggable: false,
        show: showEventDay,
        deployable: false,
        height: 'max-content'
    },
    cash: {comp: <Cash />,
        explainComp: <CashQuestion />,
        howArrivedComp:<CashExplain />,
        howArrivedTitle: t('howArrivedButton'),
        draggable: false,
        show: true,
        deployable: false
    },
    soldTickets: {comp: (event.type === 'givechak' ?<GivechakTarget />:<SoldTickets />),
        explainComp: <SimpleExplanationWindow text={t(event.type === 'givechak' ?'targetsExplanation':'soldTicketsExplanation')} />,
        draggable: false,
        show: true,
        deployable: false
    },
    transactions: {comp: <Transactions />,
        explainComp: <SimpleExplanationWindow text={t('transactionsExplanation')} />,
        draggable: false,
        show: true,
        deployable: false
    },
    multiplication: {comp: <Multiplication />,
        explainComp: <SimpleExplanationWindow text={t('multiplicationExplanation')} />,
        draggable: false,
        show: showMultiplication,
        role: {rolePermission: 'manage event'},
        deployable: false
    },
    realtimeData: {comp: <RealtimeData />,
        explainComp: <SimpleExplanationWindow text={t('realtimeDataExplanation')} />,
        draggable: false,
        show: true,
        deployable: false,
        height: 'max-content'
    },
    tickets: {comp: (event.type === 'givechak' ? <Ambassadors/> : ((tickets?.length) ? <TicketsTicket />:null)),
        explainComp: <SimpleExplanationWindow text={event.type === 'givechak' ? t('leadsExplanation') : t('ticketsExplanation')} />,
        draggable: false,
        show: !!tickets?.length,
        deployable: true,
        height: isMobile?'max-content':'390px',
        ...(event.type === 'givechak' ? {role: {rolePermission: 'publish'}} : {})
    },
    weather: {comp: weather ? <Weather />:null,
        explainComp: <SimpleExplanationWindow text={t('weatherExplanation')} />,
        deployable: true,
        draggable: false,
        show: !!weather && isMasterUser,
        role: {rolePermission: 'access'},
        height: isMobile?'max-content':'100%'
    },
    message: {comp:  showMessage?<SystemMessageSwiper isPop={false} />:<></>,
        explainComp: null,
        deployable: true,
        draggable: false,
        show: showMessage,
        role: {rolePermission: realtimeMessage && (realtimeMessage[0]?.distributionRole || realtimeMessage[0]?.distribution_role) || 'access'},
        height: isMobile?'max-content':'390px'
    }
    }),[tickets, isMobile, weather, showMessage, showEventDay, showMultiplication]);

    useEffectOnUpdate(()=>showHideWin('message', showMessage),[showMessage]);
    useEffectOnUpdate(()=>showHideWin('weather', !!weather),[weather]);
    useEffectOnUpdate(()=>showHideWin('eventDay', showEventDay),[showEventDay]);

    const getProps = item => {
        return {children: components[item.gridArea]?.comp , gridArea: item.gridArea, role: {rolePermission: 'manage event'}, ...(components[item.gridArea] || {})};
    };
    const onDragEnd = (hi)=>{
        reorderGrid(hi?.map(item=>item.gridArea));
    };

    return (
        <css.gridContainer showWeather={!!weather} windowsList={windowsList} eventType={event.type} showEventDay={showEventDay} $showMultiplication={showMultiplication}>
            <GridDrag onDragEnd={onDragEnd} items={windowsList?.filter(win=>components[win]?.show).map(win => ({gridArea: win})) || []} getProps={getProps as any} Comp={Window} idName={'gridArea'} undraggableChildren={undefined} />
        </css.gridContainer>
    );
}

export default memo(Windows);
