const Search = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
    >
        <g clipPath="url(#clip0_8408_22196)">
            <path
                fill="#000"
                d="m17.779 16.714-3.237-3.236c.532-.62.975-1.33 1.286-2.128.399-.976.62-2.04.62-3.148a8.1 8.1 0 0 0-.62-3.103c-.4-1.02-1.02-1.907-1.73-2.66A7.3 7.3 0 0 0 11.439.62 8.3 8.3 0 0 0 8.292 0C6.03 0 3.99.931 2.483 2.394c-.754.754-1.374 1.64-1.773 2.66a8.3 8.3 0 0 0-.621 3.148c0 1.108.222 2.128.62 3.103.4 1.065 1.02 1.951 1.774 2.705s1.64 1.374 2.66 1.773a8.3 8.3 0 0 0 6.296 0c.753-.31 1.463-.753 2.128-1.285l3.236 3.236c.31.31.754.31 1.064 0a.724.724 0 0 0 0-1.064zm-4.833-3.768a6.7 6.7 0 0 1-2.172 1.463c-.798.31-1.64.488-2.572.488a7 7 0 0 1-2.571-.488 6.7 6.7 0 0 1-2.173-1.463 7.2 7.2 0 0 1-1.463-2.173 7.2 7.2 0 0 1-.487-2.571c0-.887.177-1.773.487-2.571a7.2 7.2 0 0 1 1.463-2.173C4.7 2.261 6.34 1.508 8.202 1.508c.931 0 1.774.177 2.572.487.842.31 1.551.842 2.172 1.463.62.665 1.108 1.375 1.419 2.173s.487 1.684.487 2.571-.177 1.773-.487 2.571a7.2 7.2 0 0 1-1.463 2.173z"
            />
        </g>
        <defs>
            <clipPath id="clip0_8408_22196">
                <path fill="#fff" d="M0 0h18v18H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default Search;
