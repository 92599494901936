import styled from 'styled-components';
import {BGImageCenter, DEFAULT_COLOR, mobileMedia} from '../../../../globalVariables/GlobalVariables';
import {btn} from '../../../../component/button/btn/Btn.css';
import {lightArrow} from '../../../../assets/symbols/symbols/Symbols.css';

export const card=styled.div<{disabled?: boolean}>`
    height: 350px;
    width:280px;
    background-color: ${`${DEFAULT_COLOR}69`};
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 30px;
    ${p=>p.disabled && 'opacity: 0.2; pointer-events: none;'}
    ${mobileMedia}{
        margin: 15px auto;
    }
    ${btn}{
        min-width: 145px;
        &.yellow{
            &::before{
                content:'';
                ${BGImageCenter(lightArrow,'contain')};
                position: absolute;
                inset-inline-end: 10px;
                transform: rotate(${p => p.theme.rtl?180:0}deg);
                width: 10px;
                height: 10px;
            }    
        }
    }
    
    
`;
export const title=styled.div`
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.24px;
`;
export const subTitle=styled.div`
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 15px;
    height: 70px;
`;
export const buttonTitle=styled.span`
  
`;

