import store from '../../store';
import setGlobalState from '../../redux/setGlobalState';
import request, {updateRequestStatus} from '../../library/request';
import {getSecurityLevel, getToken} from '../../redux/security/securityActions';
import {hasRoleAccess} from '../permission';

let resolveFunc = null;
let rejectFunc = null;
let agruments = [];

const reset = () => {
    resolveFunc = null;
    rejectFunc = null;
    agruments = [];
};

export const cancelVerification = async () => {
    if(rejectFunc) {
        rejectFunc();
    }
    reset();
};

//for level 3
export const askPermissionFromOffice = async (permission) => {
    await request('security.askPermissionFromOffice', 'post', `/package/${store.getState().account.pid}/permissionSecurity`,
        {level: 3, type: permission});
    rejectFunc();
    reset();
    setGlobalState('security.isVerification', true);
};

//for level 1 or 2
export const requestAfterVerification = async (code) => {
    const level = getSecurityLevel(store.getState().security.permission,store.getState().security.isActivating);
    if(level === 2)
        agruments[3] = {...agruments[3], securityToken: store.getState().security.token, code: code};
    try {
        const res = await request(...agruments, {handleError: true});
        setGlobalState('security.isVerification', true);
        resolveFunc(res);
        reset();

    } catch (error) {
        if(level === 2)
            setGlobalState('ui.codeInput.isWrong', true);
    }
};

export const securityByLevelRequest = (accountPermission, rolePermission, isActivating, action, method, ...args) => {
    if(rolePermission) {
        const roleAccess = hasRoleAccess(rolePermission);
        if(!roleAccess) {
            updateRequestStatus(action, method, 'FAILED', 'not access');
            throw {};
        }
    }
    const level = getSecurityLevel(accountPermission, isActivating);
    if(level) {
        setGlobalState('security', {permission: accountPermission, level,isActivating});
        const promise = new Promise(async (resolve, reject) => {
            if(level === 2) {
                await getToken(accountPermission,false,isActivating);
            }
            agruments = [action, method, ...args];
            resolveFunc = resolve;
            rejectFunc = reject;
        });
        return promise;
    }
    return request(action, method, ...args);
};

const highSecurityRequest = async (accountPermission, rolePermission, action, method, ...args) => {
    return securityByLevelRequest(accountPermission, rolePermission, false, action, method, ...args);
};

export default highSecurityRequest;