import {useTranslation} from 'react-i18next';
import * as css from './DateStep.css';
import DateInput from '../../../../component/input/dateInput/DateInput';
import TimeInput from '../../../../component/input/dateInput/TimeInput';
import {useField, useFormikContext} from 'formik';
import InputAmount from '../../../../component/input/inputAmount/InputAmount';
import {useContext, useEffect, useState} from 'react';
import useStep from '../StepLeft/useStep';
import {addMinute, combineDateTime, getDuration, getMinutesDifference, getTs, isSameDay, checkIfToday as isToday} from '../../../../library/dates';
import {updateEvent} from '../../../../redux/event/eventActions';
import {stopEnterKeyDown} from '../Step/Step';
import store from '../../../../store';
import getUnequalFields from '../../../../functions/getUnequalFields';
import {newEventContext} from '../newEventForm/NewEventForm';
import {useSelector} from 'react-redux';
import useEffectOnUpdate from '../../../../hooks/useEffectOnUpdate';
import setGlobalState from '../../../../redux/setGlobalState';
import getCountryByTimeZone from '../../../../library/getCountryByTimeZone';
import CountryFlag from '../../../../component/countryFlag/CountryFlag';

export const onClickDate=async (_changedValues,date,form)=>{
    let fields;
    if(!date.startDate)
        fields={
            timeStart:null,
            timeEnd:null,
            timeOpen:null
        };
    else {
        const timeZone = form.values.locale.timeZone;
        fields={
            timeStart:combineDateTime(date.startDate,date.startTime,timeZone)||null,
            timeEnd:combineDateTime(date.endDate,date.endTime,timeZone)||null,
            timeOpen:date.timeOpen?combineDateTime(date.startDate,date.timeOpen,timeZone):null,
        };
    }
    const {timeStart,timeOpen,timeEnd}=store.getState().event;
    const oldDate={timeStart,timeOpen,timeEnd};
    const changedValues=getUnequalFields({date:oldDate},{date:fields});
    if(!changedValues.date){
        return Promise.resolve();
    }

    await updateEvent(fields);
};

function DateStep() {
    const {t} = useTranslation('newEvent');
    const form = useFormikContext();

    const [dateField,,{setValue}]=useField('date');
    const [,,{setValue:setEndDate}]=useField('date.endDate');
    const [,,{setValue:setEndTime}]=useField('date.endTime');
    const [,,{setValue:setTimeOpen}]=useField('date.timeOpen');
    const [{value:timezone}]=useField('locale.timeZone');

    const {value}=dateField;
    const {startDate,startTime,endDate,endTime,duration,timeOpen}=dateField.value;
    const [isDisabledFields,setIsDisabledFields]=useState(!duration);
    const [hasTimeOpen, setHasTimeOpen] = useState(startDate ? !!timeOpen : true);
    const [updated,setUpdated]=useState(false);
    const realStartDate=(startDate&& startTime)? combineDateTime(startDate,startTime):null;
    const realEndDate=(endDate&&endTime)?combineDateTime(endDate,endTime):null;
    const touched=form.touched.date||{};
    const ready=useSelector(s=>s.event.ready);
    const type=useSelector(s=>s.event.type);

    const getEnd=(duration,start) =>{
        if(!duration || !parseInt(duration))
            return;
        return  start+(duration*60);
    };

    const setEnd=()=>{
        setUpdated('end');
        const end=getEnd(duration,realStartDate);
        setEndDate(end);
        setEndTime(end);
    };

    useEffect(() => {
        form.validateForm();
        if(!touched.startDate ||!touched.startTime )
            return;
        if(isDisabledFields){
            const endDate =realStartDate+(60*60*3);
            setValue({
                ...value,
                timeOpen:realStartDate-(60*60),
                endTime:realStartDate+(60*60*3),
                endDate,
                duration:getDuration(realStartDate,endDate)
            });
            setIsDisabledFields(false);
            setHasTimeOpen(true);
        }
        else {
            setEnd();
        }
    }, [startDate,startTime]);

    useEffectOnUpdate(()=>{
        if(startTime&&startDate&&!touched.timeOpen)
            setTimeOpen(realStartDate-(60*60));
    },[startTime]);

    useEffect(() => {
        if(updated==='end'){
            setUpdated(false);
            return;
        }
        setUpdated('duration');
        setValue({
            ...value,
            duration:getMinutesDifference(realStartDate,realEndDate)||''
        });
        form.validateForm();
    }, [endDate,endTime]);

    useEffect(() => {
        if(updated==='duration')
            setUpdated(false);
        if(touched.duration)
            setEnd();
    }, [duration]);

    useEffect(form.validateForm, [timeOpen]);

    const modeBtnClick=(val)=>{
        setHasTimeOpen(val);
        if(!val)
            setValue({...value,timeOpen:''});
    };

    const {steps,currentStep,isReady}=useContext(newEventContext);

    const {onContinue} = useStep(steps[currentStep]);

    const onKeyDown=(e)=>{if(e.key==='Enter') e.stopPropagation();};
    const props={onKeyDown,...(ready===5?{portalId:'root-portal'}:{})};

    const endTimeProps={
        ...props
        ,onKeyDown:(e)=>{
            onKeyDown(e);
            if(e.key==='Tab')
                onContinue();
        }};
    if(isSameDay(startDate,endDate)&&ready<5)
        endTimeProps.minTime=addMinute(realStartDate,1);

    const isOpenLocaleStep=useSelector(s=>s.ui.event.isOpenLocaleStep);

    return (
        <css.wrapper onKeyDown={stopEnterKeyDown}>
            <DateInput name={'date.startDate'} timezone={timezone}  text={t('startDate')} isFixed={true}
                datePickerProps={props} />
            <TimeInput name={'date.startTime'} text={t('startTime')} isTimestamp={true}  isFixed={true}
                timePickerProps={{...props,...(isToday(startDate)?{minTime:getTs()}:{})}}/>
            <InputAmount name={'date.duration'} text={t('eventDuration')} icon={'clock'} amountText={t('minutes')}
                disabled={isDisabledFields}/>
            {type !== 'givechak' && <TimeInput name={'date.timeOpen'} text={t('openingGates')} checkbox={{selected:hasTimeOpen,onClick:modeBtnClick}} className={!hasTimeOpen&&'disabled'} readOnly={isDisabledFields||!hasTimeOpen}
                isTimestamp={true} isFixed={true} timePickerProps={{maxTime:startTime,...props}}/>}
            <DateInput name={'date.endDate'} timezone={timezone} text={t('endDate')} readOnly={isDisabledFields} isFixed={true}
                datePickerProps={{minDate:startDate,...props}} />
            <TimeInput name={'date.endTime'} text={t('endTime')} readOnly={isDisabledFields} isTimestamp={true} isFixed={true}
                timePickerProps={endTimeProps}/>
            <css.timeZoneWrap>
                <css.timeZoneAndLang
                    className={`${isReady?'ready':''} ${isOpenLocaleStep?'open':''}`}
                    onClick={isReady?undefined:()=>setGlobalState('ui.event.isOpenLocaleStep',!isOpenLocaleStep)} >
                    {`${isOpenLocaleStep?t('hide'):''} ${t(isReady?'localeHeader':'timeZoneLangOptions')}`}
                    <CountryFlag countryCode={getCountryByTimeZone(store.getState().event.timeZone,false).toLowerCase()}/>
                </css.timeZoneAndLang>
            </css.timeZoneWrap>
        </css.wrapper>
    );
}

export default DateStep;
