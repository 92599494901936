import styled from 'styled-components';
import {BGImageCenter, DEFAULT_COLOR, mobileMedia, MODERATE_BLUE, POPUP_DURATION, VERY_LIGHT_GRAY, WHITE} from '../../../globalVariables/GlobalVariables';
import zIndex from '../../../globalVariables/zIndex';
import {videoContainer} from '../../../component/video/Video.css';
import {add} from '../../../assets/symbols/symbols/Symbols.css';
import {dFlexCenter, justifyBetween, dFlex, flexColumn, alignStart, justifyCenter} from '../../../globalVariables/GlobalFlex';

export const unStyledButton = styled.button`
    outline: none;
    border: none;
    cursor: pointer;
    background: none;
`;
export const container = styled.div`
    position: fixed;
    right: auto !important;
    left: 30px !important;
    bottom: calc(160px - 100%);
    height: calc(100% - 160px);
    z-index: ${zIndex.upperModal};
    max-height: 590px;
    width: min(370px, calc(100% - 60px));
    border-radius: 15px;
    ${dFlexCenter}
    ${flexColumn}
    padding: 23px 23px 60px 23px;
    gap: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, .16);
    background-color: ${WHITE};
    transition: all 300ms;
    &.open{
        bottom: 0;
        ${mobileMedia}{
            width: 100vw;
            height: 100vh;
            max-height: 100vh;
            bottom: 0;
            inset-block-end: 0 !important;
            top: 0;
            left: 0 !important;
            border-radius: 0;
        }
    }
`; 
export const header = styled.div`
    ${dFlex}
    ${justifyBetween}
    width: 100%;
    &>button{
        position: relative;
        top: -20px;
        inset-inline-start: -5px;
    }
`;
export const close = styled(unStyledButton)`
    align-self: start;
`;
export const content = styled.div`
    height: 100%;
    overflow-y: auto;
    padding-block: 10px;
`;
export const videos = styled.div`
    height: calc(100% - 22px);
    min-height: fit-content;
    ${dFlex}
    ${flexColumn}
    gap: 11px;
    margin-block: 11px;
    font-size: 10px;
    letter-spacing: 0.7px;
`;
export const video = styled.div<{$expended: boolean, $hide: boolean}>`
    height: 38px;
    ${dFlex}
    ${justifyCenter}
    ${flexColumn};
    ${alignStart}
    overflow: hidden;
    padding: 11px 17px 11px 19px;
    border-radius: 5px;
    border: 0.5px solid #DDDDDD;
    transition: all 300ms;
    ${p=>p.$expended && `
        height: 250px;
        border-bottom: 3px solid ${DEFAULT_COLOR};
    `}
   opacity: ${p=>p.$hide?0.5:1};
`;
export const videoTitle = styled.div`
    height: 38px;
    width: 100%;
    text-align: right;
    font-size: 12px;
    font-weight: 600;
    ${dFlexCenter}
    ${justifyBetween}
`;
export const openWidgetLink= styled.div`
    color: ${MODERATE_BLUE};
    font-size: 10px;
    text-decoration-line: underline;
`;
export const openWidgetButton = styled(unStyledButton)`
    border-radius: 27px;
    background: ${MODERATE_BLUE};
    box-shadow: 2px 2px 4px 2px rgba(129, 129, 129, 0.25);
    color: ${WHITE};
    font-size: 14px;
    font-weight: 600;
    ${dFlexCenter}
    gap: 9px;
    width: 100%;
    height: 42px;
    margin-inline: auto;
`;
export const videoContent= styled.div<{$expended: boolean}>`
    font-size: 12px;      
    line-height: 122.475%;
    width: 100%;
    overflow: hidden;
    transition: all 300ms;
    height: ${p=>p.$expended?'auto':0}
`;
export const videoWrapper = styled.div<{$fullscreen: boolean}>`
    &>div {
        margin-bottom: -48px;
    }
        ${p=>p.$fullscreen ? `${dFlexCenter};
                padding-block: 50px;
                position: fixed;
                bottom:0 ;
                inset-inline-end: 0;
                width: 100%;
                height:100%;
                background-color: rgba(0,0,0,.75);
                z-index: ${zIndex.basicModal};
                backdrop-filter: blur(2px);
                transition: all ${POPUP_DURATION}ms;
                opacity: 0;
                flex-direction: column;
                &.show{ 
                  opacity:1 ;
                }
                ${videoContainer} {
                    width: 70vw;
                    height: calc(70vw * 9 / 16);
                }
        `:' max-width: 100%;'}

`;
export const overlay = styled.div<{$fullscreen: boolean}>`
    background: transparent;
    position: relative;
    width: 48px;
    height: 48px;
    bottom: 50px;
    inset-inline-start: ${p=>p.$fullscreen? '0':'13px'};
`;
export const permissions=styled.div`
    font-size: 10px;
    letter-spacing: 0.1px;
`;

export const iconBtn = styled(unStyledButton)<{expended:boolean, icon: any}>`
    ${p=>BGImageCenter(p.icon, '12px')}
    width: ${p=>p.expended?12:0}px;
    height: 14px;
    margin: 5px;
`;

export const addVideo = styled(unStyledButton)`
${dFlexCenter}
width: 100%;
height: 38px;
padding: 10px 0px 12px 0px;
border-radius: 5px;
border: 1.5px dashed ${VERY_LIGHT_GRAY};
background: ${WHITE};
font-size: 12px;
gap:12px;
`;

export const addIcon = styled.span`
width: 10px;
height: 10px;
${BGImageCenter(add, '10px')}
`;