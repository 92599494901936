import {Form} from 'formik';
import {useTranslation} from 'react-i18next';
import Input from '../../../../component/input/Input';
import * as css from './GroupForm.css';
import * as Yup from 'yup';
import FullEditImage from '../../../../component/fullEditImage/FullEditImage';
import {useState} from 'react';
import ItemInArray from '../../../../component/itemInArray/ItemInArray';
import {useSelector} from 'react-redux';
import ticketColors from '../../../../assets/objects/TicketColors';
import {addGroup, updateGroup} from '../../../../redux/tickets/ticketsActions';
import store from '../../../../store';
import {getTicketPrice} from '../../components/ticketBox/TicketBox';
import TextareaInput from '../../../../component/input/textareaInput/TextareaInput';
import FormContext from '../../../components/formContext/FormContext';
import {yupStringMin} from '../../../../functions/validationsYup';

function GroupForm({item, formGroupRef, color, symbol, active}) {

    const {t} = useTranslation('ticket');
    const size = {height: 157, width: 157};
    const currency = useSelector(s => s.event.currencySymbol);
    const ticketsIcon = useSelector(s => s.tickets.ticketsIcon);
    const tickets = useSelector(s => s.tickets.tickets);
    const [image, setImage] = useState(item?.groupImage);
    const originTickets = tickets.filter(o1 => item?.tickets?.some(o2 => o1.tid === o2.tid));

    const SignUpSchema = Yup.object().shape(
        {
            title: yupStringMin().required(),
            description: yupStringMin().max(100),
            summary:Yup.string().max(70)
        }
    );

    return (
        <>
            <FormContext
                innerRef={formGroupRef}
                initialValues={
                    {
                        title: item?.title,
                        description: item?.description || '',
                        summary: item?.summary,
                        groupImage: item?.image
                    }
                }
                validationSchema={SignUpSchema}
                enableReinitialize={true}
                onSubmit={(_changedValues,values) => {
                    values.groupSymbol = symbol;
                    values.color = color;
                    values.groupImage = image;
                    values.active = active;
                    values.tickets = originTickets;

                    if(item.gid)
                        updateGroup(item.gid, values).then();
                    else {
                        values.weight = store.getState().tickets?.groups.length;
                        addGroup(values).then();
                    }
                }}
            >
                <Form>
                    <css.aboutGroup>
                        <css.initRight>
                            <Input name={'title'} icon="rect" text={t('groupTitle')}  />
                            <Input name={'description'} icon="pencil" text={t('moreInfo')}  />
                            <TextareaInput name={'summary'} icon="clusterDetails" text={t('summary')}  height="50px" />
                        </css.initRight>
                        <css.initLeft>
                            <FullEditImage setImage={setImage} size={size} image={image}/>
                        </css.initLeft>
                    </css.aboutGroup>
                    <css.tickets>
                        {!!item?.tickets?.length && <css.ticketsTitle>{t('tickets')}</css.ticketsTitle>}
                        <css.ticketsRow>
                            {originTickets?.map((item) => (
                                <ItemInArray title={item.title} left={getTicketPrice(item) + currency} bgColor={item.color} key={item.tid} icon={ticketColors[item.color]?.[ticketsIcon]} />
                            ))}
                        </css.ticketsRow>
                    </css.tickets>
                </Form>
            </FormContext>
        </>
    );
}

export default GroupForm;