import styled from 'styled-components';
import {alignStart, dFlexBetween, dFlexCenter, flexColumn, justifyBetween} from '../../../../../../globalVariables/GlobalFlex';
import {LIGHT_BLUE5, mobileMedia, tabletMedia} from '../../../../../../globalVariables/GlobalVariables';
import {divider} from '../ValidationsChart/ValidationsChart.css';

export const wrapper = styled.div`
    ${dFlexCenter};
    ${justifyBetween};
    height: 100%;
    gap: 20px;
    ${tabletMedia}{
        gap: 10px;
    }
    ${mobileMedia}{
        ${flexColumn};
        ${alignStart};
    }
`;

export const section = styled.div`
    ${dFlexCenter};
    border-radius: 15px;
    background: ${LIGHT_BLUE5};
    height: 100%;
    padding: 0 20px;
    gap: 20px;
    ${divider}{
        inset-block-start: unset;
    }
    ${mobileMedia}{
        padding: 0;
        gap: 10px;
        position: relative;
        width: 100%;
    }
`;
export const dataSection = styled(section)`
    width: 100%;
    ${mobileMedia}{
        ${flexColumn};
        ${alignStart};
        height: 100%;
        inset-inline-start: 0;
    }
`;

export const title = styled.div`
    padding-inline: 20px;
    width: 100%;
    ${dFlexBetween}
`;
