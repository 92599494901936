import React, {useEffect, useMemo} from 'react';
import * as All from '../../../layoutUserProfileMenu/LayoutUserProfileMenu.css';
import UserProfileInput from '../userProfileInput/UserProfileInput';
import UserProfileSelect from '../UserProfileSelect';
import {useTranslation} from 'react-i18next';

const EditUserDetails = (props) => {
    const {t} = useTranslation('layout');
    const inputRefs = useMemo(() => Array(props.inputFields.length).fill(0).map(_=> React.createRef()), [props.inputFields]);
    useEffect(() => {
        if(props.activeIdx !== -1){
            inputRefs[props.activeIdx].current.focus();
        }
    }, [props.activeIdx,inputRefs]);

    return <All.EditProfileDetailsCtr>
        <All.EditProfileDetailsHeader>{t('personalInformation')}</All.EditProfileDetailsHeader>
        <All.EditProfileDetailsFieldsCtr>
            {props.inputFields.map((ipt, idx) =>{
                return ipt.isSelect ? null : <UserProfileInput
                    textInput={inputRefs[idx] }
                    key={idx}
                    iptProps={ipt}
                    clickOut={()=> props.clickOut(idx)}
                    inputChange={props.inputChange}/>;
            } )}
        </All.EditProfileDetailsFieldsCtr>

        <All.LanguageTime>{t('LanguageTime')}</All.LanguageTime>
        <All.EditProfileDetailsFieldsCtr>
            {props.inputFields.map((ipt, idx) =>{
                return !ipt.isSelect ? null : <UserProfileSelect textInput={inputRefs[idx]}
                    key={idx}
                    iptProps={ipt}
                    clickOut={()=> props.clickOut(idx)}
                    inputChange={props.inputChange}/>;
            } )}
        </All.EditProfileDetailsFieldsCtr>
    </All.EditProfileDetailsCtr>;
};

export default EditUserDetails;