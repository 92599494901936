import {io} from 'socket.io-client';
import {SOCKET_API_URL} from '../config/config';
import setGlobalState from '../redux/setGlobalState';
import store from '../store';
import request from '../library/request';
// import store from '../store';

export const initializeSocketConnection = () => {
    console.log(`Attempting to connect to Socket.IO at ${SOCKET_API_URL}`);
    const socket = io(SOCKET_API_URL, {
        path: '/socket',
        withCredentials: true,
        transports: ['websocket'],
        reconnection: false
        // reconnectionDelay: 10000,
        // reconnectionDelayMax: 10000
    });
    socket.on('connect', () => {
        console.log('Socket.IO connected with id:', socket.id);
    });

    socket.on('connect_error', (error) => {
        console.error('Socket.IO connection error:', error);
    });
    return socket;
};

export const setupCustomOnMethod = (socket) => {
    const originalOnMethod = socket.on;
    socket.on = (eventName, handler) => {
        const wrappedHandler = (data) => {
            handler(data);
        };
        originalOnMethod.call(socket, eventName, wrappedHandler);
    };
};

export const emitEventsWithCommonData = (socket, keys, eid) => {
    const getCommonData = (redisKeyType) => ({
        redisKey: `${redisKeyType}_${eid}`,
        referer: window.location.href,
        eid
    });

    keys.forEach(redisKeyType => {
        // console.log(`Emitting event ${redisKeyType} with data`, getCommonData(redisKeyType));
        socket.emit(redisKeyType, getCommonData(redisKeyType));
    });
};

export const setupEventHandlers = (socket, eid) => {
    // const handleEventDetails = (updatedEvent) => {
    //     if(Number(updatedEvent.eid) === Number(eid)) {
    //         setGlobalState('event', {...store.getState().event, ...updatedEvent.event});
    //     }
    // };

    // const handleEventStats = (updatedEvent) => {
    //     if(Number(updatedEvent.eid) === Number(eid)) {
    //         setGlobalState('realtime.event.eventStats', updatedEvent.stats);
    //     }
    // };
    const handleRealtimeCash = (updatedEvent) => {
        if(Number(updatedEvent.eid) === Number(eid)) {
            setGlobalState('realtime.event', {...store.getState().realtime.event,...{
                socketCashAmount: updatedEvent.amount,
                socketTicketsAmount: updatedEvent.tickets,
                socketOrdersAmount: updatedEvent.order
            }
            });
        }
    };
    // const handleRealtimeVisitors = (updatedEvent) => {
    //     if(Number(updatedEvent.eid) === Number(eid)) {
    //         setGlobalState(`realtime?.event?.eventStats?.${updatedEvent?.date}`,{allVisitors: updatedEvent?.allVisitors});
    //     }
    // };
    const handleRealtimeVisitorsBatch = (updatedEvents) => {
        if(Number(updatedEvents.eid) === Number(eid) && updatedEvents.messages?.length) {
            const currentEventStats = store.getState()?.realtime?.event?.eventStats || {};
            const updatedStats = {...currentEventStats};
            updatedEvents.messages.forEach(updatedEvent => {
                updatedStats[updatedEvent.date] = {
                    allVisitors: updatedEvent.allVisitors
                };
            });
            setGlobalState('realtime.event.eventStats', updatedStats);
        }
    };
    const handleEventDay = (updatedEvent) => {
        if(Number(updatedEvent.eid) === Number(eid)) {
            let socketNewEnter = 1;
            if(parseInt(updatedEvent.cancel) === 1){
                socketNewEnter = -1;
            }
            setGlobalState('realtime.eventDay.socketNewEnter', socketNewEnter);
            setGlobalState('realtime.eventDay.totalEnters', updatedEvent.totalEnters);
        }
    };
    // socket.on('eventStatsConnections', handleEventStats);
    // socket.on('eventDetailsConnections', handleEventDetails);
    socket.on('realtimeCashConnections', handleRealtimeCash);
    socket.on('realtimeVisitorsConnections', handleRealtimeVisitorsBatch);
    socket.on('eventDayConnections', handleEventDay);
};

export const cleanupSocketConnection = (socket, handleRealtimeCash, handleRealtimeVisitorsBatch, handleEventDay) => {
    return () => {
        if(socket) {
            const socketData = store.getState().auth?.socket;
            const requestData = {
                socket: {
                    clientId: socketData?.clientId,
                    redisKeys: socketData?.redisKeys
                }
            };
            request('auth.deleteSocket', 'delete', '/user/deleteSocket/', requestData)
                .catch(error => {
                    console.error('Error notifying server about socket disconnection:', error);
                    console.log('Request error details:', error.response?.data);
                })
                .finally(() => {
                    socket.off('realtimeCashConnections', handleRealtimeCash);
                    socket.off('realtimeVisitorsConnections', handleRealtimeVisitorsBatch);
                    socket.off('eventDayConnections', handleEventDay);
                    socket.disconnect();
                });
        } else {
            console.log('No socket to clean up');
        }
    };
};
