import request from '../../library/request';
import store from '../../store';
import setGlobalState from '../setGlobalState';

export const getEid = () => store.getState().event.eid;

export const getCategories = async () => {
    const res = await request('categories.getCategories', 'get', `/event/${getEid()}/categories`);
    setGlobalState('categories.eventCategories', res.categories);
};
export const addCategory = async (category) => {
    const res = await request('event.addCategory', 'post',`/event/${getEid()}/category`, {...category});
    if(res){
        const eventCategories = store.getState().categories.eventCategories;
        setGlobalState('categories.eventCategories', [...eventCategories, res.newCategory]);
    }
};
export const updateCategory = async (category) => {
    return await request('event.updateCategory', 'put',`/event/${getEid()}/category/${category.id}`, {pid: category.pid, title: category.title});
};
export const deleteCategory = async (catId) => {
    return await request('event.deleteCategory', 'delete', `/event/${getEid()}/category/${catId}`);
};