const getEmbedUrl = (url) => {
    const youtubeMatch = url?.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&\s]+)|(?:https?:\/\/)?youtu\.be\/([^?&\s]+)/);
    if(youtubeMatch && (youtubeMatch[1] || youtubeMatch[2])) {
        return `https://www.youtube.com/embed/${youtubeMatch[1] || youtubeMatch[2]}`;
    }
    const vimeoMatch = url?.match(/(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/);
    if(vimeoMatch && vimeoMatch[1]) {
        return `https://player.vimeo.com/video/${vimeoMatch[1]}`;
    }
    // eslint-disable-next-line
    const driveMatch = url?.match(/(?:https?:\/\/)?drive\.google\.com\/file\/d\/([^\/\s]+)\/(?:view)?/);
    if(driveMatch && driveMatch[1]) {
        return `https://drive.google.com/file/d/${driveMatch[1]}/preview`;
    }

    return null;
};

export default getEmbedUrl;
