import getEmbedUrl from '../../functions/getEmbedUrl';
import * as css from './Video.css';

function Video ({video}) {
    return (
        <css.videoContainer>
            <css.videoIframe>
                <iframe
                    src={getEmbedUrl(video)}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{width: '100%', height: '100%'}}
                    title="video player"
                />
            </css.videoIframe>
        </css.videoContainer>
    );
}

export default Video;
