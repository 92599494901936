import * as css from './ValidationsChart.css';
import {useSelector} from 'react-redux';
import {BRIGHT_GREEN, BRIGHT_RED} from '../../../../../../globalVariables/GlobalVariables';
import {getDateFormat, getEndOfDay, getStartOfDay, getTs} from '../../../../../../library/dates';
import {ValidationPieChart} from './components/ValidationPieChart';
import {ValidationStats} from './components/ValidationStats';
import {useEffect, useState} from 'react';
import setGlobalState from '../../../../../../redux/setGlobalState';
import {ValidationTicketRow} from './components/ValidationTicketRow/ValidationTicketRow';

export function ValidationsChart() {
    const tickets = useSelector((s:any) => s.realtime?.eventDay?.tickets || []);
    const transactions = useSelector((s: any) => s.realtime?.eventDay?.transactions || 0);
    const hourlyEntries = useSelector((s: any) => s.realtime?.eventDay?.hourlyEntries || []);
    const socketNewEnter = useSelector((s: any) => s.realtime?.eventDay?.socketNewEnter);
    const totalEnters = useSelector((s: any) => s.realtime?.eventDay?.totalEnters);
    const {timeStart, timeEnd, timeZone} = useSelector((s: any)=>s.event);
    
    const initialValidatedTickets = tickets.reduce((sum, t) => sum + (t.entered || 0), 0);
    const initialNotValidatedTickets = tickets.reduce((sum, t) => sum + (t.waiting || 0), 0);
    const currentHourKey = getDateFormat(getTs(null, timeZone), 'HH:00', timeZone);
    const currentDateKey = getDateFormat(getTs(null, timeZone), 'YYYY-MM-DD', timeZone);
    const initialLastHourValidations = hourlyEntries.find(
        entry => entry.hour === currentHourKey && entry.date === currentDateKey
    )?.validations || 0;
    
    const [validatedTickets, setValidatedTickets] = useState(initialValidatedTickets);
    const [notValidatedTickets, setNotValidatedTickets] = useState(initialNotValidatedTickets);
    const [lastHourValidations, setLastHourValidations] = useState(initialLastHourValidations);
    const totalTickets = initialValidatedTickets + initialNotValidatedTickets;
    const currentDate = getTs(null, timeZone);
    const startDate = getStartOfDay(timeStart, timeZone);
    const endDate = getEndOfDay(timeEnd, timeZone);
    const isDateInEventRange = currentDate >= startDate && currentDate <= endDate;
    const isEventPassed = currentDate > endDate;

    useEffect(() => {
        if(socketNewEnter) {
            setValidatedTickets(totalEnters);
            setNotValidatedTickets(totalTickets - totalEnters);
            if(isDateInEventRange && socketNewEnter > 0) {
                setLastHourValidations(prev => prev + socketNewEnter);
                const updatedHourlyEntries = hourlyEntries.map(entry => 
                    entry.hour === currentHourKey && entry.date === currentDateKey
                        ? {...entry, validations: (entry.validations || 0) + socketNewEnter}
                        : entry
                );

                if(!updatedHourlyEntries.some(entry => 
                    entry.hour === currentHourKey && entry.date === currentDateKey
                )) {
                    updatedHourlyEntries.push({
                        hour: currentHourKey,
                        date: currentDateKey,
                        validations: socketNewEnter
                    });
                }
                setGlobalState('realtime.eventDay.hourlyEntries', updatedHourlyEntries);
            }
        }
    }, [socketNewEnter, totalEnters]);

    return (
        <css.rightSectionContent className='free-color'>
            <ValidationPieChart 
                validatedTickets={validatedTickets}
                notValidatedTickets={notValidatedTickets}
                totalTickets={totalTickets}
            />
            <css.ticketsInfo> 
                <ValidationTicketRow 
                    count={validatedTickets}
                    total={totalTickets}
                    color={BRIGHT_GREEN}
                    type="entered"
                />
                <ValidationTicketRow 
                    count={notValidatedTickets}
                    total={totalTickets}
                    color={BRIGHT_RED}
                    type="waiting"
                />
            </css.ticketsInfo>
            <css.divider />
            <ValidationStats 
                transactions={transactions}
                lastHourValidations={lastHourValidations}
                hourlyEntries={hourlyEntries}
                isEventPassed={isEventPassed}
                eventDate={endDate}
            />
        </css.rightSectionContent>
    );
}
