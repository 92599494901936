import styled from 'styled-components';
import {BLACK, mobileMedia} from '../../../../../../globalVariables/GlobalVariables';
import realtimeFonts from '../../../../fonts';
import {timer as timerBase, wrapTimer} from '../../../timer/Timer.css';
import {dFlexStart, justifyStart} from '../../../../../../globalVariables/GlobalFlex';

export const timeText = styled.span`
    ${realtimeFonts.windowTitle}
    position: relative;
    ${mobileMedia}{
        white-space: nowrap;
    }
`;

export const date = styled(timerBase)`
    color: ${BLACK};
`;

export const timer = styled.div`
    width: max-content;
    margin-inline-start: 10px;
    ${justifyStart}
    ${wrapTimer}{
        ${justifyStart}
       
    }
    ${mobileMedia}{
        width: 100%;
        ${dFlexStart};
        gap: 5px;
        margin-inline-start: 0; 
        ${timerBase}{
            font-size: 14px;
        }
    }
`;
