import {BLACK} from '../../../../../../globalVariables/GlobalVariables';

function CloseStateIcon({color = BLACK}: { color?: string }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <g clipPath="url(#clip0_6632_11329)">
                <path d="M7.52581 8.21319L1.95721 6.72109C1.70338 6.65308 1.48702 6.48696 1.35565 6.25928C1.22428 6.0316 1.18871 5.761 1.25676 5.50702C1.32481 5.25305 1.49092 5.03649 1.71853 4.90499C1.94614 4.7735 2.21657 4.73782 2.47041 4.80583L5.73994 5.6819L3.26437 1.40879C3.13298 1.18337 3.09651 0.91495 3.16294 0.66256C3.22937 0.41017 3.39327 0.1945 3.61861 0.0629813C3.84395 -0.0685372 4.11226 -0.105146 4.36451 -0.0387518C4.61676 0.0276425 4.83227 0.191602 4.96366 0.417016L7.45112 4.69331L8.3197 1.45172C8.35211 1.3252 8.40946 1.20643 8.48836 1.10237C8.56727 0.998311 8.66616 0.91109 8.77921 0.845777C8.89227 0.780463 9.0172 0.738373 9.14671 0.722025C9.27622 0.705676 9.40769 0.715366 9.53339 0.750541C9.65788 0.784071 9.77438 0.842139 9.87611 0.921358C9.97784 1.00058 10.0627 1.09938 10.1258 1.21188C10.1888 1.32439 10.2288 1.44835 10.2432 1.57653C10.2577 1.7047 10.2464 1.83447 10.2101 1.95824L8.72313 7.50763C8.65801 7.75864 8.49647 7.97378 8.27364 8.10629C8.0508 8.23879 7.78471 8.27795 7.53325 8.21519L7.52581 8.21319Z" fill={color}/>
                <path d="M14.9773 13.2623L20.5236 14.7484C20.6489 14.7788 20.7669 14.834 20.8706 14.9106C20.9743 14.9873 21.0616 15.0838 21.1274 15.1947C21.1933 15.3056 21.2363 15.4285 21.254 15.5563C21.2717 15.6841 21.2637 15.8141 21.2305 15.9387C21.1971 16.0647 21.1392 16.1828 21.0601 16.2863C20.9809 16.3898 20.8822 16.4767 20.7694 16.5418C20.6566 16.607 20.532 16.6492 20.4028 16.666C20.2737 16.6829 20.1425 16.674 20.0168 16.6399L16.7666 15.769L19.2358 20.0659C19.3599 20.2899 19.3922 20.5534 19.3259 20.8009C19.2596 21.0483 19.0999 21.2604 18.8804 21.3923C18.7685 21.4591 18.6444 21.5027 18.5153 21.5207C18.3863 21.5387 18.255 21.5306 18.1292 21.4969C18.0034 21.4631 17.8856 21.4045 17.7828 21.3244C17.6801 21.2444 17.5944 21.1445 17.5309 21.0307L15.0673 16.7608L14.1908 20.0321C14.1221 20.2885 13.9544 20.507 13.7247 20.6398C13.4949 20.7725 13.222 20.8085 12.9658 20.7398C12.7096 20.6712 12.4912 20.5035 12.3586 20.2737C12.226 20.0439 12.1901 19.7708 12.2587 19.5144L13.7541 13.9338C13.8285 13.6841 13.9979 13.4734 14.226 13.3474C14.454 13.2213 14.7223 13.1898 14.9733 13.2596L14.9773 13.2623Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_6632_11329">
                    <rect width="22" height="22" fill="none"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default CloseStateIcon;
