import * as css from './ReadyMapStep.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Area from '../../../../assets/icons/Area';
import sanitizeHtml from '../../../../library/sanitizeHtml';
import StepContext from '../stepContext/StepContext';
import StepRight from '../stepRight/StepRight';
import StepLeft from '../StepLeft/StepLeft';
import useShow from '../../../../hooks/useShow';
import ExplainMap from '../explainMap/ExplainMap';
import Btn from '../../../../component/button/btn/Btn';
import {useField} from 'formik';

function ReadyMapStep () {
    const map = useSelector((s: any) => s.event.map);
    
    const {t} = useTranslation('newEvent');
    const {open, close, isOpen} = useShow();
    const [{value},,helpers]=useField('map');
    const openDescription = (action) => {
        helpers.setValue({...value,action});
        open();
    };
    return (
        <StepContext>
            <css.wrapReadyContent $hasPop={isOpen}>
                <StepRight name="map">
                    <css.title>{map?.title || t('hallsMap')}</css.title>
                    <css.text>{t('countSeatsTitle',{count:map?.seatCount || 10000})}</css.text>
                    <css.wrap>
                        <css.wrapperDetails>
                            {map?.areas?.length ? (
                                map.areas.map((item, index) => (
                                    <css.text key={index}>{`${item.title} ${item.amount}`}</css.text>
                                )) ) : (<>
                                <css.text>{`${t('defaultBlock')}`}</css.text>
                                <css.massageText>{t('defaultMassage')}</css.massageText></>)}
                        </css.wrapperDetails>
                        { (map?.areas?.length > 1 && map?.svg) ? (<div dangerouslySetInnerHTML={sanitizeHtml(map.svg)} />) : 
                            (<Area />)}
                    </css.wrap>
                </StepRight>
                {isOpen && <ExplainMap name={'map'} close={close} action={'mapError'} title={t('explainOnYourErrorMap')} />}
                <StepLeft name="map" >
                    {!isOpen &&<Btn disabled={null} className="white" onClick={()=>openDescription('mapError')}>{t('mapError')}</Btn>}
                </StepLeft>
            </css.wrapReadyContent>
        </StepContext>
    );
}

export default ReadyMapStep;
