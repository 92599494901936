function isYouTubeUrl(url:string)  {
    try {
        const parsedUrl = new URL(url);
        const hostname = parsedUrl.hostname.toLowerCase();
        return (
            hostname === 'www.youtube.com' ||
        hostname === 'youtube.com' ||
        hostname === 'youtu.be' ||
        hostname.endsWith('.youtube.com')
        );
    } catch (e) {
        return false;
    }
}

export default isYouTubeUrl;