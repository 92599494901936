import * as css from './ValidationTicketRow.css';
import {formatNumber} from '../../../../../../../../library/numberFunction';
import {useTranslation} from 'react-i18next';
import {useContext} from 'react';
import {CompactEventDayContext} from '../../../CloseEventDay/CloseEventDay';
import {calculatePercentage} from '../../../../../../../../functions/calculatePercentage';

interface ValidationTicketRowProps {
    count: number;
    total: number;
    color: string;
    type: 'entered' | 'waiting';
}

export function ValidationTicketRow({count, total, color, type}: ValidationTicketRowProps) {
    const {t} = useTranslation('realtime');
    const isCompact = useContext(CompactEventDayContext)?.isCompact || false;

    return (
        <css.wrap>
            {isCompact && <css.dot $color={color}/>}
            {!isCompact && <css.precentage>{calculatePercentage(count, total)}
            </css.precentage>}
            <css.ticketRow>
                {isCompact && <css.precentage>
                    {calculatePercentage(count, total)}
                </css.precentage>}
                {!isCompact && <css.dot $color={color}/>}
                <css.wrap>
                    <css.validationTextBold>
                        {formatNumber(count)}
                    </css.validationTextBold>
                    <css.validationText>{t(type)}</css.validationText>
                </css.wrap>
            </css.ticketRow>
        </css.wrap>
    );
}
