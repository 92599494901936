import React, {useEffect, useRef} from 'react';
import * as css from './InputList.css';
import List from '../../list/List';
import {useField} from 'formik';
import useShow from '../../../hooks/useShow';
import useMobile from '../../../hooks/useMobile';

function InputListSearch({list=[], name, icon, text, onChange = () => null, isSelectable = false, className, children, description = '', onSearch = null, getMoreAction=null}) {
    const {open, close, isOpen, showClass} = useShow();
    const [, meta, helpers] = useField(name);
    const click = (item) => {
        helpers.setTouched(true);
        helpers.setValue(item.key);
        onChange(name, item.key);
        if(!isSelectable)
            close();
    };

    const openClose = () => {
        if(list?.length)
            if(isOpen)
                close();
            else
                open();
    };
    useEffect(() => {
        // if(!list.length)
        //     close();
    }, [list.length]);

    const onSearchChange=e=>{
        e.stopPropagation();
        if(onSearch) {
            onSearch(e.target.value);
            if(e.target.value === '') {
                close();
            } else {
                open();
            }
        }
    };

    const ref=useRef();
    useEffect(()=>{
        if(ref.current)
            ref.current.focus();
    },[ref.current]);

    const isMobile=useMobile();
  
    return (
        <css.container data-cy= {`${name}Validation`} className={`${className}  ${(!!meta.touched) && !isSelectable} ${!list.length && ' disabled'}`}>
            {icon && <css.icon icon={icon} className={className}>
                {children}
            </css.icon>}
            <css.wrap>
                <css.input
                    data-cy ={`${name}Input`}
                    onClick={openClose}
                    className={`inputList ${className}`}> 
                    {!isMobile&&<input type='text' ref={ref} onChange={onSearchChange} placeholder={text || ''}/>}
                    {isOpen && <List close={close} onClick={click} list={list} top={25} left={'1px'} showClass={showClass} width={'100%'} getMoreAction={getMoreAction}/>}
                </css.input>
                <css.title>{!isSelectable && text}&nbsp;<span>{description}</span></css.title>
            </css.wrap>
        </css.container>
    );
}
export default InputListSearch;