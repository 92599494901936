import React from 'react';
import * as css from './AreaSVG.css';
import Area from '../../../assets/icons/Area';
import sanitizeHtml from '../../../library/sanitizeHtml';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';

function AreaSVG({title, seatCount, svg, eid}) {
    const {t} = useTranslation('newEvent');
    const [{value},,helpers]=useField('map');
    const isSelected = value.eid === eid;

    const onClick = () => {
        helpers.setValue({eid,title,action:''});
    };
    return (
        <css.container onClick={onClick} isSelected={isSelected}>
            {svg ? (
                <div dangerouslySetInnerHTML={sanitizeHtml(svg)} />
            ) : (
                <Area />
            )}
            <css.title>{title}</css.title>
            <css.subtitle>{t('countSeats',{count:seatCount})}</css.subtitle>
        </css.container>
    );
}

export default AreaSVG;