import {useEffect} from 'react';
import * as css from './soldTickets.css';
import {useSelector} from 'react-redux';
import {getGivechakTargets} from '../../../../../redux/realtime/realtimeActions';
import {useTranslation} from 'react-i18next';
import {formatRealtimeNumber} from '../../../functions';
import {eventTypes} from '../../../eventRealtime/eventTypes';
import {XHR_LOADING_STATE} from '../../../../../assets/constants/constants';
import SoldTicketsTemplate from './soldTicketTemplate';
import {CircleProgress, Insight, MainDetail} from '../../windowItems/windowItems';

function GivechakTarget() {
    const {eid, currencySymbol, eventType} = useSelector((s: any)=>({
        eid: s.event?.eid,
        currencySymbol: s.event?.currencySymbol,
        eventType: s.event?.type,
    }));
    const stats = useSelector((s:any) => s.realtime?.event.targets ||{});
    
    const eventConfig = eventTypes.givechak.ticketsTitle;
    const {t} = useTranslation('realtime');
    const isLoading = useSelector((s:any)=>s.realtime?.event?.getTargetsXHR === XHR_LOADING_STATE);
    
    useEffect(()=>{
        if(eid) getGivechakTargets(eid);
    },[eid]);
    
    if(isLoading) {
        return <SoldTicketsTemplate />;
    }
    return ( stats ? <css.container>
        <css.content>
            {eventConfig.target && <MainDetail item={{
                title: eventConfig.target,
                value: stats.target,
                text: `${t('achievements')} ${formatRealtimeNumber(stats.achievements || 0, true)}${currencySymbol}`
            }} />}
            {eventConfig.donors && <MainDetail item={{
                title: eventConfig.donors,
                value: stats.amountOrders,
            }} />}
        </css.content>
        <css.insights>
            <css.insightsNumbers $eventType={eventType}>
                <Insight item={{
                    value: `${currencySymbol}${formatRealtimeNumber(stats.withRef, true)}`,
                    text: eventConfig.withRef
                }} width={80} />
                <Insight item={{
                    value: `${currencySymbol}${formatRealtimeNumber(stats.withoutRef, true)}`,
                    text: eventConfig.withoutRef
                }} width={80} />
                <Insight item={{
                    value: `${currencySymbol}${formatRealtimeNumber(stats.highestDonate, true)}`,
                    text: eventConfig.highestDonate
                }} width={80} />
                <Insight item={{
                    value: `${currencySymbol}${formatRealtimeNumber(stats.averagePerDonation, true)}`,
                    text: eventConfig.averagePerDonation
                }} width={80} />
            </css.insightsNumbers>
            {stats.target > 0 && <CircleProgress amount={stats.target} sold={stats.achievements} text={'goalText'} />}        </css.insights>
    </css.container>:<></>
    );
}
export default GivechakTarget;