import * as css from './videoForm.css';
import {useTranslation} from 'react-i18next';
import Input from '../../../component/input/Input';
import TextareaInput from '../../../component/input/textareaInput/TextareaInput';
import InputList from '../../../component/input/inputList/InputList';
import {useField} from 'formik';
import MemberRoleBtn from '../../../pages/team/memberRoleBtn/MemberRoleBtn';
import TooltipAbsolute from '../../../component/tooltip/tooltipAbsolute/TooltipAbsolute';
import {roles} from '../../../pages/team/Team';
import {menuEvent} from '../../menu';
import {menuAccount} from '../../menuAccount';
import {getPageTranslation} from '../supportVideos/suportVideos';
import Camera from '../../../assets/icons/camera';
import VideoFormPopBottom from './vidoeFormPopBottom';

export const ALL_TEAM_KEY = 'allTeam';
    
const getTitle = ({packagePage, page, subPage, routeType})=>`${routeType||''}${routeType?'_':''}${packagePage || page || 'unknown_page'}${subPage?'_':''}${subPage||''}`;

function VideoForm({videoId, removeVideo, formRef}) {
    const {t} = useTranslation('layout');
    const inputProps=(name)=>({name,text:t(name),description:t(`${name}Desc`),className:name});
    const [DRoleField,DRoleMeta, DRoleHelper]=useField('permission');
    const [pageField,pageMeta]=useField('page');
    const rolesList = [ALL_TEAM_KEY, ...roles].map(role=>({
        key: role,
        text: t(role),
    }));
    
    const onRoleChanged = (name, itemKey) => {
        if(itemKey === ALL_TEAM_KEY)
            DRoleHelper.setValue([ALL_TEAM_KEY]);
    };
    
    const handleMemberRoleChange = (role) => {
        DRoleHelper.setValue(DRoleField.value.filter(r => role !== r));
    };
    const classes = `${DRoleMeta.touched && (DRoleMeta.error ? ' invalid' : ' valid')}`;
    const SClasses = `${pageMeta.touched && (pageMeta.error ? ' invalid' : ' valid')}`;
    
    const paths = [
        {path: '/login/forgetPassword', routeType:''},
        {path: '/login', routeType:''},
        {path: '/signup', routeType:''},
        ...Object.values(menuEvent).map(menuItem=>({path:menuItem.path, routeType: 'event'})),
        ...Object.values(menuAccount).map(menuItem=>({path:menuItem.path, routeType: 'package'})),
        {path: '/notFound', routeType: '404'}];

    const pagesList = Array.from(new Set(paths.map(path =>{
        if(path?.path){
            const splitPath = path.path.split('/');
            const title = getTitle({packagePage:'',page:splitPath[1],subPage: splitPath[2], routeType:path.routeType});
            return {key: title, text: getPageTranslation(t, title)};
        }
    }))).filter(Boolean);

    return (<>
        <css.container>
            <Input  events={undefined} icon={<Camera />} forwardRef={undefined}{...inputProps('title')}/> 
            <Input events={undefined} icon={'copy'} forwardRef={undefined}{...inputProps('videoLink')}/>
            <TextareaInput icon={false} name={'description'} text={t('description')} description={''} events={{}} />
            <css.roleSelectInput className={classes}>
                <InputList onChange={onRoleChanged} multiSelect={true} name='permission' list={rolesList} text={t('userPermission')} isSelectable={true} icon={undefined} className={undefined} valid={undefined} onFocus={undefined} > </InputList>
                <css.rolesConteiner>{DRoleField.value?.map(role=><MemberRoleBtn onclick={handleMemberRoleChange} key={role} member={{role}} />)}</css.rolesConteiner>
                {DRoleMeta.touched && DRoleMeta.error && (<TooltipAbsolute show={true} text={DRoleMeta.error} dataTitleIcon={undefined} icon={undefined} /> )}          
            </css.roleSelectInput> 
            <css.roleSelectInput className={SClasses}>
                <InputList onChange={onRoleChanged} multiSelect={false} name='page' list={pagesList} text={getPageTranslation(t, pageField.value) || t('systemArea')} isSelectable={true} icon={undefined} className={undefined} valid={undefined} onFocus={undefined} > </InputList>
                {pageMeta.touched && pageMeta.error && (<TooltipAbsolute show={true} text={pageMeta.error} dataTitleIcon={undefined} icon={undefined} /> )}          
            </css.roleSelectInput> 
        </css.container>
        <VideoFormPopBottom hasVideoId={!!videoId} remove={removeVideo} formRef={formRef} />
    </>);
   
}

export default VideoForm;