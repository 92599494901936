import styled from 'styled-components';
import {BGImageCenter, ellipsisLines, SUCCESS_COLOR} from '../../../globalVariables/GlobalVariables';
import {success} from '../../../assets/symbols/symbols/Symbols.css';

export const container = styled.div`
  position: relative;
  width:180px;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 10px #00000029;
  text-align: center;
  padding: 10px;
  height: 150px;
  margin-inline-end: 10px;
  &::after{
    ${props => (props.isSelected &&  "content: ''")};
    position:absolute ;
    border: 2px solid #FFFFFFB1;
    border-radius: 50%;
    width:20px;
    height:20px;
    background-color: ${SUCCESS_COLOR};
    ${BGImageCenter(success,'10px')};
    inset-inline-start: 15px;
    inset-block-end: 15px;
  }
    svg{
       height:67px;
    }
`;

export const title = styled.h3`
  font-size: 14px;
  ${ellipsisLines(2)};
  font-weight: bold;

`;

export const subtitle = styled.p`
  font-size: 11px;
`;