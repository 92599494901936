import * as css from './ParticipantTop.css';
import useShow from '../../../../hooks/useShow';
import {useSelector} from 'react-redux';
import {
    getHourMinutes,checkIfToday as isToday,
    tsGetDateAndMonth,
    tsGetDayOfWeek
} from '../../../../library/dates';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import CourseMeetingsList from '../../courseMeetingsList/CourseMeetingsList';

function ParticipantTop() {
    const {pageId} = useParams();
    const tdid = pageId;

    const {t} = useTranslation('ticket');
    const globalT = useTranslation('global');
    const {open, close, isOpen, showClass} = useShow();
    const ticketMeetings=useSelector(s=>s.attendance.tickets.find(t=>t.tid===s.attendance.currentTid)?.meetings||[]);
    const date =ticketMeetings.find(t=>t.tdid=== Number(tdid))?.date;
    const stringToday = (date&&isToday(date))?`${t('attendance:today')} - `:'';

    return (
        <css.container  >
            <css.date onClick={e=>{
                e.stopPropagation();isOpen ? close() : open();}}>{ date && ` ${stringToday} ${globalT.t('fullDay'+(tsGetDayOfWeek(date)))}  ${tsGetDateAndMonth(date)}   ${getHourMinutes(date)}`}</css.date>
            {date&&<css.arrow/>}
            {isOpen&&<CourseMeetingsList showClass={showClass} close={close}/>}

        </css.container>
    );
}

export default ParticipantTop;
