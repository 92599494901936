import ReportCard from './reportCard/ReportCard';
import * as css from './ReportCash.css';
import {useSelector} from 'react-redux';
import {postEventReport} from '../../../redux/event/eventActions';
import {useEffect, useState} from 'react';
import {getReportEvents, getInvoiceDates} from '../../../redux/cash/cashActions';
import {useTranslation} from 'react-i18next';
import {getDate} from '../../../library/dates';
import MasterPermission from '../../components/rolePermission/MasterPermission';
import Btn from '../../../component/button/btn/Btn';

function ReportCash() {
    const {eventList, dateList, getEventListXHR, getDateListXHR} = useSelector((s:any) => s.cash);
    const {t} = useTranslation('cash');
    const [selectedEvents, setSelectedEvents] = useState([]);
    
    const renderEventList = eventList.map((event) => {
        return {
            text: `${event.eid} - ${event.title}`,
            eid: event.eid,
            type: 'checkbox',
            selected: selectedEvents.includes(event.eid),
            classes: selectedEvents.includes(event.eid) ? 'selected' : ''
        };
    });
    
    const renderDateList = dateList.map((bill) => {
        return {
            text: getDate(bill.date), 
            link: bill.invoice_link
        };
    });
    
    const invoiceOnClick = (item) => {
        item?.link && typeof item?.link === 'string' && window.open(item.link, '_blank');
    };
    
    const eventOnClick = (item) => {
        const isSelected = selectedEvents.includes(item.eid);
        
        if(isSelected) {
            setSelectedEvents(prev => prev.filter(eid => eid !== item.eid));
        } else {
            setSelectedEvents(prev => [...prev, item.eid]);
        }
    };
    
    const handlePdfExport = () => {
        if(selectedEvents.length > 0) {
            postEventReport(JSON.stringify(selectedEvents), 'pdf');
        }
    };
    
    const handleExcelExport = () => {
        if(selectedEvents.length > 0) {
            postEventReport(JSON.stringify(selectedEvents), 'excel');
        }
    };
    
    useEffect(() => {
        if(!getEventListXHR)
            getReportEvents().then();
        if(!getDateListXHR)
            getInvoiceDates().then();
    }, []);
    
    return (
        <css.popContain>
            <ReportCard 
                title={t('yourInvoices')} 
                subTitle={t('invoiceSubTitle')}
                listButton={{
                    title: '',
                    text: t('selectDate'),
                    list: renderDateList,
                    onClick: invoiceOnClick
                }}
                whiteButton={{
                    text: t('lastInvoice'), 
                    onClick: () => invoiceOnClick(renderDateList[0])
                }}
            />
            
            <ReportCard 
                title={t('yourEvents')} 
                subTitle={t('eventSubTitle')}
                listButton={{
                    title: '',
                    text: t('selectEvent'),
                    list: renderEventList,
                    onClick: eventOnClick
                }}
                listTitle={
                    <css.title>
                        {t('export')}
                        <css.buttons>
                            <Btn className={`btnExport white btnPdf ${selectedEvents.length === 0 ? 'disabled' : ''}`} onClick={handlePdfExport}  disabled={undefined}>{t('pdf')}</Btn>
                            <MasterPermission>
                                <Btn className={`btnExport white btnExcel ${selectedEvents.length === 0 ? 'disabled' : ''}`} onClick={handleExcelExport} disabled={undefined}>{t('excel')}</Btn></MasterPermission>
                        </css.buttons>
                    </css.title>
                }
                whiteButton={{
                    text: t('lastEvent'), 
                    onClick: () => eventList.length > 0 ? postEventReport(JSON.stringify([eventList[0].eid]), 'pdf') : null
                }}
            />
        </css.popContain>
    );
}

export default ReportCash;