import {useEffect, useRef, useState, useCallback} from 'react';
import {getNow, getTime} from '../../../../../library/dates';

interface UseUpdateTimerProps {
    onUpdate: () => void;
    duration?: number;
    enabled: boolean;
}

export const useUpdateTimer = ({onUpdate, duration = 10 * 60, enabled}: UseUpdateTimerProps) => {
    const [remainingTime, setRemainingTime] = useState('');
    const timerRef = useRef({
        interval: null,
        timeout: null,
        endTime: null
    });

    const clearTimers = useCallback(() => {
        if(timerRef.current.interval) clearInterval(timerRef.current.interval);
        if(timerRef.current.timeout) clearTimeout(timerRef.current.timeout);
        timerRef.current = {interval: null, timeout: null, endTime: null};
        setRemainingTime('');
    }, []);

    const handleUpdateNow = () => {
        clearTimers();
        onUpdate();
    };

    useEffect(() => {
        if(enabled && !timerRef.current.endTime) {
            timerRef.current.endTime = getNow() + duration;
            timerRef.current.interval = setInterval(() => {
                const remaining = timerRef.current.endTime! - getNow();
                if(remaining <= 0) {
                    handleUpdateNow();
                } else {
                    setRemainingTime(getTime(remaining));
                }
            }, 1000);
        }

        return () => {
            if(!enabled && !timerRef.current.endTime) {
                clearTimers();
            }
        };
    }, [enabled]);

    useEffect(() => {
        return clearTimers;
    }, []);

    return {remainingTime, handleUpdateNow};
};
