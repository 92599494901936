import {useEffect} from 'react';
import {getRealtimeMessages, approveSystemMessage} from '../../../../../redux/realtime/realtimeActions';
import {useSelector} from 'react-redux';
import * as css from './systemMessage.css';
import {useTranslation} from 'react-i18next';
import Btn from '../../../../../component/button/btn/Btn';
import {Link} from 'react-router-dom';
import {TSystemMessage} from '../../../../../redux/realtime/realtime.type';
function SystemMessage({isPop=false, close, message}:{isPop:boolean, close?: any, message: TSystemMessage}){
    const systemMessageXHR = useSelector((s:any) => s.realtime.getSystemMessageXHR);
    const eid = useSelector((s:any) => s.event?.eid);
    const {t} = useTranslation('realtime');
    useEffect(()=>{
        if(!systemMessageXHR && eid)
            getRealtimeMessages().then();
    },[]);

    const approve = (event) => {
        event.preventDefault();// Prevent the default action of the link
        approveSystemMessage(message.smid);
        if(close)
            close();
    };
    const btnApproved=()=>!!(isPop && message.watchRequired )&&<Btn onClick={approve} className={'white'} disabled={undefined} ><css.approved/>{t('approveView')}</Btn>;
    return (
        message?.status?
            message.title? <css.container  image={!isPop && message.image} >
                <css.title>{message.title}</css.title>
                <css.message><p>{message.message}</p></css.message>
                <css.buttons>
                    {message.link && <Btn target='_blank' href={message.link} className={'yellow'} onClick={undefined} disabled={undefined} >{message.linkText || t('showMe')}</Btn>}
                    {btnApproved()}
                </css.buttons>
            </css.container>
                :
                <css.container className='no-title' image={!isPop && message.image} >
                    <Link href={message.link} target='_blank'>
                        {btnApproved()}
                    </Link>
                </css.container>

            :<></>
    );
}

export default SystemMessage;