/* eslint-disable spellcheck/spell-checker */
import styled, {css} from 'styled-components';
import {error, success} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, boxShadow, ellipsis, getStaticImage, mobileMedia} from '../../../../globalVariables/GlobalVariables';
import {topClose} from '../../../../component/circleClose/CircleClose.css';
import {titleWrap, description, textWrap} from '../../../../component/input/basicInput/BasicInput.css';
import zIndex from '../../../../globalVariables/zIndex';
const IMAGE_WIDTH='200px',CLOSE_WIDTH='30px', IMAGE_WIDTH_MOBILE='130px';

export const wrapper = styled.div`
    margin-top: 60px;
    background-color: #FFF;
    border-radius: 20px;
    width: 650px;
    max-width: 95%;
    padding: 0 15px 10px 15px;
    transition: margin-top  300ms,background-color  300ms;
    box-shadow: rgba(0, 0, 0, 0.333) 0 1px 9px;  :focus {
       outline: none;
    }
    input{
      font-size: 16px;
    }
    ${titleWrap}{
      font-size: 13px;
      &.empty {
        font-size: 16px;
      }
    }
    ${description}{
      font-size: 13px;
    }
    ${textWrap}{
      bottom:-20px;
    }
    &.close{
        background-color: #ffffff;
        margin-top: 15px;
        padding-bottom:0 ;
    }
  ${mobileMedia}{
    margin-top: 20px;
  }
`;
export const top = styled.div`
    position: relative;
    display: flex;
    /* justify-content: space-between; */
    align-items: flex-start;
    height: 95px;
    padding-top: 10px;
    padding-inline: 2px;
    transition: all 300ms;
    /* margin-top: 15px; */
    &.close{
        margin-top: 0;  
        align-items: center;
        padding-top: 0;

      /* overflow: hidden; */
    }
`;

const errorSign=`
    ${BGImageCenter(error,'4px')};
    border: 2px solid rgba(255, 255, 255, 0.694);
    background-color: rgb(221, 47, 33);
`;

const validSign=`
    border: 2px solid #FFFFFFB1;
    background-color: #AED11C;
    ${BGImageCenter(success,'10px')};
`;

export const close = styled.div`
    width: ${CLOSE_WIDTH};
    height: 30px;
    transition: background-color 300ms;
    border-radius: 50%;
    ${topClose}{
      background-color: #ffffff;
    }
    &.error{${errorSign}}
    &.valid{${validSign}}
    ${mobileMedia}{
      ${boxShadow};
      z-index:${zIndex.baseRaised};
    }
`;

const backgroundSize={
    date:'140px',
    category:'210px',
    description:'140px',
    duplicate:'100px',
    logo:'125px',
    map:'120px'
};
const closeBackgroundSize={
    date:'100px',
    location:'120px',
    category:'180px',
    description:'112px',
    duplicate:'100px',
    map:'90px'
};
const closeInsetInlineStart={
    category:'50px',
};
const insetInlineStart={
    category:'40px',
};

const closeTop={
    location:'-18px',
    budgetIndex:'-18px',
    duplicate: '-18px',
    locale:'-22px',
    logo:'-22px',
    target:'-22px'
};
const bottom={
    location:'-50px',
    budgetIndex:'-40px',
    duplicate: '-50px',
    locale:'-40px',
    logo:'-50px',
    target:'-50px',
    map:'-80px'
};

const IMAGE_TOP = '50px';
export const image = styled.div`
    width: ${IMAGE_WIDTH};
    height: calc(100% + ${IMAGE_TOP});
    margin-top: -${IMAGE_TOP};
    transition: all 300ms;
    
    &.close {
    margin-top: 0;
    height: 100%;
    overflow: hidden;
      &::after{
        background-size: ${p=>closeBackgroundSize[p.name]||'120px'};
        inset-inline-end: ${p=>closeInsetInlineStart[p.name]||'0'};
        top: ${p=>closeTop[p.name]||'0'};
        height: calc(100% + 50px);
        background-position: ${p=>p.theme.rtl?'right':'left'} center;
      }
    }
    position: relative;
    &::after{
      content: '';
      ${(p) => BGImageCenter(getStaticImage(`newEvent/${p.name}.png`),backgroundSize[p.name]||'160px')}
      position: absolute;
      width:100%;
      inset-inline-end: ${p=>insetInlineStart[p.name]||'0'};
      height: calc(110% + ${IMAGE_TOP});
      bottom: ${p=>bottom[p.name]||'-73px'};
      pointer-events: none;
    }
  ${mobileMedia}{
    width: ${IMAGE_WIDTH_MOBILE};
    margin-top: -18px;
    margin-inline-start: -30px;
    &::after{
      background-size:140px;
    }
    &.close{
      background-size:80px;

    }
  }
`;
export const topTitle = styled.div`
    padding: 0;
    white-space: nowrap;
    transition: all 500ms;
    width:fit-content;
    text-align: end;
    min-width:calc(100% - ${IMAGE_WIDTH} - ${CLOSE_WIDTH});
    display: flex;
    justify-content: flex-end;
    &.close{
        min-width: 0;
        font-size: 18px;
        font-weight: 500;
      ${mobileMedia}{
        font-size: 16px;
      }
    }
  ${mobileMedia}{
    min-width:calc(100% - ${IMAGE_WIDTH_MOBILE});

  }
`;
export const valueOnClose=styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px;  
    font-weight: 900;
    text-align: start;
    `;
export const wrapCloseText=styled.div`
    text-align: start;
    ${ellipsis};
    width: 220px;
    &.required{
      width: 315px;
    }
    ${mobileMedia}{
      &.required{
      width: 200px;
      }
    }
`;
export const notRequired=styled.div`
  margin-inline-end: 11px;
  width: fit-content;
  padding: 2px 10px;
  height: 20px;
  background-color: rgb(255, 218, 13);
  border-radius: 16px;
  font-size: 12px;
   &.close{
    position: absolute;
    inset-inline-end: 0;
   }

`;

const beforeCSS=css`
&::before{
  color: black;
  border: 1px solid red;
  width:max-content;
  padding-inline: 30px 10px;
}`;

const afterMobileCSS=css`
::after{
  bottom: 10px;
}
::before{
  bottom: 8px;
}
`;

export const warpContext = styled.div`
    position: relative;
    z-index: ${zIndex.baseRaised};
    height: max-content;
    max-height: 800px;
    overflow: visible;
    transition: max-height 500ms ease-in;  
    &.isAnimateNow{
      overflow: hidden;
    }
        &.close {
            max-height: 0;
            &::after{
                display: none;
            }
    }
    &::after{
        content: '';
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        bottom: 10px;
        inset-inline-start: 10px;
        background-color: transparent;
        transition: background-color 500ms  ease-in;
    }
    &::before{
        content: '${p=>p.errorMessage}';
        width: 0;
        height: 22px;
        border-radius: 18px;
        background-color:transparent ;
        position: absolute;
        bottom: 9px;
        z-index: ${zIndex.baseRaised};
        text-align: center;
        inset-inline-start: 9px;
        height: 30px;
        line-height: 30px;
        transition: all 600ms;
        border: 1px solid transparent;
        white-space: nowrap;
        overflow: hidden;
        color: transparent;
      }
      &.error{
        ${p=>p.isShowErrorMessages?beforeCSS:''}
        &::after{
            ${errorSign}
        }
    }
    &.valid{
        &::after{
            ${validSign}
        }    
    }
  ${mobileMedia}{
    max-height: 1300px;
    ${p=>p.isShowErrorMessages?afterMobileCSS:''}
  }
`;