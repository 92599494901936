const ATTR_REGULAR = {
    fill: '#47b2db',
    stroke: '#999',
    strokeWidth: 1,
    strokeLinejoin: 'round'
};

const ATTR_TAKEN = {
    fill: '#967b7b',
    stroke: '#333',
    strokeWidth: 1,
    strokeLinejoin: 'round'
};

const ATTR_STAGE = {
    fill: '#db4747',
    stroke: '#333',
    strokeWidth: 1,
    strokeLinejoin: 'round'
};

const ATTR_PRE = {
    fill: 'white',
    stroke: '#333',
    strokeWidth: 1,
    strokeLinejoin: 'round'
};

const getBoundingBox = (pathData) => {
    const numbers = pathData.match(/-?\d+\.?\d*/g).map(Number);
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    for(let i = 0; i < numbers.length; i += 2) {
        const x = numbers[i];
        const y = numbers[i + 1];
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, y);
    }

    return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY
    };
};

const createPathAttributes = (attrs, scale = null, x = 0, y = 0) => {
    const attributes = [
        `fill="${attrs.fill}"`,
        `stroke="${attrs.stroke}"`,
        `stroke-width="${attrs.strokeWidth}"`,
        `stroke-linejoin="${attrs.strokeLinejoin}"`
    ];

    if(scale) {
        const [scaleX, scaleY] = scale.split(',');
        attributes.push(`transform="translate(${x},${y}) scale(${scaleX},${scaleY})"`);
    }

    return attributes.join(' ');
};

const createTextElement = ({x, y, text, fontSize, fontFamily = 'Assistant'}) => `
    <text 
      x="${x}" 
      y="${y}"
      font-family="${fontFamily}"
      fill="#fff"
      text-anchor="middle"
      dominant-baseline="middle"
      font-size="${fontSize}px"
    >${text}</text>`;

export const generateSVG = (data) => {
    const width = data.width || '763';
    const height = data.height || '761';
    const parts = [];

    parts.push(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}">`);

    // Draw areas
    if(data.area) {
        Object.entries(data.area).forEach(([, area]) => {
            if(!area.path) return;

            const isSoldOut = area.count === 0;
            const attrs = isSoldOut ? ATTR_TAKEN : ATTR_REGULAR;
            const attributes = createPathAttributes(attrs, area.scale, area.x, area.y);

            parts.push(`  <path d="${area.path}" ${attributes}/>`);

            if(area.title) {
                const bbox = getBoundingBox(area.path);
                const textX = bbox.x + bbox.width / 2;
                const textY = bbox.y + bbox.height / 2;
                const fontSize = Math.min(30, Math.max(12, width * 0.045));

                parts.push(createTextElement({
                    x: textX,
                    y: textY,
                    text: area.title,
                    fontSize
                }));
            }
        });
    }

    // Draw pre-areas
    if(data['area-pre']) {
        Object.entries(data['area-pre']).forEach(([, value]) => {
            if(!value.path) return;

            const attributes = createPathAttributes(ATTR_PRE, value.scale, value.x, value.y);
            parts.push(`  <path d="${value.path}" ${attributes}/>`);
        });
    }

    // Draw stage
    if(data.stage?.path) {
        const attributes = createPathAttributes(
            ATTR_STAGE,
            data.stage.scale,
            data.stage.x,
            data.stage.y
        );
        parts.push(`  <path d="${data.stage.path}" ${attributes}/>`);

        const stageBBox = getBoundingBox(data.stage.path);
        parts.push(createTextElement({
            x: stageBBox.x + stageBBox.width / 2,
            y: stageBBox.y + stageBBox.height / 2,
            text: '',
            fontSize: width * 0.045
        }));
    }

    parts.push('</svg>');
    return parts.join('\n');
};
