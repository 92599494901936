import styled from 'styled-components';
import {unStyledButton} from '../supportVideos/supportVideos.css';
import {alignEnd, dFlex, dFlexCenter} from '../../../globalVariables/GlobalFlex';
import {VERY_VERY_LIGHT_GRAY} from '../../../globalVariables/GlobalVariables';

export const container=styled.div`
    ${dFlex}
    width:100%;
    height:50px;
    cursor:pointer;
    &.focus{
        ${dFlex}
        ${alignEnd}
        border-radius: 5px;
        background: ${VERY_VERY_LIGHT_GRAY};
        height: 40px;
        padding: 11px 26px 11px 10px;
    }   
`;

export const close = styled(unStyledButton)``;
export const titles = styled.div`
    width:100%
`;
export const title = styled.div`
    font-size: 18px;
    font-weight: 600;
`;
export const titleExplain = styled.div`
    letter-spacing: 0.84px;
`;
export const actionButton=styled.div`
    ${dFlexCenter}
    margin-inline-start: -30px;
    transition:all 400ms;
    width: 12px;
    font-size: 12px;
    min-width: fit-content;
    height: 100%;
    gap: 5px;
`;
export const text=styled.div`
    width:100%;
    height: 100%;
    font-size: 16px;
    padding:10px 20px;
    ${dFlexCenter}
`;
export const input=styled.input`
    width:100%;
    border:0;
    background:transparent;
    padding:0 10px;
    font-size: 18px;    
`;