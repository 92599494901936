import * as css from './AddCategory.css';
import Btn from '../../../../component/button/btn/Btn';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';
import {useSelector} from 'react-redux';
import Input from '../../../../component/input/Input';
import {addCategory, updateCategory} from '../../../../redux/categories/categoriesActions';
import setGlobalState from '../../../../redux/setGlobalState';

function AddCategory({categoriesList,close}) {
    const {t} = useTranslation('newEvent');
    const [{value: title},,{setValue: setTitle}]=useField('categoryTitle');
    const [{value: index},, {setValue: setIndex}]=useField('categoryIndex');

    const pid = useSelector(s=>s.account.pid);
    const back = () => {
        setTitle(null);
        setIndex(null);
        close();
    };
    const onClick = async() => {
        if(index){
            await updateCategory({pid:pid,title:title,id:categoriesList[index].catId})
                .then(()=>{
                    const updatedCategories = [
                        ...categoriesList.slice(0, index),
                        {...categoriesList[index], title: title},
                        ...categoriesList.slice(index + 1)
                    ];
                    setGlobalState('categories.eventCategories', updatedCategories);
                    back();
                });
        }
        else {
            await addCategory({pid:pid,title:title})
                .then(()=>{
                    back();
                });
        }
    };

    return (
        <css.wrap>
            <css.back onClick={back}/>
            <Input name={'categoryTitle'} text={t('categoryTitle')} data-cy="enterCategoryName" />
            <Btn disabled={!title} onClick={onClick}>{t('continue')}</Btn>
        </css.wrap>
    );
}

export default AddCategory;
