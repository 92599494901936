import * as css from './Profile.css';
import {roleArr} from '../../../team/memberRoleBtn/MemberRoleBtn';
import {isMaster} from '../../../../functions/permission';
import {useSelector} from 'react-redux';
import useShow from '../../../../hooks/useShow';
import MasterPermission from '../../../components/rolePermission/MasterPermission';
import EditTextPopup from '../editTextPopup/EditTextPopup';
import {updateRealtimeText} from '../../../../redux/realtime/realtimeActions';
import {useTranslation} from 'react-i18next';

export function Profile({openProfile}) {
    const event = useSelector((s:any) => s.event);
    const user = useSelector((s:any) => s.user);
    const account = useSelector((s:any) => s.account);
    const realtimeText = useSelector((s:any) => s.realtime.event.realtimeText);
    const lang = user.lang || 'he';
    const {t} = useTranslation('realtime');
    const editTextShow = useShow();

    return (
        <>
            <css.profile data-cy="profile">
                <css.edit data-cy="editProfile" onClick={openProfile}/>
                <css.profileImage image={user.picture}/>
                <css.userName>{user.nameFirst} {user.nameLast}</css.userName>
                <css.userRole
                    icon={roleArr[((!isMaster() && event.userEventRole) || account.userAccountRole)]?.icon}>{t((!isMaster() && event.userEventRole) || account.userAccountRole)}</css.userRole>
                <css.profileText isMaster={isMaster()} onClick={() => {
                    !!isMaster() && editTextShow.open();
                }}>{realtimeText && realtimeText[lang]}</css.profileText>
            </css.profile>
            {!!editTextShow.isOpen && <MasterPermission>
                <EditTextPopup show={editTextShow} value={realtimeText[lang]} onSave={(text)=>{updateRealtimeText(text, lang).then(()=>{editTextShow.close();});}} title={t('textEditor')}/>
            </MasterPermission>}
        </>
    );
}