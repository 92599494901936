import {useEffect} from 'react';
import {getPromotionMessageStats} from '../../../../../redux/event/eventActions';
import * as css from './marketingPromotionInsights.css';
import DateInput from '../../../../../component/input/dateInput/DateInput';
import FormContext from '../../../../components/formContext/FormContext';
import * as Yup from 'yup';

export default function PickDateRange({close}) {
   
    return (
        <FormContext
            initialValues={{startDate: '', endDate: ''}}
            validationSchema={Yup.object({
                startDate: Yup.string(),
                endDate: Yup.string()
            })}
        >
            {(formProps) => <DateRangeForm close={close} values={formProps.values} />}
        </FormContext>
    );
}
function DateRangeForm({values, close}) {
    useEffect(() => {
        async function fetchData() {
            if(values.startDate && values.endDate) {
                await getPromotionMessageStats({
                    startDate: values.startDate,
                    endDate: values.endDate
                });
                close();
            }
        }
        fetchData();
    }, [values.startDate, values.endDate, close]);

    return (
        <css.freeDate>
            <DateInput 
                name="startDate"
                text=""
                isFixed={true}
                datePickerProps={{minDate:'null'}} className={undefined} descText={undefined} onChange={undefined} readOnly={undefined} icon={undefined}                />
      -
            <DateInput 
                name="endDate"
                text=""
                isFixed={true}
                datePickerProps={{minDate:'null'}} className={undefined} descText={undefined} onChange={undefined} readOnly={undefined} icon={undefined}                />
        </css.freeDate>
    );
}
