import TransactionsQueue from '../../../component/TransactionsQueue/TransactionsQueue';
import {useSelector} from 'react-redux';

function Queue() {
    const tickchakQueue=useSelector((state: any) => state.ui.tickchakQueue);

    if(!tickchakQueue) {
        return <></>;
    }

    return (
        <>
            <TransactionsQueue />
        </>
    );
}

export default Queue;
