import axios from 'axios';
import store from '../store';
import setGlobalState from '../redux/setGlobalState';
import getStoreByPath from '../functions/getStoreByPath';
import {bounceMessage} from '../layout/userMessages/UserMessage';

export const updateRequestStatus = (action, method, status, errorMessage = null) => {
    setGlobalState(`${action}XHR`, status);

    if(method === 'put') {
        setGlobalState('ui.putXHR', status);
    }
    if(errorMessage) {
        if(!store.getState().auth.isLoggedIn)
            setGlobalState('auth.message',errorMessage);
        else {
            bounceMessage(errorMessage,'warning',undefined,undefined,5);
        }
    }
};

let controllers = {};
export const cancelableRequest = async (action, method, ...args) => {
    // try {
    //     args.push({isCancelable: true});
    //     return await request(action, method, ...args);
    // } catch (e) {
    //     if(e.code === 'ERR_CANCELED') {
    //         console.log(`${action} request was canceled`);
    //         return false;
    //     }
    // }
// };

    const isCancelable = true;// args[args.length - 1]?.isCancelable || false;
    if(getStoreByPath(`${action}XHR`)==='LOADING'){
        if(isCancelable && controllers[action]) {
            controllers[action].abort();
        } else {
            await new Promise(setImmediate);
            if(getStoreByPath(`${action}XHR`) === 'LOADING') {
                return Promise.reject(`${action} is blocked`);
            }
        }
    }

    const controller = isCancelable ? new AbortController() : null;
    if(isCancelable) {
        controllers[action] = controller;
    }
    updateRequestStatus(action, method, 'LOADING');
    // await new Promise((res)=>setTimeout(res,300));
    console.assert( ['get', 'delete', 'head', 'options', 'post', 'put', 'patch'].includes(method),{method:method,action:action});
    try {
        if(method==='get')
            args[1]={params:args[1]};
        if(isCancelable) {
            if(!args[1]) {
                args[1] = {};
            }
            args[1].signal = controller.signal;
        }
        const response = await axios[method](...args);
        setImmediate(()=>{
            updateRequestStatus(action, method, 'SUCCESS');
        });
        return response.data;
    } catch (e) {
        if(isCancelable && e.code === 'ERR_CANCELED') {
            // throw e;
            return;
        }
        const {errorMessage} = e.response && e.response.data ? e.response.data : '';
        updateRequestStatus(action, method, 'FAILED', errorMessage);

        if(e?.response?.data?.masterUserNeedPassword && e?.response?.data?.errorMessage) {
            alert(e.response.data.errorMessage);
        }
        if(args[args.length-1]?.handleError){
            throw e;
        } 
    } finally {
        if(isCancelable) {
            delete controllers[action];
        }
    }
};

const request = async (action, method, ...args) => {
    if(getStoreByPath(`${action}XHR`)==='LOADING'){
        await new Promise(setImmediate);
        if(getStoreByPath(`${action}XHR`)==='LOADING')
            return Promise.reject(`${action} is blocked`);
    }
    updateRequestStatus(action, method, 'LOADING');
    
    console.assert(['get', 'delete', 'head', 'options', 'post', 'put', 'patch'].includes(method), {method:method, action:action});
    
    try {
        // Handle different HTTP methods appropriately
        if(method === 'get') {
            args[1] = {params: args[1]};
        } else if(method === 'delete' && args[1]) {
            // For DELETE requests, move the data to the config object
            args[1] = {data: args[1]};
        }
        
        const response = await axios[method](...args);
        setImmediate(() => {
            updateRequestStatus(action, method, 'SUCCESS');
        });
        return response.data;
    } catch (e) {
        const {errorMessage} = e.response && e.response.data ? e.response.data : '';
        updateRequestStatus(action, method, 'FAILED', errorMessage);

        if(e?.response?.data?.masterUserNeedPassword && e?.response?.data?.errorMessage){
            alert(e.response.data.errorMessage);
        }
        if(args[args.length-1]?.handleError){
            throw e;
        }
        return Promise.reject(`${action} is failed`);
    }
};

export default request;

export const requestWithXHRReset = async (action, method, ...args) => {
    try {
        const res = await request(action, method, ...args);
        return res;
    } finally {        
        const timeout = setTimeout(()=>{
            setGlobalState(`${action}XHR`, null);
            clearTimeout(timeout);
        }, 2000); // reset XHR after 2 seconds
    }
};

