import styled from 'styled-components';
import realtimeFonts from '../../../../fonts';
import {section} from '../../EventDay.css';
import {dFlexColumn, alignCenter} from '../../../../../../globalVariables/GlobalFlex';
import {mobileMedia} from '../../../../../../globalVariables/GlobalVariables';

export const container = styled.div`
    grid-area: terminals;
    ${section}
    ${dFlexColumn}
    height: 100%;
    padding: 15px;
    ${mobileMedia}{
        height: max-content;
    }
`;

export const title = styled.h3`
    margin: 0 0 15px 0;
    ${realtimeFonts.textBold}
`;

export const list = styled.div`
    ${dFlexColumn};
    ${alignCenter}
    gap: 10px;
    flex: 1;
`;
