import React from 'react';
import * as css from './BtnPlus.css';
import Tooltip from '../../tooltip/Tooltip';
import ConditionalWrapper from '../../conditionalWrapper/ConditionalWrapper';

function BtnPlus({onClick, text, hasText,white,state,bsize, dataCyName, disabled=false}) {

    return (

        <ConditionalWrapper
            condition={!hasText && text}
            wrapper={children =>  <Tooltip text={text}>{children}</Tooltip>}>
            <css.btnPlus  className={`${state?' open ':' close '} ${disabled && 'disabled'}`} onClick={onClick} bsize={bsize} $hasText={hasText} $white={white} data-cy={`${dataCyName ? dataCyName + 'PlusBtn' : 'plusBtn'}`}>
                {hasText&&<css.btnText >{text}</css.btnText>}
                <css.plusIcon bsize={bsize} className={'btnIcon'}/>
            </css.btnPlus>
        </ConditionalWrapper>

    );
}

export default BtnPlus;
