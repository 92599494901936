import styled from 'styled-components';
import {dFlex, mobileMedia} from '../../../../../../../../globalVariables/GlobalVariables';
import realtimeFonts from '../../../../../../fonts';

export const wrap = styled.div`
    ${p => p.theme.$compact && `
        ${dFlex};
        gap: 10px;
        width: 100%;
    `}
`;

export const ticketRow = styled.div`
    ${p => !p.theme.$compact && `
        ${dFlex};
        gap: 10px;
    `}
`;

export const dot = styled.div<{ $color: string }>`
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: relative;
    inset-block-start: 8px;
    background-color: ${props => props.$color};
    ${mobileMedia}{
        width: 8px;
        height: 8px;
        inset-block-start: 5px;
    }
`;

export const validationText = styled.div`
    ${realtimeFonts.windowTitle}
    font-weight: 400;
`;

export const validationTextBold = styled.div`
    ${realtimeFonts.windowTitle}
    font-weight: 700;
`;

export const precentage = styled(validationTextBold)`
    position: relative;
    padding-inline-start: ${p => p.theme.$compact ? '0px' : '24px'};
    ${mobileMedia}{
        padding-inline-start: ${p => p.theme.$compact ? '0px' : '18px'};
    }
`;
