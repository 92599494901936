import {useState,useEffect,useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import CircleClose from '../../../../component/circleClose/CircleClose';
import useShow from '../../../../hooks/useShow';
import {bounceMessage} from '../../../../layout/userMessages/UserMessage';
import FormContext from '../../../components/formContext/FormContext';
import Navigation from '../navigation/Navigation';
import Step from '../Step/Step';
import * as css from './NewEventForm.css';
import {createContext} from 'react';
import useEffectOnUpdate from '../../../../hooks/useEffectOnUpdate';
import useTimeout from '../../../../hooks/useTimeout';
import useMobile from '../../../../hooks/useMobile';
import setGlobalState, {resetGlobalState} from '../../../../redux/setGlobalState';
import {deleteEvent} from '../../../../redux/suggestions/suggestionsActions';
import store from '../../../../store';
import {useSelector} from 'react-redux';
import PageWrapper from '../../../components/pageWrapper/PageWrapper';
import ConditionalWrapper from '../../../../component/conditionalWrapper/ConditionalWrapper';
import {hotjar} from 'react-hotjar';
import {HOTJAR_ID, HOTJAR_SV} from '../../../../config/config';
import SaveEventBtn from '../saveEventBtn/SaveEventBtn';
import ArtistUserMessage from '../../../../component/artistUserMessages/ArtistUserMessage';
import {NAVIGATION_HEIGHT, NAVIGATION_HEIGHT_MOBILE} from '../navigation/Navigation.css';
import {getAllLanguages} from '../../../../redux/languages/languagesActions';
import CustomScrollbar from '../../../../component/customScrollbar/CustomScrollbar';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';
import {duplicateInitialValues,newEventSteps} from '../../globalSteps';
import {eventFinishDuplication} from '../../../../redux/event/eventActions';
import {openDesignPop} from '../../../../layout/Layout';

export const newEventContext = createContext();

function NewEventForm({steps,initialValues, validationSchema}) {
    const ref=useRef();
    const containerRef = useRef();
    const {t} = useTranslation('newEvent');
    const event=useSelector(s=>s.event);
    const isReady=event.ready===5;
    const [currentStep,setCurrentStep]=useState(isReady?-1:0);
    const failedState = useState({});
    const history = useHistory();
    const show = useShow(500);
    const timer = useTimeout();
    const isMobile=useMobile();
    const {duplicateSourceEid} = useSelector(s=>s.ui.event);

    steps=  [...steps,...(duplicateSourceEid? [newEventSteps.duplicate(t)]: [])];
    const contextValue={currentStep,setCurrentStep,steps,failedState,isReady};

    const onClose=()=>{
        bounceMessage(t('deleteEventMessage'),'warning',
            {text: t('return'), onClick:()=>{}},{text: t('deletePage'), onClick:()=> {
                deleteEvent(store.getState().event.eid).then();
                resetGlobalState('event');
                history.push(`/p/${event.pid}/home`);
            }});
    };

    useEffect(show.open,[]);

    useEffect(()=>{
        formRef.current?.setValues({...formRef.current.values,
            locale:{
                timeZone:event.timeZone,
                lang:event.lang
            }});
    },[event.timeZone,event.lang]);

    useEffectOnUpdate(()=>{
        timer.current=setTimeout(() => {
            const top =(!isReady&&(isMobile?NAVIGATION_HEIGHT_MOBILE:NAVIGATION_HEIGHT))+20;
            containerRef.current?.scrollTo({top: ref.current?.offsetTop - top , behavior: 'smooth'});
        }, 600);
    },[currentStep]);

    const getAllLanguagesXHR=useSelector(s=>s.languages.getAllLanguagesXHR);

    useEffect(()=>{
        if(!['SUCCESS','UPDATED'].includes(getAllLanguagesXHR))
            getAllLanguages().then();
        hotjar.initialize(parseInt(HOTJAR_ID), parseInt(HOTJAR_SV));
        setGlobalState('ui.show.isOpenTickchakLoader',false);
    },[]);

    let prevIsLastIndex = steps?.findIndex(s => s.isLast);
    if(prevIsLastIndex!==-1)
        delete steps[prevIsLastIndex].isLast;
    steps[steps.length-1].isLast=true;

    const formRef=useRef(null);

    const cssStyle = `
        inset-inline-end: 20px;
        ${mobileMedia}{
            inset-inline-end: -20px;
        }
    `;
    const duplicateSubmit = (form) => {
        const values = form.getFieldMeta('duplicate').value;
        eventFinishDuplication(event.eid, duplicateSourceEid, values).then(() => {
            history.push(`/e/${event.eid}/${event.type === 'course' ? 'courses' : 'tickets'}`);
            openDesignPop();
        });
    };
    const submitBtnProps = duplicateSourceEid ? {
        onSubmit: duplicateSubmit,
        children: t('newEvent:saveDuplicate'),
    } : {};
    return (
        <ConditionalWrapper condition={isReady} wrapper={(children)=> <PageWrapper operations={{}}>{children}</PageWrapper>}>
            <newEventContext.Provider value={contextValue}>
                <FormContext
                    innerRef={formRef}
                    initialValues={{...initialValues, ...(duplicateSourceEid ? {duplicate: duplicateInitialValues(event?.type)} : {})}}
                    validationSchema={validationSchema}
                >{({isValid})=>
                        <css.wrapper className={`${show.showClass} ${isReady?'ready':''}`}>
                            {!isReady&&
                    <>
                        <CircleClose isDark={true} close={onClose} closeType = 'newEventClose' />
                        <Navigation/>
                    </>}
                            {!!isReady && <CustomScrollbar contentRef={containerRef} cssStyle={cssStyle}/>}
                            <css.steps ref={containerRef} className={`${isReady?'ready':''} ${isValid && ' valid '}`}>
                                {!isReady&&<css.text>
                                    {t('newEventText',{type: t(event.type)})}
                                    <css.description> {t('newEventDesc')}</css.description>
                                </css.text>}
                                {steps.map(({name,RightComp,FullComp,compProps={}},index)=>{
                                    const Comp=RightComp?RightComp:FullComp;
                                    return <Step
                                        key={name}
                                        index={index}
                                        forwardRef={currentStep===index?ref:null}
                                    >
                                        <Comp {...compProps}/>
                                    </Step>;}
                                )}
                            </css.steps>
                            {(event.ready<5) && <SaveEventBtn validForm={isValid && 'valid'} containerRef={containerRef} {...submitBtnProps}/>}
                            <ArtistUserMessage/>
                        </css.wrapper>}
                </FormContext>
            </newEventContext.Provider>
        </ConditionalWrapper>
    );
}

export default NewEventForm;
