import Cookies from 'js-cookie';
import setGlobalState from '../redux/setGlobalState';

const tickchakQueue = (key, value) => {
    const tickchakQueue = Cookies.get('tickchakQueue');
    let dataObject;
    if(tickchakQueue) {
        dataObject = JSON.parse(tickchakQueue);
        dataObject[key] = value;
    }
    else {
        dataObject={[key]: value};
    }
    Cookies.set('tickchakQueue', JSON.stringify(dataObject));
    setGlobalState('ui.tickchakQueue', dataObject);

};
export default tickchakQueue;
