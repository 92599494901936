import {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import setGlobalState from '../redux/setGlobalState';
import {cleanupSocketConnection, emitEventsWithCommonData, initializeSocketConnection, setupCustomOnMethod, setupEventHandlers} from '../functions/socketFunctions';
import {iframeClick} from '../layout/Layout';

const useSocket = () => {
    const socketRef = useRef(null);
    const eid = useSelector(state => state.event.eid);

    useEffect(() => {
        if(!socketRef.current) {
            socketRef.current = initializeSocketConnection();
            setupCustomOnMethod(socketRef.current);

            const keys = ['realtimeCashConnections', 'realtimeVisitorsConnections', 'eventDayConnections'];
            emitEventsWithCommonData(socketRef.current, keys, eid);

            socketRef.current.on('connect', () => {
                setGlobalState('auth.socket', {clientId: socketRef.current.id, redisKeys: keys.map(key => `${key}_${eid}`)});
            });
        }

        setupEventHandlers(socketRef.current, eid);
        return cleanupSocketConnection(socketRef.current);
    }, [eid]);

    useEffect(() => {
        const handleUserChange = (event) => {
            if(event.key === null) {
                cleanupSocketConnection(socketRef.current)();
                iframeClick('logout');
            }
        };

        window.addEventListener('storage', handleUserChange);
        return () => window.removeEventListener('storage', handleUserChange);
    }, []);

    return socketRef.current;
};

export default useSocket;