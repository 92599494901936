import * as css from './TerminalList.css';
import {useTranslation} from 'react-i18next';
import {Terminal} from './Terminal';
import {useSelector} from 'react-redux';

export function TerminalList() {
    const {t} = useTranslation('realtime');
    const terminals = useSelector((s:any) => s.realtime?.eventDay?.terminals || []);

    return (
        <css.container className='free-color'>
            <css.title>{t('terminals')}</css.title>
            <css.list>
                {terminals.map((terminal, index) => (
                    <Terminal key={index} {...terminal} />
                ))}
            </css.list>
        </css.container>
    );
}
