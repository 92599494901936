export const dFlex = `
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
`;

export const dInlineFlex = `
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
`;

export const flexRow = `
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
`;

export const flexRowReverse = `
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
`;

export const flexColumn = `
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
`;

export const flexColumnReverse = `
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
`;

export const justifyStart = `
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
`;

export const justifyEnd = `
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
`;

export const justifyCenter = `
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
`;

export const justifyBetween = `
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
`;

export const justifyAround = `
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
`;

export const justifyEvenly = `
    -webkit-justify-content: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
`;

export const alignStart = `
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
`;

export const alignEnd = `
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
`;

export const alignCenter = `
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
`;

export const alignStretch = `
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
`;

export const alignBaseline = `
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
`;

export const flexNoWrap = `
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
`;

export const flexWrap = `
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
`;

export const flexWrapReverse = `
    -webkit-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
`;

export const alignContentStart = `
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
`;

export const alignContentEnd = `
    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
`;

export const alignContentCenter = `
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
`;

export const alignContentBetween = `
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
`;

export const alignContentAround = `
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
`;

export const alignContentStretch = `
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
`;

export const dFlexCenter = `
    ${dFlex}
    ${alignCenter}
    ${justifyCenter}
`;
export const dFlexStart= `
    ${dFlex}
    ${alignCenter}
    ${justifyStart}
`;
export const dFlexEnd= `
    ${dFlex}
    ${alignCenter}
    ${justifyEnd}
`;
export const dFlexBetween = `
    ${dFlex}
    ${alignCenter}
    ${justifyBetween}
`;
export const dFlexStartBetween = `
    ${dFlex}
    ${alignStart}
    ${justifyBetween}
`;
export const dFlexColumn = `
    ${dFlex}
    ${flexColumn}
`;

export const dFlexColumnCenter = `
    ${dFlex}
    ${flexColumn}
    ${alignCenter}
    ${justifyCenter}
`;
export const dFlexColumnAlineEnd = `
    ${dFlex}
    ${flexColumn}
    ${alignEnd}
`;
