import * as css from './ValidationStats.css';
import {useTranslation} from 'react-i18next';
import {LineChart, Line, XAxis, ResponsiveContainer, Tooltip} from 'recharts';
import {BLACK, DEFAULT_COLOR} from '../../../../../../../globalVariables/GlobalVariables';
import {formatNumber} from '../../../../../../../library/numberFunction';
import React, {useContext} from 'react';
import {CustomTooltipProps} from '../../../../cashGraph/CashGraph';
import NoEntriesImage from '../../icons/NoEntriesImage';
import useMobile from '../../../../../../../hooks/useMobile';
import {getDateFormat} from '../../../../../../../library/dates';
import {useSelector} from 'react-redux';
import {CompactEventDayContext} from '../../CloseEventDay/CloseEventDay';

interface ValidationStatsProps {
    transactions: number;
    lastHourValidations: number;
    hourlyEntries: any[];
    isEventPassed?: boolean;
    eventDate?: number;
}

const getDayName = (date: number, timeZone) => {
    return getDateFormat(date, 'dddd', timeZone).toLowerCase();
};

export function ValidationStats({
    transactions, 
    lastHourValidations, 
    hourlyEntries,
    isEventPassed,
    eventDate
}: ValidationStatsProps) {
    const {t} = useTranslation('realtime');
    const isMobile = useMobile();
    const timeZone = useSelector((s:any)=>s.event.timeZone);
    const isCompact = useContext(CompactEventDayContext)?.isCompact || false;
    const CustomTooltip: React.FC<CustomTooltipProps> = ({active, payload}) => {
        if(active && payload && payload.length) {
            const isLastElement = payload[0]?.payload?.hour === hourlyEntries[hourlyEntries.length - 1]?.hour && hourlyEntries.length !==1;
            return (
                <css.customTooltip $isLast={isLastElement}>
                    <css.tooltipText>
                        {formatNumber(payload[0]?.payload?.validations)}
                    </css.tooltipText>
                </css.customTooltip>
            );
        }
        return null;
    };

    return (
        <css.statsSection>
            {transactions ? <><css.validationsInfo>
                <css.validationsRow>
                    <css.totalValidations>{Number.isFinite(transactions) ? formatNumber(transactions) : 0}</css.totalValidations> 
                    <css.validationText>{t('enteredTransactions')}</css.validationText>         
                </css.validationsRow>
                {!isCompact && <css.validationsRow>
                    <css.totalValidations>{!!lastHourValidations && formatNumber(lastHourValidations)}</css.totalValidations>
                    <css.validationText>{isEventPassed ? t('realtimePassedText', {
                        date: getDateFormat(eventDate, 'YYYY-MM-DD', timeZone),
                        dayName: t(getDayName(eventDate, timeZone))
                    }) : !lastHourValidations ? t('noValidations') : t('lastHourValidation')}</css.validationText>
                </css.validationsRow>}
            </css.validationsInfo>
            {!isCompact && <css.validationGraph>
                <ResponsiveContainer>
                    <LineChart data={hourlyEntries} margin={{
                        top: 10,
                        right: 20,
                        left: 20,
                        bottom: 5,
                    }}>
                        <Tooltip 
                            content={<CustomTooltip />}
                            cursor={{stroke: 'none'}}
                            position={{y: 70}}
                            offset={0}
                        />
                        <XAxis dataKey="hour" />
                        <Line 
                            type="monotone" 
                            dataKey="validations" 
                            stroke={BLACK}
                            dot={false}
                            activeDot={{
                                stroke: DEFAULT_COLOR,
                                strokeWidth: 2,
                                fill: DEFAULT_COLOR,
                                r: 4
                            }}
                            strokeWidth={2}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </css.validationGraph>}</> : 
                <css.noEntries>
                    <css.text>{t('noEntriesText')}</css.text>
                    <NoEntriesImage width={isCompact ? '92' : isMobile ? '132': '169'} height={isCompact ? '62' : isMobile ? '89' : '114'}/>
                </css.noEntries>
            }
        </css.statsSection>
    );
}
