import styled from 'styled-components';

export const oCircle = styled.div`
    transition: all 100ms;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${props => props.$circleColor?props.circleColor:'#fff'};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
    margin-top: -6px;
    &.currentStage{
        transform: scale(1.7);
        border: 1px solid ${props => props.$borderColor?props.borderColor:'#43BAE7'};
    }
`;

export const iCircle = styled.div`
    transition: all 100ms;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${props => props.$circleColor?props.circleColor:'#43BAE7'};
`;

export const stageText = styled.div`
    text-align: center;
    color: rgb(93, 93, 93);
    font-size: 14px;
    width: 65px;
    font-weight: 500;
    line-height: 13px;
    &.currentStage{
        font-weight: bold;
    }
    &.disabled{
        opacity: 0.6;
    }
`;

export const wrapStae = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 12px;
    cursor: pointer;
    &:not(.disabled){
        &:hover{
            .oCircle{
            &:not(.currentStage){ 
                transform: scale(1.7);
                border: 1px solid ${props => props.$borderColor?props.$borderColor:'#43BAE7'};
            .iCircle{
                //width: 6px;
                //height: 6px;
            }
        }}
        }
    }
`;
