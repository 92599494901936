const formatWithSuffix = (num, divisor, suffix) => {
    const val = (num / divisor).toFixed(1);
    return (val.endsWith('.0') ? val.slice(0, -2) : val) + suffix;
};

export const formatSuffix = (num) => {
    if(Math.abs(num) >= 1000000000000) return formatWithSuffix(num, 1000000000000, 'T');
    if(Math.abs(num) >= 1000000000) return formatWithSuffix(num, 1000000000, 'B');
    if(Math.abs(num) >= 1000000) return formatWithSuffix(num, 1000000, 'M');
    if(Math.abs(num) >= 10000) return formatWithSuffix(num, 1000, 'k');
    return num.toString();
};

export const formatDecimal = (input, kFormat = false) => {
    const num = parseFloat(`${input}`.replaceAll(',',''));
    if(isNaN(num)) return input;

    if(kFormat && Math.abs(num) >= 10000) {
        return formatSuffix(num);
    }
    return num.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2});
};

export const formatNumber = (input, kFormat = false) => {
    let num = input;
    if(!input?.toString().includes('e')) // large number
        num = parseNumber(input);
    return (kFormat && Math.abs(num) >= 10000) ? formatSuffix(num) : num.toLocaleString();
};

export const parseNumber = (string) => {
    return string ? parseInt(string.toString().replace(/[^0-9.-]/g, '')) : 0;
};
