import styled from 'styled-components';
import {BGImageCenter, ROYAL_BLUE, mobileMedia, scrollbar} from '../../../globalVariables/GlobalVariables';
import {meta} from '../../../assets/symbols/symbols/Symbols.css';
import {container as containerMode} from '../../../component/modeBtn/ModeBtn.css';
import {wrapper} from '../../../component/grayWrapper/GrayWrapper.css';
import * as inputCss from '../../../component/input/basicInput/BasicInput.css';

export const background = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  padding-inline-end: 100px;
  background: transparent linear-gradient(270deg, #4880F6 0%, #3C67DC 100%) 0% 0% no-repeat padding-box;
`;

export const title = styled.div`
  color: #FFFFFF;
  font-size: 19px;
 padding-top: 10px
`;

export const mobileIcon = styled.div`
    box-shadow: 0px 5px 15px #00000029;
    border-radius:50%;
    border: 2px solid #FFFFFF;
    background: transparent linear-gradient(180deg, #3F6CE1 0%, #467CF2 100%) 0% 0% no-repeat padding-box;
    width: 74px;
    height: 74px;
    position: absolute;
    top:-37px;
    inset-inline-start: calc(50% - 37px);
    &::before{
        content:"";
        ${BGImageCenter(meta,'40px')}
        width:100%;
        height:100%;
        position: absolute;
    }
    &::after{
        content:'${p=>p.title}';
        position: absolute;
        bottom: -25px;
        width: max-content;
        font-weight:500;
        inset-inline-end: 3px;
    }
`;

export const icon = styled.div`
    width: 43px ;
    height: 43px;
    ${BGImageCenter(meta,'contain')}
    margin-inline-start: 10px;
`;

export const content = styled.div`
    ${scrollbar};
    height: calc(100% - 130px);
    padding: 50px 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${mobileMedia}{
        padding: 20px;
        height: calc(100% - 60px);
    }
    ${wrapper}{
        margin-inline-start: 30px;
    }
`;

export const bottom = styled.div`
    padding-inline-end: 55px;
    display: flex;
    height: 70px;
    border-top: 1px solid #EEE;
    align-items: center;
    justify-content: flex-end;
`;

export const text = styled.div`
    font-size: 10px;
    letter-spacing: 2px;
`;

export const children = styled.div`
    direction: ltr;
    display: flex;
    ${inputCss.container}{
        width: 200px;
        padding: 0;
        &::before{
            bottom: -17px;     
        }
    }
    ${inputCss.icon}{
        display: none;
    }
    ${inputCss.wrapInput}{
        input{
            width: 100%;
        }
    }
    ${mobileMedia}{
        flex-direction: column;
    }
`;

export const message = styled.div`
    font-size: 10px;
    margin-inline-start: 30px;
    transition:all 400ms;
`;

export const wrap = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 0 10px;
   ${containerMode}{
    &.small{
        .modeBtnText{
            width: max-content;
            font-size: 11px;
            padding-inline-end: 5px;
        }
    }
   }
`;

export const wrapAll = styled.div`
    display: flex;
    justify-content: space-between;
    >a{
        color: ${ROYAL_BLUE};
        border-bottom: 1px solid ${ROYAL_BLUE};
        width: max-content;
        margin-inline-start: 30px;
    }
`;