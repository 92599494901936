import Stage from '../components/stage/Stage';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import setGlobalState from '../../../redux/setGlobalState';
import LoginForm from './loginForm/LoginForm';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import {Case, Switch} from 'react-if';
import Code from './code/Code';
import {loginByEmail, loginUser, resetPassword} from '../../../redux/user/userActions';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {Form, Formik} from 'formik';
import useErrorMessages from '../../../hooks/useErrorMessages';
import SupportComponent from '../../../layout/supportComponent/SupportComponent';
import useTimeout from '../../../hooks/useTimeout';
import useEffectOnUpdate from '../../../hooks/useEffectOnUpdate';
import {getDefaultPath} from '../../../Routes';
import {isIframe, sendPostMessage} from '../signUp/SignUp';
import VerifyUserPop from '../../../layout/topMenu/verifyUserPop/VerifyUserPop';
import useShow from '../../../hooks/useShow';
import ForgotPassword from './forgotPassword/ForgotPassword';
import {useSearchParams} from '../../../hooks/useSearchParams';
import {refreshTokenApplicationAndRedirect} from '../../../functions/refreshTokenApplication';
import yupMethods from '../../../functions/validationsYup';
// import OtherWay from './otherWay/OtherWay';  //note:If you delete it you must remove this files

function Login({pageType = null}) {
    const {loginByEmailXHR,defaultPid,defaultEid}=useSelector(state=>state.user);
    const {isPrevPath,noPackageOrEvent,isInactiveUser}=useSelector(state=>state.ui.login);
    const {isLoggedIn, isApplication, applicationExp}=useSelector(state=>state.auth);
    const history=useHistory();
    const [currentPage,setCurrentPage]=useState(pageType || 'signIn');
    const [isPassword,setIsPassword]=useState(false);
    const {t} = useTranslation('login');
    const {t: globalT} = useTranslation('global');
    const timer=useTimeout();
    const showVerifyPop = useShow();
    const {token} = useSearchParams();
    useErrorMessages();
    useEffect(()=>{
        const currentLang = JSON.parse(localStorage.getItem('user.langloc'))||'he_il';
        setGlobalState('user.langloc',currentLang);
        setGlobalState('user.lang',currentLang.split('_')[0] || 'he');
    },[]);
    useEffect(()=>{
        const now = new Date();
        if(isApplication && applicationExp && now < new Date(applicationExp * 1000)){
            refreshTokenApplicationAndRedirect().then();
            return;
        }
        if(!isLoggedIn)
            return;
        if(!defaultEid&&!defaultPid){
            return;
        }
        
        setGlobalState('user.loginUserXHR',null);
        if(isPrevPath)
            history.goBack();
        else
            history.push(getDefaultPath(defaultPid,defaultEid));
        if(isIframe)
            sendPostMessage('signUp');
    },[defaultPid,isLoggedIn,defaultEid]);

    useEffect(()=>{
        if(noPackageOrEvent){
            history.push('signUp');
            setGlobalState('auth.noPackageOrEvent',null);}
    },[noPackageOrEvent]);

    useEffectOnUpdate(()=>{
        isInactiveUser && showVerifyPop.open();
    },[isInactiveUser]);

    useEffectOnUpdate(()=>{
        if(loginByEmailXHR==='SUCCESS'){
            setCurrentPage('code');
            setGlobalState('user.loginByEmailXHR',null);
        }
    },[loginByEmailXHR]);

    const resetLoginXHR=(action)=> {
        timer.current=setTimeout(() => {
            setGlobalState(`user.${action}XHR`, null);
        }, 2000);
    };

    const onSubmit = (values) => {
        const {email,sendToEmail,password, password2}=values;
        if(currentPage==='resetPassword'){
            resetPassword(email, password, password2, token);
        } else if(isPassword && !(values.sendToEmail)){
            loginUser({email,password},true).then((res)=>{
                if(res?.isCodeSend)
                    setCurrentPage('code');
                resetLoginXHR('loginUser');
            });
        }
        else
            loginByEmail(email,sendToEmail).then(()=>resetLoginXHR('loginByEmail'));
    };

    yupMethods();
    const validationPassword=Yup.string().matches(/^[^\u0590-\u05FF]*$/, t('hebrewChars'));

    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required(),
        password: isPassword ? validationPassword.required().minLength(6, globalT('errorMinLength', {min: 6})) : validationPassword,
        password2: validationPassword.oneOf([Yup.ref('password'), null], t('passwordsNotEqual'))
    });
    const existingUserEmail=useSelector(s=>s.ui.login.existingUserEmail);
    return (
        <>
            <title>{'Tickchak | login - התחברות'}</title>
            {showVerifyPop.isOpen && <VerifyUserPop show={showVerifyPop}/>}
            <Formik
                initialValues={{email:existingUserEmail||'' ,password:'',sendToEmail:false}}
                validationSchema={validationSchema}
                onSubmit={onSubmit}>
                <Form  >
                    <Stage >
                        <SupportComponent />
                        <Switch >
                            <Case condition={currentPage==='signIn'}>
                                <LoginForm setCurrentPage={setCurrentPage} isPassword={isPassword} setIsPassword={setIsPassword}/>
                            </Case>
                            <Case condition={currentPage==='code'}>
                                <Code setCurrentPage={setCurrentPage}/>
                            </Case>
                            <Case condition={currentPage==='resetPassword'}>
                                <ForgotPassword setCurrentPage={setCurrentPage} />
                            </Case>
                            {/* <Case condition={'otherWay'===currentPage}>
                            note:If you delete it you must remove this files
                                <OtherWay setCurrentPage={setCurrentPage} />
                            </Case> */}
                        </Switch>

                    </Stage>
                </Form>
            </Formik>
        </>
    );
}

export default Login;

