import {useSelector} from 'react-redux';
import * as css from './supportVideos.css';
import useQuery from '../../../hooks/useQuery';
import {useParams, useLocation} from 'react-router-dom';
import {useEffect, useRef} from 'react';
import Headphones from '../../../assets/icons/headphones';
import Ex from '../../../assets/icons/ex';
import SearchVideos from '../popHeader/searchVideos';
import {useTranslation} from 'react-i18next';
import {getSupportVideos} from '../../../redux/management/managementActions';
import MasterPermission from '../../../pages/components/rolePermission/MasterPermission';
import VideoFormPop from '../videoForm/videoFormPop';
import useOnClickOutside from '../../../hooks/UseOnClickOutside';
import VideoCard from './videoCard';
import useShow from '../../../hooks/useShow';

export const getPageTranslation = (t, pageTitle)=>pageTitle.split('_').map(item=>t(item)).join(' > ');

function SupportVideos({isOpen, openWidget, close}) {
    const videos = useSelector((state: any) => state.management.videos);
    const {t} = useTranslation('layout');
    const {eid, pid, page, subPage} = useParams();
    const location = useLocation();
    const query = useQuery();
    const packagePage = query.get('packagePage');
    const routeType = eid ? 'event' : pid ? 'package' : '';
    const showForm = useShow();
    const containerRef = useRef(null);
    const pathName= location.pathname?.replaceAll('/','').toLowerCase(); 
    const pageType = packagePage? `${routeType}_${packagePage}` : page? `${routeType}_${page}${subPage?'_':''}${subPage||''}`: pathName || 'unknown_page';
    const currentVideo=useRef(null);

    useEffect(()=>{
        handleSearch('');
    }, [eid, pid]);
    
    const closePopup = () => {
        showForm.close();
        currentVideo.current = null;
    };
    
    const handleSearch = newValue=>{
        const id = routeType==='package'?pid:eid;
        if(id||pageType==='login'||pageType==='signup')
            getSupportVideos(pageType, routeType, id, newValue).then();
    };
    
    const editVideo = (videoId)=> {
        currentVideo.current = videoId;
        showForm.open();
    };
    useOnClickOutside(containerRef, close);

    const {currentPageVideos, otherVideos} = videos.reduce((result, video) => {
        result[video.page === pageType ? 'currentPageVideos' : 'otherVideos'].push(video);
        return result;
    }, {currentPageVideos: [], otherVideos: []});
    return (
        <css.container ref={containerRef} className={isOpen && 'open'}>
            <css.close onClick={close}> <Ex width={12} height={12} color='#000000' /></css.close> 
            <css.header>
                <SearchVideos getSearch={handleSearch} title={getPageTranslation(t, pageType)} />
            </css.header> 
            <css.content>
                <css.videos>
                    {currentPageVideos.map((video)=><VideoCard openWidget={openWidget} editVideo={editVideo} key={video.id} video={video} />)}
                    {!!otherVideos.length && t('moreResults')}
                    {otherVideos.map((video)=><VideoCard openWidget={openWidget} editVideo={editVideo} key={video.id} video={video} />)}
                </css.videos>
            </css.content>
            <MasterPermission>
                <css.addVideo onClick={showForm.open}>
                    <css.addIcon/>
                    <span>{t('newQuestion')}</span>
                </css.addVideo>
                {showForm.isOpen && <VideoFormPop videoId={currentVideo.current} show={{...showForm, close:closePopup}} currentPage={pageType} />}
            </MasterPermission>
            <css.openWidgetButton onClick={openWidget}>
                <Headphones />
                {t('contactSupport')}
            </css.openWidgetButton>
        </css.container>
    );
}

export default SupportVideos;