import {useMemo, useRef} from 'react';
import * as css from './videoForm.css';
import {useTranslation} from 'react-i18next';
import VideoForm from './videoForm';
import * as Yup from 'yup';
import FormContext from '../../../pages/components/formContext/FormContext';
import {TSupportVideos} from '../../../redux/management/management.type';
import {createVideo, deleteVideo, editVideo} from '../../../redux/management/managementActions';
import {useSelector} from 'react-redux';
import isYouTubeUrl from '../../../functions/isYouTubeUrl ';
import FormikModeBtn from '../../../component/formikModeBtn/FormikModeBtn';
import useTimeout from '../../../hooks/useTimeout';
import PopUpWithHeader from '../../../component/newPopup/popUpWithHeader/PopUpWithHeader';

function VideoFormPop({show, currentPage, videoId}: {show: any, currentPage:string, videoId:number | null}) {

    const video = useSelector((state: any) => state.management.videos?.find(v => v.id === videoId) || {});
    const timer: any = useTimeout();
    const {t} = useTranslation('layout');
    const formRef = useRef<{ handleSubmit: () => void }>(null);
    
    const onActionSuccess=()=>{
        timer.current=setTimeout(() => {
            show.close();
        },500);
    };
    const removeVideo =async ()=>{
        if(video.id) {
            await deleteVideo(video.id);
            onActionSuccess();
        }
    };

    const initialValues: TSupportVideos = useMemo(()=>({
        title: '',
        description: '',
        id: 0,
        page: currentPage,
        videoLink: '',
        permission: [],
        createByGlobalUserId: 0,
        ...video,
        active: video?!video.hide:1,
    }),[video]);

    const validation = Yup.object().shape({
        title: Yup.string().max(90),
        description: Yup.string().max(90),
        page: Yup.string().required(),
        videoLink: Yup.string().max(90).test('is-youtube-url', t('youtubeLinkValidation'), isYouTubeUrl),
        permission: Yup.array().required()
    });

    const handleSubmit= async (_changedValues: any, values: any) =>{
        const hide = Number(!{..._changedValues, ...values}.hide);
        if(values.id) {
            await editVideo(values.id, {..._changedValues, ...values, hide});
        } else {
            await createVideo({..._changedValues, ...values, hide});
        }
        onActionSuccess();
    };

    const popTitle = <css.header>
        {t('supportNewQuestion')}
        <FormikModeBtn name='active' className='small' forwardRef={undefined} />
    </css.header>;
    return (
        
        <FormContext
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
        >
            <css.popUp>
                <PopUpWithHeader show={show} title={''} image={undefined} dataCyName="videoFormPop" topChildren={popTitle}  >
                    <VideoForm removeVideo={removeVideo} videoId={videoId} formRef={formRef} />
                </PopUpWithHeader>
            </css.popUp>
        </FormContext>
    );
}

export default VideoFormPop;