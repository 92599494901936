import styled from 'styled-components';
import {
    DEFAULT_COLOR,
    BGImageCenter,
    getStaticImage,
    mobileMedia,
    scrollbar,
    dFlexCenter,
    maskImage,
    RED
} from '../../../globalVariables/GlobalVariables';

import {document} from '../../../assets/symbols/symbols/Symbols.css' ;
import {btn} from '../../../component/button/btn/Btn.css';

export const popContain=styled.div`
    width: 100%;
    display: flex;
    gap:30px;
    justify-content: center;
    align-items: center;
    position: relative;
    &::before{
        content: ''; 
        ${BGImageCenter(getStaticImage('app/cashMan.png'),'contain')};
        position: absolute;
        width: 155px;
        height: 155px;
        inset-inline-end: 40px;
        bottom: -25px;
        ${mobileMedia}{
            content: unset;
        }
    }   

    ${mobileMedia}{
        display: unset;
        height: calc(100% - 60px);
        ${scrollbar}
    }
`;

export const title = styled.div`
    ${dFlexCenter};
    justify-content: space-between;
    width: 100%;
    padding: 17px;
    color: ${DEFAULT_COLOR};
`;

export const buttons = styled.div`
    ${dFlexCenter};

    ${btn}.btnExport{
        min-width: unset;
        margin-inline-start: 10px;
        margin-block-start: unset;
        &::before {
            content:'';
            width: 10px;
            height: 14px;
            margin-inline-end: 5px;
            ${maskImage(document,'green', '10px')};
        }
    }
    ${btn}.btnPdf{
        &::before {
            background-color: ${RED};
        }
    }

`;

