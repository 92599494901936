import {useField} from 'formik';
import * as css from './FormikDatePicker.css';
import ReactDatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useSelector} from 'react-redux';
import {getPickerDate, getPickerTs, tsToDate} from '../../library/dates';
import {useEffect, useRef} from 'react';
import {onDatePickerChange, onFocus, onKeyDown} from './DatePickerFunctions';
import {ThemeProvider} from 'styled-components';
import useMobile from '../../hooks/useMobile';
import he from 'date-fns/locale/he';
import {DEFAULT_TIME_ZONE} from '../../globalVariables/locals';

function DatePicker({name, minDate, onChange=()=>{} ,isTimestamp=true,readOnly,isFixed=false,timezone=DEFAULT_TIME_ZONE,...props}) {
    const [{value}, , helpers] = useField(name);
    const lang = useSelector(state => state.user.lang);
    const ref = useRef(null);
    useEffect(() => {
        if(lang === 'he')
            registerLocale('he', he);
    }, []);

    // const handleDateChange = (date) => {
    //     const value = isTimestamp ? getTs(date,timezone) : date;
    //     onDatePickerChange(value, helpers);
    //     onChange(value);
    // };
    const handleDateChange = (date) => {
        const dateObj = typeof date === 'string' ? new Date(date) : date;
        if(dateObj) {
            dateObj.setHours(0, 0, 0, 0);
        }

        const value = isTimestamp ? getPickerTs(dateObj, timezone) : dateObj;
        onDatePickerChange(value, helpers);
        onChange(value);
    };

    const isMobile = useMobile();

    const DatePickerComponent = isMobile ? 'input' : ReactDatePicker;
    const datePickerProps = isMobile
        ? {
            type:'date',
            placeholder:'',
            value: value ? tsToDate(value,null,timezone,'YYYY-MM-DD') : '',
            min: tsToDate((minDate? minDate : getPickerTs(null,timezone)),null,timezone,'YYYY-MM-DD'),
            onChange: (e) => handleDateChange(e.target.value),
            className:value&&'hasValue'
        }
        : {
            selected: (isTimestamp && value )? getPickerDate(value,timezone) : value,
            minDate: minDate ? minDate * 1000 : getPickerTs(null,timezone) * 1000,
            dateFormat:'dd/MM/yyyy',
            onChange: handleDateChange,
            popperProps: isFixed ? {strategy: 'fixed'} : undefined,
            ...(readOnly?{}:{
                onKeyDown:(e)=>onKeyDown(e,ref,'date',props),
                onFocus:()=>onFocus(ref,value,'date',props)
            })
        };

    return (
        <ThemeProvider theme={{type: 'date'}}>
            <css.container ref={ref}>
                <DatePickerComponent
                    locale={'he'}
                    className="noBorder"
                    readOnly={readOnly}
                    placeholderText="DD/MM/yyyy"
                    {...props}
                    {...datePickerProps}
                />
            </css.container>
        </ThemeProvider>
    );
}

export default DatePicker;
