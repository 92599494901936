import React, {useEffect, useRef, useState} from 'react';
import * as css from './MenuItem.css';
import {useTranslation} from 'react-i18next';
import {Link, useLocation, useParams, withRouter} from 'react-router-dom';
import {useSelector} from 'react-redux';
import useShow from '../../../hooks/useShow';
import List from '../../../component/list/List';
import AccountPermission from '../../../pages/components/accountPermission/AccountPermission';
import {getPageTitle} from '../../navigation/Navigation';
import useMobile from '../../../hooks/useMobile';
import {refreshTokenIfNeed} from '../../../library/axios';
import useNavigationList from '../../navigation/useNavigationList';
import {getTabletList} from '../../subList/SubList';
import useCurrentItemUrl from '../../../tickchakHooks/useCurrentItemUrl';
import {reloadIframe} from '../../myIframe/MyIframe';

function MenuItem({miniDisplay, item,closeMenu=()=>{}}) {
    const account = useSelector(state => state.account);
    const eventType = useSelector(s => s.event.type);

    const [list, setList] = useState([]);

    const ref = useRef(null);

    const isMobile = useMobile();
    const {t,i18n} = useTranslation('layout');

    const {open, close, isOpen, showClass} = useShow(150);

    const location = useLocation();
    const event = useSelector(state => state.event);
    let path = (item.path.substring(1));
    const params = useParams();
    const currentItemUrl = useCurrentItemUrl();
    let idParams=params[currentItemUrl?.idParams];
    const navigationList = useNavigationList(idParams,path);

    useEffect(() => {
        setList(getTabletList(navigationList,t,idParams,currentItemUrl,subMenuClick,false));
    }, [account.permissions,event.settings?.billings?.hasBillings,i18n.language,currentItemUrl]);

    useEffect(() => {
        // if(!isMobile)
        close();
    }, [location]);

    const subMenuClick=()=>{
        closeMenu();
        refreshTokenIfNeed();
    };

    const refEnter=useRef(0);
    const leaveTimer = useRef(null);

    const onEnter = () => {
        refEnter.current=1;
        leaveTimer.current=setTimeout(() => {
            open();
        }, 400);
    };
    const timer = useRef(null);

    const onLeave = () => {
        clearTimeout(leaveTimer.current);
        refEnter.current=0;
        timer.current =setTimeout(()=>{
            if(refEnter.current===0)
                close();
        },200);
    };
    useEffect(() => () => clearTimeout(timer.current), []);

    const onClick = () => {
        if(isMobile)
            open();
    };

    const linkClick=()=>{
        if(isCurrent)
            reloadIframe();
        if(!list.length)
            closeMenu();
        refreshTokenIfNeed();
    };

    const isCurrent=item.path == `/${location.pathname.split('/')[3]}`;

    const menuItem = <css.menuItem $current={isCurrent}
    >
        <css.menuItemImg className={item.path.replace('/', '')} />
        <css.menuItemText>{getPageTitle(eventType,t,item.title)}</css.menuItemText>
        {!!list.length && !miniDisplay && <css.menuItemCircle><css.menuItemArrow /></css.menuItemCircle>}
    </css.menuItem>;

    const listComp = <>{isOpen && !miniDisplay &&
    <List
        title={isMobile&&t(path)}
        close={close}
        list={list}
        showClass={showClass}
        top={20}
        isChild={true}
        right={'160px'}
        fixedTop={ref.current.getBoundingClientRect().y}
        width={'200px'}  />}
    </>;

    return (<>
        <AccountPermission permission={item.permission || true}>
            <css.wrapItem  icon={path} ref={ref} onClick={onClick} onMouseEnter={isMobile ? null : onEnter} onMouseLeave={isMobile ? null : onLeave}  >
                {/* <Link key={props.key} to={'/e/' + event.eid + item.path} onClick={list.length?null:linkClick}> */}
                {!isMobile || !list.length ?
                    <Link to={'/e/' + event.eid + item.path} onClick={linkClick} data-cy={`${path}ListItem`}>
                        {/* { !list.length? <Link key={props.key} to={'/e/' + event.eid + item.path} onClick={list.length?null:linkClick}> */}
                        {menuItem}
                    </Link> :
                    menuItem

                }
                {listComp}
            </css.wrapItem>
        </AccountPermission>
    </>

    );
}

export default withRouter(MenuItem);
