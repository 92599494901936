import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import * as css from './Rating.css';
import Close from '../../../component/close/Close';
import Button from '../../../component/button/button/Button';
import {addRating} from '../../../redux/ratings/ratingsActions';
import store from '../../../store';
import setGlobalState from '../../../redux/setGlobalState';
import getSpecialTranslation from '../../../functions/getSpecialTranslation';

export const setCurrentRating=(action)=>{
    // let newCurrentRating = store.getState().ratings.ratings.filter((item)=>item.action===action)[0]
    let newCurrentRating = store.getState().ratings.ratings?.find((item) => item.action === action);
    if(newCurrentRating && !newCurrentRating.rated)
        setGlobalState('ratings.currentRating', {rating: newCurrentRating, show: true});
};
function Rating() {
    const {t} = useTranslation('layout');
    const pid = useSelector(state => state.account.pid);
    const eid = useSelector(state => state.event.eid);
    const userAccountRole=store.getState().account.userAccountRole;

    const [popState, setPopState] = useState(0);
    const [text, setText] = useState('');
    const ratingArr = [{key: 4, type: 'stunning'}, {key: 3, type: 'beautiful'}, {key: 2, type: 'nice'}, {key: 1, type: 'bad'}];
    const [{ratingSelectedKey, ratingSelectedType}, setRatingSelected] = useState({ratingSelectedKey: 0, ratingSelectedType: ''});
    const ratings = useSelector((state) => state.ratings);
    const currentRating = ratings.currentRating;

    const close = (rated) => {
        setPopState(0);
        setTimeout(() => {
            let ratingIndex = ratings.ratings.findIndex(item => item.urid === currentRating.rating.urid);
            if(rated)
                setGlobalState(`ratings.ratings[${ratingIndex}].rated`, true);
            setGlobalState('ratings.currentRating.show', false);
        }, 100);
        // setRatingSelected({ratingSelectedKey: 0, ratingSelectedType: ''})
    };

    const updateText = (e) => {
        setText(e.target.value);
    };
    const saveRating = async () => {
        await addRating({urid: currentRating.rating.urid, rating: ratingSelectedKey, eid: eid, pid:userAccountRole? pid:null, text: text});
        close(1);
    };

    const saveBtnProps = {
        text: t('send'),
        textColor: '#000',
        backgroundColor: '#76d6e8',
        successColor: '9CB900',
        hoverTextColor: '#76d6e8',
        hoverBackgroundColor: '#fff',
        hoverBorderColor: '#76d6e8',
        onClick: saveRating,
    };
    useEffect(() => {
        if(currentRating.show)
            setTimeout(function () {
                setPopState(1);
            }, 0);
    }, [currentRating.show]);
    if(!currentRating.show)
        return (
            <></>
        );

    const ratingText=getSpecialTranslation(`${currentRating.rating.action}RatingText`,t)
        ||t('ratingTitle', {action: t(`${currentRating.rating.action}Rating`)});

    return (
        <css.popup className={popState && 'show'}>
            <css.popupTop>
                <Close className={'popupRating'} onClick={close} />
                {!ratingSelectedKey && <css.popupTitle>{ratingText}</css.popupTitle>}
            </css.popupTop>

            <css.popupContain className={(ratingSelectedKey && 'input')}>
                {ratingSelectedKey ?
                    <>
                        <css.SelectedContain>
                            <css.selectedImage dataType={ratingSelectedType} />
                            <css.selectedText>
                                <css.selectedTextText>{t(ratingSelectedType + 'Title')}</css.selectedTextText>
                                {currentRating.rating.action==='newRealtime' && <css.selectedTextSubText>{t('realtimeRatingText')}</css.selectedTextSubText>}
                                <css.selectedTextInput><textarea value={text} onChange={updateText} />
                                </css.selectedTextInput>

                            </css.selectedText>
                        </css.SelectedContain>
                        <css.selectedBtn>
                            <css.selectedBtnCancel onClick={saveRating}>{t('cancel')}</css.selectedBtnCancel>
                            <Button BtnProps={saveBtnProps} />
                        </css.selectedBtn>
                    </>
                    :
                    ratingArr.map((item) => {
                        return <css.pcRating onClick={() => setRatingSelected({
                            ratingSelectedKey: item.key,
                            ratingSelectedType: item.type
                        })} key={item.key} index={item.key}>
                            <css.pcrImg dataType={item.type} />
                            <css.pcrText>{t(item.type)}</css.pcrText>
                        </css.pcRating>;
                    })}
            </css.popupContain>

        </css.popup>

    );
}

export default Rating;
