import styled from 'styled-components';
import {BGImageCenter, getStaticImage, mobileMedia, scrollbar, tabletMedia} from '../../../../globalVariables/GlobalVariables';
import {popContain} from '../../../cash/reportCash/ReportCash.css';
import {card, subTitle ,title} from '../../../cash/reportCash/reportCard/ReportCard.css';
import {windowBase} from '../../style.css';
import fonts from '../../fonts';
import {WINDOW_HEIGHT} from '../../accountRealtime/AccountRealtime.css';
import {getColor} from '../../eventRealtime/EventRealtime.css';

export const reports = styled.div`
    grid-area: windowsReports;
    ${windowBase};
    height: ${WINDOW_HEIGHT};
    ${mobileMedia}{
        width: 100%;
    }
    ${card}{
        background-color:unset;
        height:unset;
        width:unset;
        padding-inline: unset;
        padding-block-start: 50px;
    }
    ${subTitle}{
        display:none;
    }
    ${title}{
        display:none;
    }
    ${popContain}{
        gap: unset;
            justify-content: space-evenly;
        &::before{
            content:unset;
        }
        ${mobileMedia}{
            display: flex;
            height: unset;
            ${scrollbar}
        }
    }
    .white {
        margin-block-start: 50px;
        ${mobileMedia}{
            margin-block-start: 15px;
        }
    }
    svg{
        display:none;
    }
`;
export const subTitleRow = styled.div`
    ${fonts.textBold};
    height:19px;
    white-space: break-spaces;
    color: ${p=>getColor(p.theme.isDarkMode)};

`;
export const titleRowHeader = styled.div`
    ${fonts.windowTitle};
    font-weight: 700;
    color: ${p=>getColor(p.theme.isDarkMode)};
    height:38px;
    display:flex;
    align-items: center;

`;
export const titleRow = styled.div`
    padding:20px;
    padding-inline-end: 100px;
    position: relative;
    &::before{
        content: ''; 
        ${BGImageCenter(getStaticImage('app/cashMan.png'),'contain')};
        position: absolute;
        width: 100px;
        height: 100px;
        inset-inline-end:25px;
        top:10px;
        ${tabletMedia}{
            inset-inline-end:5px;
        }
        
    }   
`;
