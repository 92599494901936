import {useTranslation} from 'react-i18next';
import * as css from './RealtimeData.css';
import {useSelector} from 'react-redux';
import {formatRealtimeNumber} from '../../../functions';
import {useState} from 'react';
import useShow from '../../../../../hooks/useShow';
import List from '../../../../../component/list/List';
import {Link} from 'react-router-dom';
import RealtimeDetail from '../../realtimeDetail/RealtimeDetail';
import RealtimeDataTemplate from './RealtimeDataTemplate';
import {XHR_LOADING_STATE} from '../../../../../assets/constants/constants';
import {eventTypes} from '../../../eventRealtime/eventTypes';
import Counter from '../../counter/Counter';
import setGlobalState from '../../../../../redux/setGlobalState';
import store from '../../../../../store';

const RealtimeData = () => {
    const {t} = useTranslation('realtime');
    const [date, setDate] = useState('today');
    const event = useSelector((s:any) => s.event);
    const stats = useSelector((s:any) => s.realtime?.event?.eventStats);
    const isLoading = useSelector((s:any)=>s.realtime?.event?.getEventStatsXHR === XHR_LOADING_STATE);
    const socketCashAmount = useSelector((s:any)=>s.realtime?.event?.socketCashAmount);
    const socketTicketsAmount = useSelector((s:any)=>s.realtime?.event?.socketTicketsAmount);
    const socketOrdersAmount = useSelector((s:any)=>s.realtime?.event?.socketOrdersAmount);
    const cashPrice = date === 'all' ? stats.month?.totalPrice + stats.other?.totalPrice : stats[date]?.totalPrice || 0;
    const transaction = stats[date]?.amountOrders || 0;
    const ticketsSold = stats[date]?.ticketsSold || 0;
    const desperate = stats[date]?.sumDesperate || 0;
    const leadingTicket = stats[date]?.leadingTicket || '';
    const nowInPage = stats?.now?.allVisitors || 0;
    const allVisitors = date === 'today' ? ((stats[date]?.allVisitors || 0) + nowInPage) : (stats[date]?.allVisitors || 0);
    const translateTitle = eventTypes[event.type]?.translateTitle || eventTypes.default.translateTitle;
    const leadingTicketTitle = eventTypes[event.type]?.leadingTicket || eventTypes.default.leadingTicket;

    const updateStats = () => {
        ['today', 'week', 'month'].forEach((date) => {
            const totalPrice = stats[date]?.totalPrice;
            const orders = stats[date]?.amountOrders;
            const tickets = stats[date]?.ticketsSold;
            setGlobalState(`realtime.event.eventStats[${date}]`, {...store.getState().realtime.event.eventStats[date], ...{
                totalPrice: totalPrice + socketCashAmount,
                ticketsSold: tickets + socketTicketsAmount,
                amountOrders: parseInt(socketOrdersAmount) === 1 ? orders + 1 : orders
            }
            });
        });
        setGlobalState('realtime.event', {...store.getState().realtime.event,...{
            socketCashAmount: 0,
            socketTicketsAmount: 0,
            socketOrdersAmount:0
        }
        });
    };

    const realtimeDetails = [
        {title: 'visits', value: formatRealtimeNumber(allVisitors, true), text: ''},
        {title: 'cash', value: <span><css.currency>{event.currencySymbol}</css.currency>
            <Counter value={cashPrice||0} socketValue={socketCashAmount} callback={updateStats}/>
        </span>
        , text: `${transaction} ${t('transactions')}`},
        {title: translateTitle, value:
             <Counter value={ticketsSold || 0} socketValue={socketTicketsAmount} callback={updateStats}/>
        , text: !!leadingTicket && `${t(leadingTicketTitle)} - ${leadingTicket}`},
        {title: 'desperate', value: formatRealtimeNumber(desperate, true), text: <Link to={`/e/${event.eid}/crm`}>{t('toDesperate')}</Link>},
        {title: 'nowInPage', value: nowInPage}
    ];
    const {open, isOpen, showClass, close} = useShow();
    const list = [
        {text: t('today'), value: 'today'},
        {text: t('yesterday'), value: 'yesterday'},
        {text: t('week'), value: 'week'},
        {text: t('month'), value: 'month'},
        {text: t('all'), value: 'all'}
    ];

    if(isLoading)
        return <RealtimeDataTemplate />;

    return (
        <css.wrapper>
            <css.wrap>
                <css.firstSection>
                    <css.sectionTitle>{t('realtime')}</css.sectionTitle>
                    <css.subtext>{date === 'all' ? t('realtimeAllText'): t('realtimeText', {time: t(date)})}</css.subtext>
                </css.firstSection>
                <css.list isOpen={isOpen} className='free-color' onClick={open}>{t(date)}</css.list>
                {!!isOpen && <List showClass={showClass} close={close} list={list} width={130} onClick={(item) => {
                    setDate(item.value);
                }} classes={'free-color'}/>}
            </css.wrap>
            <css.data>
                {realtimeDetails.map((item)=>
                    <RealtimeDetail item={item} key={item.title}/>
                )}
            </css.data>
        </css.wrapper>
    );
};

export default RealtimeData;
