import NewEventForm from '../../components/newEventForm/NewEventForm';
import * as Yup from 'yup';
import {newEventInitialValues,newEventSteps, newEventValidationSchema} from '../../globalSteps';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useEffect} from 'react';
import {getBudgetItems} from '../../../../redux/account/accountActions';
import {getHalls} from '../../../../redux/event/eventActions';
import setGlobalState from '../../../../redux/setGlobalState';
import {generateSVG} from '../../../../functions/generateSVG';
import {getCategories} from '../../../../redux/categories/categoriesActions';

function NewAreaEvent() {
    const event=useSelector(s=>s.event);
    const budgetItems = useSelector(s=>s.account.budgetItems);
    const categories = useSelector(s=>s.categories.eventCategories);
    const {t} = useTranslation(['global', 'newEvent', 'errors', 'layout']);
    
    const {isOpenLocaleStep, duplicateSourceEid} = useSelector((s) => ({
        isOpenLocaleStep: s.ui.event.isOpenLocaleStep,
        duplicateSourceEid: s.ui.event.duplicateSourceEid
    }));
    const eventSteps = [
        newEventSteps.title,
        newEventSteps.category(t),
        newEventSteps.location,
        ...(!duplicateSourceEid 
            ? ((event?.map?.approveMap) 
                ? [newEventSteps.readyMap] 
                : [newEventSteps.map]) 
            : []),
        newEventSteps.date,
        ...(isOpenLocaleStep ? [newEventSteps.locale] : []),
        newEventSteps.artists,
        newEventSteps.description,
    ];

    const steps = [
        ...eventSteps,
        ...(budgetItems.length ? [newEventSteps.budgetIndex] : []),
    ];

    useEffect(()=>{
        getBudgetItems();
        if(categories.length === 0){
            getCategories();
        }
        event.halls || getHalls({search: '', limit: 10, offset: 0}).then((res)=>{
            const updatedRes = res?.halls?.slice(0, event.maps.length === 1 ? 2 : 3).map(item => ({
                ...item,
                svg: item.areaCount > 1 ? generateSVG(item.design) :null
            }));
            setGlobalState('event', {...event,halls:res.halls, maps:[...event.maps,...updatedRes]});
        });
    },[]);

    const defaultMap = {eid: 0, title: '', approveMap: 0, seatCount: 0, design: '', sendMap: null, message: '', action: ''};

    const mapValue = event?.map ? {...event.map, sendMap: null, message: '', action: ''} : defaultMap;

    const initialValues = {
        ...newEventInitialValues(event),
        map: mapValue,
    };

    return (
        <NewEventForm
            initialValues={initialValues}
            validationSchema={Yup.object().shape(newEventValidationSchema(t))}
            steps={steps}
        />
    );
}

export default NewAreaEvent;
