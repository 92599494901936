import {memo, useEffect, useRef} from 'react';
import * as css from './EventRealtime.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import useShow from '../../../hooks/useShow';
import {getImageColors} from '../../../redux/event/eventActions';
import UserProfileMenu from '../../../layout/topMenu/layoutUserProfile/layoutUserProfileMenu/LayoutUserProfileMenu';
import {ThemeProvider} from 'styled-components';
import {getRealtime, getRealtimeDesign} from '../../../redux/realtime/realtimeActions';
import useMobile from '../../../hooks/useMobile';
import setGlobalState from '../../../redux/setGlobalState';
import {setCurrentRating} from '../../components/rating/Rating';
import useTimeout from '../../../hooks/useTimeout';
import {Profile} from '../components/profile/Profile';
import ManagePromotion from '../../../component/managePromotion/ManagePromotion';
import AccountPermission from '../../components/accountPermission/AccountPermission';
import PublishPageBtn from '../../components/publishPageBtn/PublishPageBtn';
import EventActive from '../../components/eventActive/EventActive';
import useSocket from '../../../hooks/useSocket';
import RealtimeHeader from '../realtimeHeader/RealtimeHeader';
import LastUpdateTimer from '../components/lastUpdateTimer/LastUpdateTimer';
import useQueryStrings from '../../../hooks/useQueryStrings';
import {onConfirmPortalRequest} from '../../eventSettings/mailingForm/MailingForm';
import Windows from './Windows';
import EventDetails from './eventDetails';
import CustomScrollbar from '../../../component/customScrollbar/CustomScrollbar';
import zIndex from '../../../globalVariables/zIndex';

export const eventStatusComp={hub:PublishPageBtn, default:EventActive};

function EventRealtime (){
    const event = useSelector((s:any) => ({
        getImageColorsXHR: s.event.getImageColorsXHR,
        colors: s.event.colors
    }));
    const user = useSelector((s:any) => ({
        picture: s.user.picture,
        nameFirst: s.user.nameFirst,
        nameLast: s.user.nameLast
    }));
    const darkDisplay = useSelector((s:any) => s.realtime.user.realtimeDesign?.darkDisplay);
    const {t} = useTranslation('realtime');
    const {open, close, isOpen} = useShow();
    const isMobile = useMobile();
    const timerRating=useTimeout();
    const refContent = useRef();

    useQueryStrings('portalRequest',(portalType)=>onConfirmPortalRequest(portalType));

    useEffect(() => {
        getRealtimeDesign().then();
        if(!event.getImageColorsXHR)
            getImageColors().then();
        getRealtime().then();
        timerRating.current = setTimeout(() => {
            setCurrentRating('newRealtime');
        }, 20000)as unknown as any;
    },[]);

    useSocket();

    const cssStyle = `
        inset-inline-end: 8%;
        inset-block-start: 85px;
        z-index: ${zIndex.baseHigh}
    `;

    return (
        <ThemeProvider theme={{isDarkMode: darkDisplay}}>
            {!isMobile && <CustomScrollbar contentRef={refContent} cssStyle={cssStyle} maxBottom={65}/>}
            <css.wrapper data-cy="realtime" colors={event.colors} ref={refContent}>
                {!!isOpen && <UserProfileMenu close={close} closeMenu={close} hide={false} showEvents={()=>{}} userImgPath={user.picture} userName={`${user.nameFirst} ${ user.nameLast}`}/>}
                {!isMobile && <LastUpdateTimer />}
                <RealtimeHeader isEvent={true} />
                <css.gridTop>
                    <Profile openProfile={open}/>
                    <EventDetails />
                </css.gridTop>
                {!!isMobile && <LastUpdateTimer />}
                <AccountPermission permission={'promotion message'} message={undefined}>
                    <ManagePromotion/>
                </AccountPermission>
                <Windows />
                <css.oldSystem onClick={()=>{setGlobalState('realtime.user.oldSystem', true); setCurrentRating('newRealtime');}}>{t('oldSystemText')}</css.oldSystem>
            </css.wrapper>
        </ThemeProvider>
    );
}

export default memo(EventRealtime);
