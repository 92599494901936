import styled from 'styled-components';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';
import {add} from '../../../../assets/symbols/symbols/Symbols.css';
import {wrap} from '../../../../component/selectItem/SelectItem.css';

export const wrapper=styled.div`
    height: max-content;
    width: 100%;
    display: flex;
    flex-flow: wrap;
    cursor: pointer;
    padding-top: 20px; 
    gap: 7px;

`;
export const addButton =styled(wrap)`
   
`;
export const icon = styled.div`
    width: 16px ;
    height: 16px;
    ${BGImageCenter(add,'10px')}
    margin-inline-start: 5px;
`;

export const text = styled.div`
    font-size: 13px;
    margin-inline: 5px;
`;
