import styled from 'styled-components';
import {dot as leadDot} from '../Ambassadors.css';
import {BGImageCenter, DEFAULT_COLOR, dFlexCenter, ellipsisLines, laptopMedia, mobileMedia, OFF_WHITE, SHADOW_COLOR, tabletMedia} from '../../../../../../globalVariables/GlobalVariables';
import {justifyEvenly} from '../../../../../../globalVariables/GlobalFlex';
import realtimeFonts from '../../../../fonts';

export const row = styled.div`
    width: 100%;
    height: 52px;
    flex-shrink: 0;
    border-radius: 10px;
    background: ${OFF_WHITE};
    box-shadow: 2px 0px 6.1px 2px ${SHADOW_COLOR};
    ${dFlexCenter};
    ${justifyEvenly};
    margin-bottom: 10px;
    padding-inline: 5px;
`;

export const profile = styled.div <{$image: string}>`
    width: 39px;
    height: 39px;
    border-radius: 50%;
    border: 1px solid ${DEFAULT_COLOR};
    ${p=>BGImageCenter(p.$image, 'cover')}
    ${mobileMedia}{
        width: 29px;
        height: 29px;
    }
`;
export const value = styled.div`
    ${realtimeFonts.tableValue};
    width: 120px;
    ${ellipsisLines(2)}
    margin-inline: 3px;
    &.small{
        width: 50px;
        ${mobileMedia}{
            width: max-content;
        }
    }
    ${tabletMedia}{
        width: 90px;
        font-size: 12px;
    }
    ${laptopMedia}{
        width: 100px;
    }
`;
export const dot = styled(leadDot)`

`;
export const wrapValue = styled.div`

`;
export const wrapArrow = styled.div`
    ${mobileMedia}{
        width: 20px;
        position: relative;
        inset-inline-end: 10px;
    }
`;