import * as css from './CourseMeetingsList.css';
import {useSelector} from 'react-redux';
import List from '../../../component/list/List';
import {
    getHourMinutes,
    getTs, checkIfToday as isToday,
    tsGetDateAndMonth,
    tsGetDayOfWeek
} from '../../../library/dates';
import {useTranslation} from 'react-i18next';
import {calendar} from '../../../assets/symbols/symbols/Symbols.css';
import {Link} from 'react-router-dom';
import {useEffect} from 'react';
import store from '../../../store';
import {getTicketAttendance} from '../../../redux/attendance/attendanceActions';
const difference=(date)=>Math.floor(Math.abs(date - getTs()));
export const getClosetMeet=(meetings)=>meetings?.reduce((total,item)=>difference(total.date)<difference(item.date)?total:item,0);

function CourseMeetingsList({showClass,close,tid,propsSide={right:'0px'}}) {

    const eid = useSelector(state => state.event.eid);
    const {t} = useTranslation('attendance');
    const globalT = useTranslation('global');
    const ticketMeetings=useSelector(s=>s.attendance.tickets.find(t => Number(t.tid) === (tid||Number(s.attendance.currentTid)))?.meetings||[]);
    const isMeetToday= ticketMeetings?.find(i => i.today===1)?.tdid;
    const closetMeet= getClosetMeet(ticketMeetings);
    let tempList= ticketMeetings.map((item) => {
        const fullDate=`${tsGetDateAndMonth(item.date)}   ${globalT.t('fullDay'+(tsGetDayOfWeek(item.date)))}   ${getHourMinutes(item.date)}`;
        return {'key':item.tdid,...(item.tdid===closetMeet?.tdid?{'scrollToMe': true}:{}),'text':fullDate,'isLink':true,'to':`/e/${eid}/attendance/${item.tdid}`,classes:`arrow ${isToday(item.date)&&'today'}`,icon:calendar};
    });
    useEffect(()=>{
        if(tid && store.getState().attendance.tickets.findIndex(t => t.tid === tid)===-1){
            getTicketAttendance(0,tid).then();
        }
    },[]);
    return (
        <css.container text={t('today')}>
            <List
                title={
                    <css.title >
                        <css.titleText>{globalT.t('meetingsDate')}</css.titleText>
                        {isMeetToday&&<Link to={`/e/${eid}/attendance/${isMeetToday}`}  >
                            <css.titleBtn >{t('today')}</css.titleBtn></Link>}
                    </css.title>
                }
                list={tempList} {...propsSide} top={-19} showClass={showClass} close={close}  />
        </css.container>
    );
}

export default CourseMeetingsList;
