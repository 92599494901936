import {DEFAULT_TIME_ZONE} from '../globalVariables/locals';

export const ts2date = (ts) => {

    var date = new Date(ts * 1000);
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    return day + '.' + month + '.' + year;
};

export const date2ts = (date, time) => {
    return new Date(date.substr(6), parseInt(date.substr(3, 2)) - 1, date.substr(0, 2), (time ? time.substr(0, 2) : 0), (time ? time.substr(3) : 0), 0).getTime() / 1000;
};
function dateUpdateToTimezone(date, timezone) {
    var invdate = new Date(date.toLocaleString('en-US', {
        timeZone: timezone
    }));

    var diff = date.getTime() - invdate.getTime();
    return new Date(date.getTime() - diff);
}

export const ts2time = (ts, timeZone = DEFAULT_TIME_ZONE) => {
    var date = dateUpdateToTimezone(new Date(ts * 1000), timeZone);
    var hours = '0' + date.getHours();
    var minutes = '0' + date.getMinutes();
    return hours.substr(-2) + ':' + minutes.substr(-2);

};

export const ts2DateTime = (ts) => {
    var dT = (new Date(ts * 1000));
    var year = dT.getFullYear();
    var month = (1 + dT.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    var day = dT.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    var hours = dT.getHours().toString();
    hours = hours.length > 1 ? hours : '0' + hours;
    var minutes = dT.getMinutes().toString();
    minutes = minutes.length > 1 ? minutes : '0' + minutes;
    return year + '-' + month + '-' + day + 'T' + hours + ':' + minutes;
};

