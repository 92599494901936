import * as css from './ReportCard.css';
import DoubleSidedArrow from '../../../../assets/icons/DoubleSidedArrow';
import Btn from '../../../../component/button/btn/Btn';
import useShow from '../../../../hooks/useShow';
import List from '../../../../component/list/List';
import React from 'react';
import useMobile from '../../../../hooks/useMobile';

type TButton = {
    text : string,
    onClick: (element:any) => void
}

type TListButton = {
    text : string,
    onClick: (element:any) => void
    title: string,
    list:[]
}

function ReportCard({title, subTitle, listButton, listTitle, whiteButton}:{
    title:string,
    subTitle:string,
    listButton:TListButton,
    listTitle?:React.ReactNode, // Making listTitle optional with React.ReactNode type
    whiteButton:TButton
}) {
    const showList=useShow();
    const isMobile=useMobile();
    
    return (
        <css.card disabled={!listButton.list?.length}>
            <css.title>{title}</css.title>
            <css.subTitle>{subTitle}</css.subTitle>
            <css.buttonTitle>{listButton.title}</css.buttonTitle>
            <Btn className={'yellow'} onClick={showList.open} disabled={undefined}>
                {listButton.text}
                {showList.isOpen &&
                    <List 
                        showClass={showList.showClass} 
                        close={showList.close} 
                        height={isMobile ? 1000 : 250} 
                        bottom={-100}
                        list={listButton.list}
                        onClick={listButton.onClick}
                        title={listTitle}
                    />}
            </Btn>

            <DoubleSidedArrow/>
            <Btn className={'white'} onClick={whiteButton.onClick} disabled={undefined} >{whiteButton.text}</Btn>

        </css.card>
    );
}

export default ReportCard;
