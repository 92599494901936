import * as css from './StatusAnimation.css';
import Animation from '../../../../../../component/animation/Animation';
import activeJson from '../../../../../../assets/animations/active.json';
import {useSelector} from 'react-redux';

export function StatusAnimation() {
    const socketNewEnter = useSelector((s: any) => s.realtime?.eventDay?.socketNewEnter);
    
    return (
        <css.animation>
            <css.status $active={!!socketNewEnter} />
            {socketNewEnter > 0 && (
                <Animation animation={activeJson} isLoop={false} />
            )}
        </css.animation>
    );
}
