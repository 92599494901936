import * as css from './CloseEventDay.css';
import {useTranslation} from 'react-i18next';
import Btn from '../../../../../../component/button/btn/Btn';
import {ValidationsChart} from '../ValidationsChart/ValidationsChart';
import useMobile from '../../../../../../hooks/useMobile';
import {TimeDisplay} from '../TimeDisplay/TimeDisplay';
import {StatusAnimation} from '../StatusAnimation/StatusAnimation';
import {createContext} from 'react';
import {ThemeProvider} from 'styled-components';

interface CloseEventDayProps {
    callback: (value: boolean) => void;
}
export const CompactEventDayContext = createContext({isCompact: false});

export function CloseEventDay({callback}: CloseEventDayProps) {
    const {t} = useTranslation('realtime');
    const isMobile = useMobile();
    
    const moreDetailsBtn = <Btn onClick={() => callback(false)} className={'yellow free-color'} disabled={false}>
        {t('moreDetails')}
    </Btn>;

    return (
        <CompactEventDayContext.Provider value={{isCompact: true}}>
            <ThemeProvider theme={{$compact: true}}>
                <css.wrapper>
                    {!isMobile && <>
                        <css.section className={'free-color'}>
                            <TimeDisplay/>
                        </css.section>
                        <StatusAnimation />
                        <css.dataSection>
                            <ValidationsChart/>
                        </css.dataSection>
                        {moreDetailsBtn}
                    </>}
                    {isMobile && <>
                        <css.title>
                            {t('eventDay')}
                            {moreDetailsBtn}
                        </css.title>
                        <css.dataSection>
                            <css.section className={'free-color'}>
                                <StatusAnimation />
                                <TimeDisplay />
                            </css.section>
                            <ValidationsChart/>
                        </css.dataSection>
                    </>}
                </css.wrapper>
            </ThemeProvider>
        </CompactEventDayContext.Provider>
    );
}
