import {useTranslation} from 'react-i18next';
import * as css from '../EventDay.css';
import StopwatchIcon from '../components/icons/StopwtchIcon';
import Animation from '../../../../../component/animation/Animation';
import activeJson from '../../../../../assets/animations/active.json';
import {DARK_GREEN2, GRAY3} from '../../../../../globalVariables/GlobalVariables';

interface UpdateStatusProps {
    socketNewEnter: number;
    remainingTime: string;
    onUpdateNow: () => void;
}

export const UpdateStatus = ({socketNewEnter, remainingTime, onUpdateNow}: UpdateStatusProps) => {
    const {t} = useTranslation('realtime');

    return (
        <>
            <css.autoUpdate $newEnter={socketNewEnter} className='free-color'>
                <StopwatchIcon color={socketNewEnter ? DARK_GREEN2 : GRAY3}/>
                {socketNewEnter === 1 ? <>
                    {t('newValidate')}
                    <Animation animation={activeJson} isLoop={false}/>
                </> : t('autoUpdate')}
            </css.autoUpdate>
            {remainingTime && (
                <css.update className='free-color'>
                    <StopwatchIcon />
                    {t('updateIn', {minutes: remainingTime})} 
                    <css.link onClick={onUpdateNow}>
                        {t('willUpdateNow')}
                    </css.link>
                </css.update>
            )}
        </>
    );
};
