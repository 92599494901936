import * as cssAF from './AttendanceFrame.css';
import * as cssCML from '../../courseMeetingsList/CourseMeetingsList.css';
import {useTranslation} from 'react-i18next';
import Meeting from '../../meeting/Meeting';
import {getAttendanceDates, getEventAttendance} from '../../../../redux/attendance/attendanceActions';
import {useSelector} from 'react-redux';
import {checkIfToday  as isToday, getTs, tsGetDateAndMonth, tsGetDayOfWeek} from '../../../../library/dates';
import {useHistory} from 'react-router-dom';
import {Else, If, Then} from 'react-if';
import Btn from '../../../../component/button/btn/Btn';
import Loader from '../../../../component/loader/Loader';
import {useEffect} from 'react';
import useShow from '../../../../hooks/useShow';
import List from '../../../../component/list/List';
import {calendar} from '../../../../assets/symbols/symbols/Symbols.css';
import setGlobalState from '../../../../redux/setGlobalState';
const css= {...cssAF,...cssCML};

function AttendanceFrame() {
    const {getAttendanceDatesXHR,getEventAttendanceXHR,event}=useSelector(s=>s.attendance);
    const {dates,currentDateIndex}=event;
    const date=dates?dates[currentDateIndex]:{};
    const currentDate=date?.date||getTs();
    const meetings=date?.meetings||[];
    const eid = useSelector(s=>s.event.eid);
    const history = useHistory();
    const {t} = useTranslation('attendance');
    const globalT = useTranslation('global');
    const openParticipantsScreen = (tdid)=>{
        history.push(`/e/${eid}/attendance/${tdid}`);
    };
    const hasNotMeetings=(!meetings?.length&&(getEventAttendanceXHR==='SUCCESS'||(!dates?.length &&getAttendanceDatesXHR==='SUCCESS')));

    const {open, close, isOpen, showClass} = useShow();

    const nextPrevDate=(add)=>(dates||[])[currentDateIndex+add]&&setGlobalState('attendance.event.currentDateIndex',currentDateIndex+add);

    const setCurrentIndex=(index)=>{
        setGlobalState('attendance.event.currentDateIndex',index);
        close();
    };
    const getDateText=(date)=>`${globalT.t('fullDay'+(tsGetDayOfWeek(date)))} ${tsGetDateAndMonth(date)} `;
    const getList=()=> dates?.map((item,index) => {
        return {key:item.date,...(item.date===currentDate ? {'scrollToMe': true}:{}),'text':getDateText(item.date),icon:calendar,onClick:()=>setCurrentIndex(index),classes:`arrow ${isToday(item.date)&&'today'}`};
    });

    useEffect(()=>{
        if(!getAttendanceDatesXHR)
            getAttendanceDates().then();
    },[]);
    useEffect(()=>{
        if(currentDateIndex || currentDateIndex === 0)
            getEventAttendance(date?.date).then();
    },[currentDateIndex]);
    const isTodayIndex=dates?.findIndex(d=>isToday(d.date));
    return (
        <css.frame>
            <css.frameTitle >
                <css.titleArrowRight onClick={()=>nextPrevDate(-1)}/>
                <css.date  onClick={open} text={t('today')}>
                    {(isToday(currentDate)?`${t('today')} - `:'')+ getDateText(currentDate)}
                    {isOpen &&<List
                        title={
                            <css.title >
                                <css.titleText>{t('meetingsDate')}</css.titleText>
                                {isTodayIndex>-1&&<css.titleBtn onClick={()=>setCurrentIndex(isTodayIndex)}>{t('today')}</css.titleBtn>}
                            </css.title>
                        }
                        list={getList()} left={'65px'} top={0} showClass={showClass} close={close}  />}
                </css.date>
                <css.titleArrowLeft onClick={()=>nextPrevDate(1)}/>
            </css.frameTitle>
            <Loader onScrollEnd={()=>getEventAttendance(currentDate).then()} XHR={getEventAttendanceXHR}>
                <If condition={hasNotMeetings}>
                    <Then>
                        <css.noMeetings>
                            <css.noMeetText>{t('noMeetings')}</css.noMeetText>
                            <Btn onClick={()=>history.push(`/e/${eid}/courses`)}>{t('goCourse')}</Btn>
                        </css.noMeetings>
                    </Then>
                    <Else>
                        <css.right className={hasNotMeetings?'noMeetToday':''}>
                            {meetings.map((meeting,index)=>
                                <Meeting key={index} meeting={meeting}  onClick={()=>openParticipantsScreen(meeting.tdid)}/>)}
                            {!!hasNotMeetings&&<css.noMeetText>{t('noMeetToday')}</css.noMeetText>}
                        </css.right>
                    </Else>
                </If>

            </Loader>
        </css.frame>
    );
}
export default AttendanceFrame;
