import {formatDecimal, formatNumber} from '../../../../../library/numberFunction';
import * as css from './Detail.css';

function Detail({value,sign,text , isSymbol=false, className='', number=false}) {

    return (
        <css.detail className={className} >
            {isSymbol?
                <>
                    <span>{sign}</span>{Number.isFinite(value) ? (number ? formatNumber(value, true) : formatDecimal(value)):0}
                </>:
                <>
                    {Number.isFinite(value) ? (number ? formatNumber(value, true) : formatDecimal(value)): 0}<span>{sign}</span>
                </>
            }
            <css.text>{text}</css.text>
        </css.detail>
    );
}
export default Detail;
