import {combineReducers} from 'redux';
import ui from './ui/uiReducer.js';
import forms from './forms/formsReducer.js';
import event from './event/eventReducer.js';
import account from './account/accountReducer.js';
import user from './user/userReducer.js';
import auth from './auth/authReducer.js';
import suggestions from './suggestions/suggestionsReducer.js';
import cluster from './cluster/clusterReducer.js';
import mailing from './mailing/mailingReducer.js';
import ratings from './ratings/ratingsReducer.js';
import categories from './categories/categoriesReducer';
import preparingEvent from './preparingEvent/preparingEventReducer.js';
import crowds from './crowds/crowdsReducer';
import team from './team/teamReducer';
import tickets from './tickets/ticketsReducer';
import security from './security/securityReducer';
import supplier from './supplier/supplierReducer';
import attendance from './attendance/attendanceReducer';
import publish from './publish/publishReducer.js';
import branding from './branding/brandingReducer.js';
import languages from './languages/languagesReducer.js';
import design from './design/designReducer.js';
import realtime  from './realtime/realtimeReducer.ts';
import cash  from './cash/cashReducer.ts';
import promotion from './promotion/promotionReducer.tsx';
import management from './management/managementReducer.ts';

export default combineReducers({
    account,
    attendance,
    auth,
    categories,
    cluster,
    crowds,
    event,
    forms,
    mailing,
    preparingEvent,
    publish,
    ratings,
    security,
    suggestions,
    supplier,
    team,
    tickets,
    ui,
    user,
    branding,
    languages,
    design,
    realtime,
    promotion,
    cash,
    management
});
