import {useTranslation} from 'react-i18next';
import useShow from '../../hooks/useShow';
import Close from '../close/Close';
import List from '../list/List';
import * as css from './SelectItem.css';
import {ex,add, remove, pencil} from '../../assets/symbols/symbols/Symbols.css';
import {useField} from 'formik';
import setGlobalState from '../../redux/setGlobalState';
import {bounceMessage} from '../../layout/userMessages/UserMessage';
import {deleteCategory} from '../../redux/categories/categoriesActions';
import {useSelector} from 'react-redux';

function SelectItem({catId,text,icon,onSelected = () => {},isEditable,editShow,
    index,selectedArray,}) {
    const {t} = useTranslation('newEvent');
    const tLayout = useTranslation('layout');
    const {open, close, isOpen, showClass} = useShow();
    const categories = useSelector((s) => s.categories.eventCategories);
    const [{value}, ,categoryHelpers] = useField('category');
    const selected = selectedArray?.find(id => id === catId);
    const [,,{setValue: setTitle}]=useField('categoryTitle');
    const [,,{setValue: setIndex}]=useField('categoryIndex');
    const edit = () => {
        setTitle(text);
        setIndex(index);
        editShow.open();
    };
    const removeItem = () => {
        bounceMessage(t('deleteMassage'),'warning', 
            {
                text: tLayout.t('btnMessage1'),
                onClick: async () => {
                    await deleteCategory(catId)
                        .then(() => {
                            const updatedCategories = categories.filter((c) => c.catId !== catId);
                            setGlobalState('categories.eventCategories', updatedCategories);
                            const updateFieldCategory = value.filter(item => item !== catId);
                            categoryHelpers.setValue(updateFieldCategory);
                            setTitle(null);
                            setIndex(null);
                            editShow.close();
                        });}},
            {text: tLayout.t('btnMessage'), onClick: () => {}});
    }; 
    const optionList = [
        {text: t('removeCategory'), onClick: removeItem, icon: remove},
        {text: t('edit'), onClick: edit, icon: pencil}
    ];
    const onClose=(e)=>{
        if(e?.stopPropagation) {
            e.stopPropagation();
        }
        const updateCategory = value.filter(item => item !== catId);
        categoryHelpers.setValue(updateCategory);
    };
    if(isEditable) {
        const option = selected === catId
            ? {text: t('removeSelection'), onClick: onClose, icon: ex}
            : {text: t('addSelection'), onClick: ()=>onSelected(catId), icon: add};
        if(optionList[0]?.text !== option.text) {
            optionList.unshift(option);
        }
    }
    const onClick=()=>{
        if(isEditable && selected === catId) 
            onClose();
        else
            onSelected(catId);
    };
    const openList=(e)=>{
        e.stopPropagation();
        open();
    };
    return (
        <>
            <css.wrap
                className={selected === catId && 'toggle'}
                onClick={onClick}
                data-cy={`categoryChoose-${icon}`}>
                <css.icon $icon={icon} />
                <css.text>{text}</css.text>
                {isEditable && <css.opIcon onClick={(e)=>{openList(e);}}/>}
                {!isEditable && <css.clear>
                    <Close onClick={(e)=>{onClose(e);}} icon={icon} />
                </css.clear>}
            </css.wrap>
            {isOpen && <List close={close} width={'200px'} list={optionList} showClass={showClass} />}
        </>
    );
}

export default SelectItem;