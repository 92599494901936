import styled from 'styled-components';
import realtimeFonts from '../../../fonts';
import {EventMenuForm} from '../../../../../layout/eventMenu/EventMenu.css';
import {dFlexCenter, LIGHT_GRAY, mobileMedia, scrollbar, tabletPlusMedia} from '../../../../../globalVariables/GlobalVariables';
import zIndex from '../../../../../globalVariables/zIndex';
import {dFlex, dFlexBetween} from '../../../../../globalVariables/GlobalFlex';

export const container = styled.div`
  padding: 20px;
  height: 100%;
  ${mobileMedia}{
    padding: 0;
    padding-block: 20px;
    padding-inline: 6px;
  }
`;
export const title = styled.div`
    ${realtimeFonts.windowTitle}
    ${dFlexBetween}
    position: relative;
    width: calc(100% - 40px);
    ${mobileMedia}{
        padding-inline-start: 20px;
    }
`;
export const ticketsPage = styled(EventMenuForm)`
    margin: 0 0;
    margin-inline-end: 15px;
    ${mobileMedia}{
      margin-inline-end: 30px;
    }
`;
export const data = styled.div`
    height: calc(100% - 60px);
    margin-top: 20px;
    ${tabletPlusMedia}{
      ${dFlex};
    } 
`;
export const chartWrap = styled.div`
    width: max-content;
    margin: auto;
    margin-bottom: 10px;
`;
export const chart = styled.div`
    ${dFlexCenter};
    width: 250px;
    height: 150px;
    position: relative; 
    z-index: ${zIndex.baseRaised};
`;
export const tableTitle = styled.div`
    ${dFlexBetween}
    position: relative;
    width: calc(100% - 90px);
    inset-inline-start: 90px;
    ${mobileMedia}{
      display: none;
    }
`;
export const text = styled.div`

`;
export const chartValue = styled.div<{$bigValue: boolean}>`
    ${p=>p.$bigValue ? realtimeFonts.amount : realtimeFonts.mainDataBold}
`;

export const chartAmount = styled.div`
    ${realtimeFonts.tableValue};
    font-weight: 400;
`;
export const summaryTitle = styled.div`
    margin-bottom: 5px;
    ${realtimeFonts.tableValue};
    font-weight: 400;
    text-align: start;
    margin-inline: auto;
    position: relative;
    inset-inline-start: 40px;
    span{
        font-weight: bold;
    }
`;
export const summary = styled.div`
    background-color: ${LIGHT_GRAY};
    padding: 8px;
    padding-inline: 10px;
    border-radius: 25px;
    margin-bottom: 4px;
    width: max-content;
    margin: auto;
    span{
        font-weight: bold;
    }
`;

export const summaryContainer = styled.div`
    text-align: center;
    margin-top: 15px;
    width: 250px;
`;

export const table = styled.div`
    inset-block-start: 15px;
    height: 250px;
    margin-inline-end: 10px;
    padding: 5px;
    position: relative;
    width: 100%;
    ${scrollbar}
`;

export const dot = styled.span<{$color: string}>`
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #${props => props.$color};
    margin-inline-end: 8px;
`;

export const smallDot = styled(dot)`
    width: 8px;
    height: 8px;
    margin-block-end: unset;
    margin-inline-start: 8px;
`;
