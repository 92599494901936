import {useTranslation} from 'react-i18next';
import {formatNumber} from '../../../../../../library/numberFunction';
import * as css from './AmbassadorRow.css';
import useMobile from '../../../../../../hooks/useMobile';
import ConditionalWrapper from '../../../../../../component/conditionalWrapper/ConditionalWrapper';
import Arrow from '../../../../../../assets/icons/Arrow';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {teamMemberPhoto} from '../../../../../../assets/symbols/images/teamMemberPhoto';

function AmbassadorRow({lead}) {
    const {t} = useTranslation('realtime');
    const isMobile = useMobile();
    const eid = useSelector((s:any)=> s.event.eid);
    const target = Number.isFinite(parseInt(lead?.target)) ? lead?.target : 0;
    
    return (
        <Link to={`/e/${eid}/publishGivechak`}>
            <css.row>
                <css.profile $image={lead?.image || teamMemberPhoto}/>
                <css.value>
                    {lead?.title}
                </css.value>
                <css.value className={'small'}>
                    {(((lead?.totalAmount || 0)/((!target || target == 0) ? 1 : target)) * 100).toFixed(0)}%
                </css.value>
                <ConditionalWrapper
                    condition={isMobile}
                    wrapper={children => <css.wrapValue>{children}</css.wrapValue>}>
                    <css.value>
                        <css.dot $color={'FFEB00'}/> 
                        {t('reached')}: {formatNumber(lead?.totalAmount)}
                    </css.value>
                    <css.value>
                        <css.dot $color={'76D6E8'}/> 
                        {t('goal')}: {formatNumber(target)}
                    </css.value> 
                </ConditionalWrapper> 
                <css.wrapArrow>
                    <Arrow />
                </css.wrapArrow>        
            </css.row>
        </Link>
    );
}
export default AmbassadorRow;
