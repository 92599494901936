/* eslint-disable spellcheck/spell-checker */
import styled from 'styled-components';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const context = styled.div`
    padding: 30px;
    display: flex;
    border-radius: 5px;
    background-color: white;
    position: relative;
    ${mobileMedia}{
        display: block;
        padding-bottom: 10px;        
        padding-inline: 5px;
      
    }
`;
