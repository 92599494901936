import WindowTemplate from '../WindowTemplate/windowTemplate';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';
import {generalElement} from '../WindowTemplate/windowTemplate.css';

const grid = `
    "chart terminals tickets"
`;

const gridMoreStyle = `
    grid-template-columns: 1fr 3fr 2fr;
    gap: 10px;
    justify-items: start;
    ${mobileMedia}{
            ${generalElement}{
                height: 140px
            }
        } 
`;

const settings = [

    // Chart section
    {
        gridArea: 'chart',
        width: '100%',
        height: '80px'
    },
    // Terminals section
    {
        gridArea: 'terminals',
        width: '100%',
        height: '80px'
    },
    // Tickets section
    {
        gridArea: 'tickets',
        width: '100%',
        height: '80px'
    }
];

function EventDayTemplate() {
    return (
        <WindowTemplate
            gridMoreStyle={gridMoreStyle}
            gridAreas={grid}
            settings={settings}
        />
    );
}

export default EventDayTemplate;
