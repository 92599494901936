import styled from 'styled-components';
import {dFlexCenter, LIGHT_GRAY6, mobileMedia} from '../../../../../globalVariables/GlobalVariables';
import * as inputStyle from '../../../../../component/classes/input';
export const container = styled.div`
`;
export const filterWrapper = styled.div`
        position: absolute;
        top: 90px;
        inset-inline-end: 80px;
        ${mobileMedia} {
            top: 115px;
            inset-inline-end: 20px;
        }
    & .range-datePicker {
        align-items: start;
        flex-direction: column-reverse;
        display: flex;
        justify-content: center;
        margin-inline-start: 40px;
        & .itemContent {     
            height: 18px;
            width: fit-content;
            ${inputStyle.container} {
                padding: 0;
            }
            ${inputStyle.icon} {
                width: 0;
            }
            ${inputStyle.wrap} {
                width: fit-content;
            }
        }
    }
`;
export const subTitle = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    letter-spacing: 1.5px;
    font-weight: 400;
    font-size: 14px;
    gap: 9px;
`;

export const freeDate = styled.form`
    display: flex;
`;

export const messageIndex = styled.div`
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: ${LIGHT_GRAY6};
    ${dFlexCenter}
`;