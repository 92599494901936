const Camera = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="9"
        fill="none"
        viewBox="0 0 13 9"
    >
        <path
            fill="#000"
            d="M2.08.214a2.01 2.01 0 0 0-2.009 2.01v4.553a2.01 2.01 0 0 0 2.01 2.009H7.17a2.01 2.01 0 0 0 2.008-2.01v-.269l2.278 1.573a.938.938 0 0 0 1.47-.771v-5.62a.937.937 0 0 0-1.47-.771L9.178 2.49v-.268A2.01 2.01 0 0 0 7.17.214zm7.098 3.253 2.735-1.888a.134.134 0 0 1 .21.11v5.62a.134.134 0 0 1-.21.11L9.178 5.53zM.875 2.223A1.205 1.205 0 0 1 2.08 1.018h5.09a1.205 1.205 0 0 1 1.205 1.205v4.554A1.205 1.205 0 0 1 7.17 7.982H2.08A1.205 1.205 0 0 1 .875 6.777z"
        />
    </svg>
);

export default Camera;
