import createReducer from '../createReducer';

const initialState = {
    eid: null,
    active: null,
    type: null,
    name: null,
    userEventRole: null,
    category: null,
    currency: null,
    currencySymbol: null,
    description: null,
    fbImg: null,
    lang: null,
    location: null,
    locationMap:null,
    mailingJson: null,
    ev: null,
    hash: null,
    packageTitle: null,
    pid: null,
    ready: null,
    timeEnd: null,
    timeOpen: null,
    timeStart: null,
    timeZone: null,
    title: null,
    settings:null,
    budgetIndex:null,
    domain:null,
    halls:null,
    artists:[],
    map:{},
    hasBillingsTransaction:null,
    hasSuspendedTransaction:null,
    hasScheduledOrPriceFloatTicket:null,
    hasScheduledTicket:null,
    updated:false,
    aiImages:null,
    colors:[],
    maps:[],
    
    getEventSettingsXHR:null,
    getArtistXHR:null,
    sendMapXHR:null,
    getHallsXHR:null,
    getHallEventXHR:null,
    updateEventDetailsXHR:null,
    updateEventActiveXHR:null,
    createTopSectionImageXHR:null,
    createSalePageImage:{digitalArtXHR:null, realisticXHR:null, oilPaintingXHR:null, PicassoStyleXHR:null, threeDXHR:null, humorXHR:null},
    getAiImagesXHR:null,
    getDescriptionFromJPTXHR:null,
    getEventXHR:null,
    createDesignImageXHR:null,
    updateDesignPopXHR:null,
    getDescriptionFromGPTXHR:null,
    eventDuplicationXHR:null,
    addGooglePingXHR:null,
    getImageColorsXHR:null,
    updateMultipleAccountXHR:null,
    getAccountsXHR:null,
};

const reducer = createReducer('event', initialState);

export default reducer;