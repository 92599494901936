import * as css from './EventRealtime.css';
import {useSelector, shallowEqual} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {TType, eventTypes} from './eventTypes';
import useShow from '../../../hooks/useShow';
import FormButton from '../../../layout/eventMenu/formButtton/FormButtton';
import DetailItem from '../components/detailItem/DetailItem';
import Tooltip from '../../../component/tooltip/Tooltip';
import useMobile from '../../../hooks/useMobile';
import {LIVE_DOMAIN, TICK_DOMAIN} from '../../../config/config';
import setGlobalState from '../../../redux/setGlobalState';
import PopUpBubble from '../../../component/popUpBubble/PopUpBubble';
import {TURQUISE_BLUE, WHITE} from '../../../globalVariables/GlobalVariables';
import ButtonIcon from '../../../component/button/buttonIcon/buttonIcon';
import PublishPageBtn from '../../components/publishPageBtn/PublishPageBtn';
import EventActive from '../../components/eventActive/EventActive';
import QrCodePopup from '../../../layout/eventMenu/formButtton/QrCodePopup/QrCodePopup';
import getEventUrl from '../../../functions/getEventUrl';
import QRCode from '../../../assets/icons/QRCode';
import EventRolePermission from '../../components/rolePermission/EventRolePermission';
import {postEventReport} from '../../../redux/event/eventActions';
import {getDateFormat} from '../../../library/dates';
import {memo} from 'react';
import AccountPermission from '../../components/accountPermission/AccountPermission';

export const eventStatusComp={hub:PublishPageBtn, default:EventActive};
const QR_REF = '2t24';

function EventDetails(){
    const isMobile = useMobile();
    const event = useSelector(({event}:any) => event, shallowEqual);
    const StatusComp = eventStatusComp[event?.type]||eventStatusComp.default;
    const {isOpen: isBubbleOpen,open: openBubble,close:closeBubble,showClass:bubbleShowClass}=useShow();
    const {t} = useTranslation('realtime');
    const QRShow = useShow();
    const showReport = parseInt(getDateFormat(event.timeEnd, 'YYYY', event.timeZone)) >= 2024;
    
    const getUrl =()=>{
        const currentDomain = event.type==='hub'? LIVE_DOMAIN : TICK_DOMAIN;
        const urlPath =  getEventUrl(event.domain, event.landingPageUrl||event.name, event.type, currentDomain);
        return `${urlPath}?ref=${QR_REF}`;
    };

    return (
        <css.eventDetails data-cy="eventDetails">
            <css.buttonsTop>
                <AccountPermission permission="boxoffice" message={undefined}>
                    {!isMobile && <ButtonIcon BGColor={TURQUISE_BLUE} iconName={'cashRegister'} text={t('boxOffice')} onClick={() => setGlobalState('ui.newIframe.openBoxoffice', true)} />}
                </AccountPermission>
                {(showReport && !isMobile) && <EventRolePermission rolePermission={'manage event'} views={[]} ><ButtonIcon iconName={'document'} text={t('report')} onClick={()=>postEventReport(event.eid)} onMouseLeave={closeBubble} onMouseOver={openBubble}/></EventRolePermission>}
                {!!isMobile && <StatusComp />}
                <css.wrapButton>
                    {isBubbleOpen&&<PopUpBubble open={openBubble} showClass={bubbleShowClass} close={closeBubble} color={WHITE} isText={true} >
                        <css.reportPop >{t('reportExplanation')}</css.reportPop>
                    </PopUpBubble>}
                </css.wrapButton>
            </css.buttonsTop>
            <css.buttonsBottom>
                {!isMobile && <StatusComp />}
                <css.shareButtons>
                    {!isMobile && <Tooltip text={t('QRCode')} dataTitleIcon={undefined}>
                        <css.QRCodeButton onClick={() => QRShow.open()}>
                            <QRCode />
                        </css.QRCodeButton>
                    </Tooltip>}
                    <FormButton domain={event.type==='hub'? LIVE_DOMAIN : TICK_DOMAIN}/>
                    {(QRShow.isOpen && !isMobile) && <QrCodePopup {...QRShow} url={getUrl()} />}
                </css.shareButtons>
            </css.buttonsBottom>
            <css.type icon={eventTypes[event.type as TType]?.icon}>{t(eventTypes[event.type as TType]?.text)}</css.type>
            <css.eventName>{event.title}</css.eventName>
            <css.colors>{!!event.colors && event.colors.map((color:string, index) => <css.color key={index} color={color}/>)}</css.colors>
            <css.details>
                {eventTypes[event.type as TType].details.map((item, index)=> {
                    return <DetailItem key={index} title={item}/>;
                })}
            </css.details>
        </css.eventDetails>
    );
}

export default memo(EventDetails);