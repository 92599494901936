import React from 'react';
import {PieChart, Pie, Cell, Sector, ResponsiveContainer} from 'recharts';
import * as css from './GenericPieChart.css';
const GenericPieChart = ({
    data,
    dataKey,
    activeIndex,
    onMouse = {},
    hoverState = {},
    hover = {innerRadius: 57, outerRadius: 78},
    radius = {innerRadius: 60, outerRadius: 75},
    inlineRect
}) => {
    const {onEnter: onMouseEnter, onLeave: onMouseLeave} = onMouse;
    const renderActiveShape = ({cx, cy, startAngle, endAngle, fill, payload}) => {
        const isHovered = payload.index === activeIndex.activeIndex;
        const innerRadius = isHovered ? hover.innerRadius : radius.innerRadius;
        const outerRadius = isHovered ? hover.outerRadius : radius.outerRadius;

        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    style={{transition: 'all 0.8s ease-in-out'}}
                />
            </g>
        );
    };

    const handleMouseEnter = (e, index) => {
        activeIndex.setActiveIndex(index);
        onMouseEnter && onMouseEnter(index);
    };

    const handleMouseLeave = () => {
        activeIndex.setActiveIndex(null);
        onMouseLeave && onMouseLeave();
    };

    return (
        <>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        dataKey={dataKey}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        activeIndex={activeIndex.activeIndex}
                        activeShape={hoverState ? renderActiveShape : {}}
                        innerRadius={radius.innerRadius}
                        outerRadius={radius.outerRadius}
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={`#${data[index].color}`}
                                style={{
                                    transition: 'all 0.3s ease-in-out',
                                    opacity: activeIndex.activeIndex === null || activeIndex.activeIndex === index ? 1 : 0.6,
                                }}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
            <css.summeryText>
                {inlineRect}
            </css.summeryText>
        </>
    );
};

export default GenericPieChart;
