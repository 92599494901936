import styled from 'styled-components';
import {POPUP_MOBILE_PADDING_INLINE, POPUP_PADDING_INLINE} from '../../../pages/team/about/About.css';
import {getGridArea} from '../../../functions/cssFenctions';
import {BLACK, DARK_GRAY2, mobileMedia} from '../../../globalVariables/GlobalVariables';
import {wrapInput} from '../../../component/input/basicInput/BasicInput.css';
import * as input from '../../../component/classes/input';
import {icon} from '../../../component/input/inputList/InputList.css';
import zIndex from '../../../globalVariables/zIndex';
import {alignStretch, dFlex, dFlexColumn, dInlineFlex, flexWrap, justifyBetween, justifyEnd} from '../../../globalVariables/GlobalFlex';
import {popUpContent} from '../../../component/popUp/PopUp.css';

export const  popUp= styled.div`
    ${popUpContent}{
        overflow-y: auto;
        ${mobileMedia}{
            height: calc(100% - 180px);
        }
    }
`;
export const header = styled.div`
    ${dFlex}
    ${justifyBetween}
    ${alignStretch}
    width: calc(100% - 75px);
    height: 60px;
    padding-block: 20px;
    margin: -60px 50px auto 50px;
    z-index: ${zIndex.baseRaised};
`;
export const container=styled.div<{ formType?: string }>` 
    margin: 20px ${POPUP_PADDING_INLINE};
    display: grid;
    grid-template-areas: "title video"
                        "description description"
                        "distributions page";
    column-gap: 4%;
    row-gap: 15px;
    grid-template-columns: 48% 48%;
    ${getGridArea(['title','video','description','distributions','page'])}
    ${mobileMedia}{
            ${dFlexColumn}
            padding-inline:${POPUP_MOBILE_PADDING_INLINE};
            margin-inline: 0;
            >div{
                margin-top: 10px;
            }
    }
    .message {     
        ${dFlexColumn}
        height: 150px;
        ${wrapInput}, textarea, div[class*=container] {
            height: 100%;
        }
    }
    
    textarea {
        border: 0.5px solid ${BLACK};
        border-radius: 5px;
        height: 78px;
    }
    .picture {
        height: 290px
    }
`;

export const distributions = styled.div``;
export const howToDisplay = styled.div`
    grid-area: howToDisplay;
    ${dInlineFlex}
`;
export const roleSelectInput = styled(input.container)`
    padding-bottom: 0;
    margin-top: -8px;
    ${icon} {
        width: 0;
    }
`;
export const rolesConteiner = styled.div`
        position: absolute;
        inset-inline-end: 20px;
        top: 15px;
        ${dInlineFlex}
        max-width: 100%;
        ${flexWrap}
`;
export const bottom = styled.div`
    ${dFlex}
    ${justifyEnd}
    padding: 3%;
    border-top: 1px solid ${DARK_GRAY2};
`;