import * as css from './MemberBig.css';
import React from 'react';
import MemberOptions, {useEditAccountMember} from '../memberOption/MemberOption';
import {checkIfToday as isToday, tsToDate} from '../../../library/dates';
import {openWhatsApp} from '../../../library/whatsApp';
import {ts2time} from '../../../library/dateFunctions_old_to_be_deprecated';
import EventRolePermission from '../../components/rolePermission/EventRolePermission';
import MemberRoleBtn from '../memberRoleBtn/MemberRoleBtn';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {updateEventMemberStatus} from '../../../redux/team/teamActions';
import {ThemeProvider} from 'styled-components';
import {useHistory} from 'react-router-dom';
import ModeBtn from '../../../component/modeBtn/ModeBtn';

export const useOpenMemberPop =()=>{
    const history = useHistory();
    return (member)=>{
        if(member.uid&&!member.isPackageRole){
            let params = new URLSearchParams(location.search);
            [{key:'teamMemberPop',value:'teamMember'},...(member.uid?[{key:'uid',value:member.uid}]:[])].forEach(item=>params.set(item.key,item.value));
            history.push({
                search: params.toString()
            });

        }
    };
};

function MemberBig({member, rolePermission,rolePermissionState}) {
    const {t} = useTranslation('team');
    const tLayout= useTranslation('layout');
    const isUnauthenticated=member.status==='unauthenticated';
    const name = !isUnauthenticated?`${member.nameFirst} ${member.nameLast}`:member.email;
    let lastConnection = JSON.parse(member?.lastConnect||'{}');
    let formatted = isToday(lastConnection?.last)? t('todayAtTime')+' ' + ts2time(lastConnection?.last): tsToDate(lastConnection?.last, member.langloc, member?.timeZone, 'dateAndTime');
    const eid = useSelector(s=>s.event?.eid);
    const status=(!(member?.status&&member.status==='inactive'));
    const updateState = async () =>{
        await updateEventMemberStatus(member.uid,eid,status);
    };
    const editAccountMember=useEditAccountMember();

    const open=useOpenMemberPop();
    const onClick=member.isPackageRole?editAccountMember:open;
    return (
        <css.container  >
            <ThemeProvider theme={{isPackage:member.isPackageRole,isUnauthenticated}}> {/*|| member?.status==='unauthenticated'*/}
                <css.top image={member.picture} >
                    <css.blurImage  image={member.picture} />
                    <css.topDetails>
                        <css.packageIcon/>
                        {member&& <EventRolePermission  rolePermission={rolePermissionState} >
                            {(member?.status!=='unauthenticated')&&<ModeBtn dataTitle={tLayout.t(status?'active':'inactive')} className={'small'}  status={status}  click={updateState} />}
                        </EventRolePermission>}
                        <EventRolePermission  rolePermission={rolePermission} >
                            <MemberOptions deletePermission={rolePermissionState} left={-25} member={member}/>
                        </EventRolePermission>
                    </css.topDetails>
                </css.top>
                <css.image image={member.picture} {...(isUnauthenticated?{}:{onClick:()=>onClick(member)})}/>
                <css.name>{name}</css.name>
                <MemberRoleBtn member={member}/>
                <css.whatsUp className={member.status!=='unauthenticated'?'valid':''} onClick={()=>openWhatsApp(member.phone)} />
                <css.footer >{formatted}</css.footer>
            </ThemeProvider>
        </css.container>
    );
}

export default MemberBig;
