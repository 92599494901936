import {useTranslation} from 'react-i18next';
import * as css from './MapStep.css';
import AreaSVG from '../../../components/areaSVG/AreaSVG';
import {getHalls, updateEvent} from '../../../../redux/event/eventActions';
import InputListSearch from '../../../../component/input/inputList/InputListSearch';
import {useSelector} from 'react-redux';
import setGlobalState from '../../../../redux/setGlobalState';
import {useRef} from 'react';
import StepContext from '../stepContext/StepContext';
import StepRight from '../stepRight/StepRight';
import StepLeft from '../StepLeft/StepLeft';
import MyIframe from '../../../../layout/myIframe/MyIframe';
import {useField} from 'formik';
import {generateSVG} from '../../../../functions/generateSVG';
import Btn from '../../../../component/button/btn/Btn';
import ExplainMap from '../explainMap/ExplainMap';
import CheckboxFormik from '../../../../component/checkboxFormik/CheckboxFormik';
import useShow from '../../../../hooks/useShow';

export const onClickMapStep=async (changedValues)=>{
    if(changedValues.map){
        await updateEvent(changedValues);
    }
};
export const getTopMap=(value)=>{
    if(!value?.title)
        return '';
    return value.title;
};
function MapStep() {
    const {t} = useTranslation('newEvent');
    const {halls,getHallsXHR,maps,eid}=useSelector(s=>s.event);
    const iframeRef = useRef();
    const [{value},,helpers]=useField('map');
    const {open, close, isOpen} = useShow();

    const getMoreHalls = (fields) => {
        if(getHallsXHR === 'SUCCESS'){
            if(fields.offset === 0)
                setGlobalState('event.halls', []);
            getHalls(fields).then((res)=>{setGlobalState('event.halls', res?.halls || []);});
        }
    };
    const openDescription = (action) => {
        helpers.setValue({...value,action});
        open();
    };
    const onClick = (name, key) => {
        const selectedHall = halls[key];
        const updateHall = selectedHall.svg ? selectedHall : {...selectedHall, svg: selectedHall.areaCount > 1 ? generateSVG(selectedHall.design) : null};
        const hallsMap = new Map(maps.map(map => [map.eid, map]));
        hallsMap.delete(updateHall.eid);
        const newMaps = [{...updateHall}];
        for(const [, map] of hallsMap) {
            if(newMaps.length < maps.length) {
                newMaps.push({...map});
            }
        }
        setGlobalState('event.maps', newMaps);
        helpers.setValue({eid:updateHall.eid, title:updateHall.title,action:''});
        close();
    };
    return (
        <StepContext>
            {halls && (
                <css.wrap $hasPop={isOpen}>
                    <StepRight name="map">
                        <InputListSearch onChange={onClick} list={halls} text={t('mapText')}
                            onSearch={(value) => getMoreHalls({search: value, limit: 10, offset: 0})}
                            getMoreAction={() => getMoreHalls({search: '', limit: 10, offset: halls.length})}/>
                        <css.wrapSvg>
                            {maps.map((hall, index) => (
                                <AreaSVG key={index} title={hall.title} seatCount={hall.seatCount} svg={hall.svg} eid={hall.eid} />
                            ))}
                        </css.wrapSvg>
                    </StepRight>
                    {(value.eid && !isOpen) ? <css.wrapContent>
                        <MyIframe iframeRef={iframeRef} display={value.eid && !isOpen}
                            src={`/e/${eid}/tickets/seats?auditorium=${value.eid}`} />
                    </css.wrapContent> : <></>}
                    {isOpen && <ExplainMap name={'map'} close={close} action={value.action} title={value.action === 'newMap' ? t('explainOnYourMap') : t('explainOnYourErrorMap')} />}
                    <StepLeft name="map">
                        {(value.eid && !isOpen) &&
                            <css.wrapButtons>
                                <Btn className="white" onClick={()=>openDescription('mapError')}>{t('mapError')}</Btn>
                                <CheckboxFormik name={'map.approveMap'} text={t('approveMapText')} color={'black'}/>
                            </css.wrapButtons>}
                        {!isOpen && !value.eid && <Btn className="white" onClick={()=>openDescription('newMap')}>{t('newMap')}</Btn>}
                    </StepLeft>
                </css.wrap>)}
        </StepContext>);
}
export default MapStep;
