import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {getMinutesDifference, getTs, subtractTs} from '../../../../library/dates';
import * as css from './LastUpdateTimer.css';
import {useTranslation} from 'react-i18next';
import {getEventDay, getEventStats, getGivechakTargets, getRealtime, getSoldTickets, givechakMultiplier} from '../../../../redux/realtime/realtimeActions';
import {getEventTickets} from '../../../../redux/tickets/ticketsActions';
 
const LastUpdateTimer = () => {
    const lastUpdate = useSelector((s:any) => (s.realtime.event.lastUpdate));
    const [ts, setTs] = useState(getTs());
    const lastMinutes = getMinutesDifference(lastUpdate, ts);
    const {t} = useTranslation('realtime');
    const event = useSelector((s:any) => ({
        eid: s.event.eid,
        type: s.event.type,
        timeStart: s.event.timeStart
    }));

    const getRealtimeData = () => {
        if(event.eid){
            getRealtime().then();
            getEventStats(event.eid).then();
            showEventDay && getEventDay(event.eid).then();
            if(event.type === 'givechak'){
                getGivechakTargets(event.eid).then();
                givechakMultiplier(event.eid).then();
            } else {
                getSoldTickets(event.eid).then();
                getEventTickets().then();
            }
        }
    };  
    const showEventDay = useMemo(() => {
        const weekBeforeTs = subtractTs(event.timeStart, 4, 'day');
        const nowTs = getTs();
        return nowTs >= weekBeforeTs && (event.type === 'ticket' || event.type === 'area') && event.timeStart;
    }, [event.timeStart]);
    
    useEffect(() => {
        const interval = setInterval(() => {
            setTs(getTs());
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    return <css.lastUpdate>
        {lastMinutes > 0 ? <>{t('lastUpdate', {minutes: lastMinutes})}<css.updateButton onClick={() => getRealtimeData()}> 
            {t('updateData')}
        </css.updateButton></> : t('updateNow')}
    </css.lastUpdate>;
};

export default LastUpdateTimer;