import styled from 'styled-components';
import {desktopMedia, dFlex, LIGHT_BLUE4, mobileMedia, tabletMedia} from '../../../../../../globalVariables/GlobalVariables';
import {section} from '../../EventDay.css';
import {alignCenter, flexColumn, justifyBetween} from '../../../../../../globalVariables/GlobalFlex';

export const rightSectionContent = styled.div`
    ${section}
    ${dFlex};
    ${p => p.theme.$compact ? `
        height: 100%;
        width: 100%;
        background-color: transparent;
        ${alignCenter};
        ${mobileMedia}{
            height: max-content;
            ${justifyBetween}
            margin-block-end: 10px;
        }
    `: `
        grid-area: chart;
        height: 314px;
        ${mobileMedia}{
            height: max-content;
            ${flexColumn};
            gap: 10px;
        }
    `};
`;

export const ticketsInfo = styled.div`
    ${dFlex};
    ${desktopMedia}{
        flex-direction: ${p => p.theme.$compact ?  'row' :'column'};
    }
    justify-content: space-around;
    gap: ${p => p.theme.$compact ? '20px' : '10px'};
    ${p => p.theme.$compact && `
        width: 100%;
    `}
    ${mobileMedia}{
        ${justifyBetween}
        width: ${p => p.theme.$compact ?  '100' :'70'}%;
        position: relative;
        inset-inline-start: ${p => p.theme.$compact ?  '0' :'15%'};
        ${p => p.theme.$compact && `
            padding-inline: 15px;
            gap: 50px;
        `}
    }
`;

export const divider = styled.div`
    width: 1.5px;
    height: 70%;
    position: relative;
    inset-block-start: 15%;
    margin-inline: 30px;
    background-color: ${LIGHT_BLUE4};
    ${mobileMedia}{
        height: 1.5px;
        width: 70%;
        inset-block-start: unset;
        margin-inline: 0;
        inset-inline-start: 15%;
    }
    ${tabletMedia}{
        ${p=> p.theme.$compact && `
            display: none;
        `}
    }
`;
