/* eslint-disable spellcheck/spell-checker */
import styled from 'styled-components';
import {dFlexCenter, POPUP_DURATION, mobileMedia} from '../../../globalVariables/GlobalVariables';
import zIndex from '../../../globalVariables/zIndex';

export const popUpBody = styled.div`
    ${p=>p.height!=='max-content'?'overflow: hidden;':''}
    position: absolute;
    background: #FFFFFF;
    width: 800px;
    max-height: calc(100% - 100px);
    height:${p=>p.height};
    min-height: 400px;
    box-shadow: 0 5px 15px #00000052;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    /* transition: all ${POPUP_DURATION}ms , height 300ms ; */
    /* transition: all 500ms cubic-bezier(0.165, -0.325, 0.280, 1.340) , height 300ms;
    transition-timing-function: cubic-bezier(0.165, -0.325, 0.280, 1.340); */
    transition: all 500ms cubic-bezier(0.380, -0.450, 0.430, 1.510);
    transition-timing-function: cubic-bezier(0.380, -0.450, 0.430, 1.510); 
    opacity: 0;
    margin-top:100vh;
    &.disabled{
        pointer-events: none;
    }
    &.show{ 
          opacity:1 ;
          margin-top: 0;
        }
    ${mobileMedia}{
        position:absolute ;
        width: 100%;
        bottom: 0;  
        transition: all 400ms ;
        max-height: 0;
        flex-direction: column;
        border-radius: 8px;
        box-shadow: 0 5px 15px #00000052;
        background: #FFFFFF;
      &.show{ 
            height:${p=>p.height} ;
            max-height: 90%;
        }
       
    } 
    ${p=>p.$cssStyle}
`;

export const popUpWrapper = styled.div`
        ${dFlexCenter};
        padding-block: 50px;
        position: fixed;
        bottom:0 ;
        left: 0;
        width: 100%;
        height:100%;
        /* background: rgb(65,62,62); */
        /* background: linear-gradient(0deg, rgba(65,62,62,1) 0%, rgba(238,237,235,1) 100%);    */
        background-color: rgba(0,0,0,.75);
        z-index: ${zIndex.basicModal};
        backdrop-filter: blur(2px);
        transition: all ${POPUP_DURATION}ms;
        opacity: 0;
        &.show{ 
          opacity:1 ;
        }
`;

export const popOnPop = styled.div`
   
`;

