import {useTranslation} from 'react-i18next';
import TicketDetails from '../ticketDetails/TicketDetails';
import PaymentSettings from '../paymentSettings/PaymentSettings';
import Extensions from '../extensions/Extensions';
import GlobalPopupContent from '../../components/globalPopupContent/GlobalPopupContent';
import useTicket from '../../../../../hooks/useTicket';
import {useRef} from 'react';
import FormContext from '../../../../components/formContext/FormContext';
import * as Yup from 'yup';
import {useSelector} from 'react-redux';

function TicketsPopupContent({tid, close,showClass}) {
    const {t} = useTranslation('ticket');
    const type = useSelector(s=>s.event.type);
    const ticket = useSelector(s => s.tickets?.currentTicket);
    const tickets = useSelector(s => s.tickets?.tickets);
    const {save,validationSchema,generalInitialValues,mergedInitialValues} = useTicket();
    let  {details, extras, payments,  customFields}=generalInitialValues;

    if(type==='area')
        details= {...details, amount: ticket?.amount ? ticket?.amount : 0};
    else {
        details= {...details, amount: ticket?.amount ? ticket?.amount : 100};
        if(!payments.payments.paymentsMethod){//case ticket has no paymentsMethod from itself
            const element = tickets?.find(t => t.payments?.paymentsMethod);
            payments={...payments, payments:{...payments.payments, paymentsMethod:(element?element.payments.paymentsMethod:'monthly'), numberPayments:2}};
        } 
    }
    const ticketInitialValues = mergedInitialValues({details,extras,payments,customFields});

    const validationAmount =Yup.object().shape({
        amount: Yup.number().required().test('minAmount',function (value) {
            const currentTicket = tickets?.find(t=>t.tid===ticket?.tid);
            if(value<currentTicket?.sold)
                return false;
            return true;}), 
    });

    const formRef = useRef();

    const onSubmitForm = () => {
        if(formRef.current) {
            formRef.current.handleSubmit();
        }
    };
    let tabsList = [
        {type: 'courseDetails', stageText: t('ticketDetails'),textContinue:t('ticketExtension'), Comp: TicketDetails, onSubmitForm},
        ...( type==='area' ? []:
            [ {type: 'extensions', stageText: t('extensions'),textContinue:t('paymentSettings'), Comp: Extensions, onSubmitForm}]),
        {type: 'paymentSettings', stageText: t('advancedSettings'), Comp: PaymentSettings, onSubmitForm}
    ];

    return (
        <FormContext
            innerRef={formRef}
            initialValues={ticketInitialValues}
            validationSchema={validationSchema.concat(validationAmount)}
            enableReinitialize
            onSubmit={save}>
            <GlobalPopupContent showClass={showClass} header={{image:'ticketPopIcon', title: t('ticket'),type:'ticket'}} onSubmitForm={onSubmitForm} tabsList={tabsList} tid={tid} close={close}/>
        </FormContext>
    );
}

export default TicketsPopupContent;
