import styled from 'styled-components';
import {BLACK, dFlex, dFlexCenter, WHITE} from '../../../../../../globalVariables/GlobalVariables';
import realtimeFonts from '../../../../fonts';

export const item = styled.div`
    ${dFlex}
    height: 58px;
`;
export const color = styled.div<{color: string}>`
    width: 52px;
    background-color: #${p => p.color};
    position: relative;
    ${dFlexCenter}
    border-radius: 5px;
`;
export const right = styled.div`
    width: 100%;
    letter-spacing: 0.7px;
    padding: 5px;
    padding-inline-start: 18px;
    background-color: ${WHITE};
    border-radius:${p=>p.theme.rtl? '5px 0 0 5px' : '0 5px 5px 0'};
    color: ${BLACK};
    ${dFlex}
    flex-direction: column;
    justify-content: space-around;
`;
export const ticketTitle = styled.div`
    ${realtimeFonts.textBold}
    color: ${BLACK};
    line-height: 11.158px;
`;

export const details = styled.div`
    ${dFlex};
`;
const ticketsText = `
    color: ${BLACK};
    line-height: 19px;
`;
export const text = styled.div`
    ${ticketsText}
    margin-inline: 10px;
`;

export const bold = styled.div`
    ${realtimeFonts.textBold}
`;

export const value = styled.div`
    ${realtimeFonts.text}
    font-weight: 200;
`;
