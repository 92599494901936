import {useRef, useState} from 'react';
import useShow from '../../../../hooks/useShow';
import {useTranslation} from 'react-i18next';
import MarketingPromotionSettings from './marketingPromotionSettings/marketingPromotionSettings';
import MarketingPromotionInsights from './marketingPromotionInsights/marketingPromotionInsights';
import MasterPixel from '../../../../component/masterPixel/MasterPixel';
import {useSelector} from 'react-redux';

export default function useMarketingPromotion(){   
    const [page, setPage] = useState('');
    const marketingPromotionsShow = useShow();
    const packageName = useSelector((state: any) => state.account?.title);
    const {t} = useTranslation('realtime');

    const promotionList = [
        // {
        //     title: t('settings'),
        //     text: 'settings',
        // },
        {
            title: t('ads'),
            text: 'ads',
            onClick: (item) => onListClick(item)
        },
        {
            title: t('masterPixel'),
            text: 'masterPixel',
            onClick: (item) => onListClick(item)
        },
        {
            title: t('insights'),
            text: 'insights',
            onClick: (item) => onListClick(item)
        }
    ];
    const formRef=useRef();
    const body = {
        'settings':<MarketingPromotionSettings formRef={formRef}/>,
        'ads': <MarketingPromotionSettings formRef={formRef}/>,
        'insights': <MarketingPromotionInsights formRef={formRef}/>,
        'masterPixel': <MasterPixel/>
    };
    const onListClick = (item:any)=>{
        setPage(item.text);
        marketingPromotionsShow.open();
    };

    return {promotionList, onListClick, body, packageName, page, marketingPromotionsShow};
}