import styled from 'styled-components';
import {wrapper} from '../../component/anotherOne/AnotherOne.css';
import {wrapper as container}  from '../../component/anotherOneCircle/AnotherOneCircle.css';
import {mobileMedia, scrollbar} from '../../globalVariables/GlobalVariables';
export const grid=styled.div`
    display: grid;
    row-gap: 50px;
    grid-template-columns: repeat(5,20%);
    max-width: 1400px;
    &.small{ 
      grid-template-columns: repeat(7,14.25%);
      row-gap: 40px;
    }
    height:100%;
    padding-top: 20px;
    padding-inline:30px ;
    margin-inline:-30px ;
    padding-bottom:30px ;
    ${scrollbar};
    ${mobileMedia}{
      height:calc(100% - 50px);
      &.small,&:not(.small) {
        grid-template-columns: repeat(2, 50%);
        row-gap: 25px;
        justify-items: center;
      }
    }  
`;
export const preview=styled.div`
  margin-inline-end:15px ;
  width :15px;
  height: 19px;
  border: 2px solid #2C2C2C;
  border-radius: 3px;
  transition: all 200ms;
  position: relative;
  cursor: pointer;
  &.isSmall{
    height: 15px;
    border-radius: 50%;
  }
  &::before{
    content:'';
    position: absolute;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    top:4px;
    background: #000000;
    left: calc(50% - 1.5px);  }
`;
export const containerBorder=styled.div`
  border-top:1px solid #CCCCCC;
  padding-top: 20px;
  padding-inline-end: 1.3vw; 
  height:310px ;
  width: 100%;
  position:relative;
  ${wrapper}{
    width: 100%;
    height: 100%;
  }
  ${mobileMedia}{
    ${p=>!p.isFirstLine && 'border-top:0'};
    max-width: 230px;
  }
  &.small{
     height:210px ;
    :nth-child(7n){
      padding-inline-end: 0;
      width: calc(100% - 20px);
    }
    ${container}{
      margin: 0 auto;
    }  
    ${mobileMedia}{
      display: flex;
      justify-content: center;
      height:190px ;
      :nth-child(7n){        
        width:100%;
        padding-inline-end:7px ;
      }
    }   
}
  &.first {
    ${mobileMedia}{
    grid-column-start: 1;
    }
    &::before {
      content: '${p => p.title}';
      position: absolute;
      width: 50px;
      height: 20px;
      top: -20px;
      inset-inline-start: 0;

    }
  }
  &:not(.small):nth-child(5n){ 
    padding-inline-end: 0;
    width: calc(100% - 20px);
    ${mobileMedia}{
      width: 100%;
      padding-inline-end:7px ;
    }
    }  
  &.last{
    padding-inline-end: 0;
    width: calc(100% - 20px);
    margin-inline-end:1.3vw;
    ${mobileMedia}{
      width:100%;
      margin-inline-end:0;

    }
  }
  ${mobileMedia}{
    width:100%;
    padding-inline-end: 0;
    margin-inline-end:0;
    :nth-child(2n-1){
    padding-inline-end:7px ;
  }
    :nth-child(2n){
    padding-inline-start:7px ;
  }
  }
`;
