import React from 'react';
import {GRAY3} from '../../../../../../globalVariables/GlobalVariables';

function StopwatchIcon({color = GRAY3, width = 10, height = 12}: { color?: string, width?:number, height?: number }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10 12" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.00046 2.80347C2.85496 2.80347 1.1123 4.55248 1.1123 6.71418C1.1123 8.87588 2.85496 10.6249 5.00046 10.6249C5.49245 10.6249 5.96229 10.5332 6.39458 10.3661C6.58776 10.2914 6.80489 10.3875 6.87955 10.5807C6.95421 10.7739 6.85812 10.991 6.66494 11.0657C6.14782 11.2655 5.58642 11.3749 5.00046 11.3749C2.43702 11.3749 0.362305 9.28635 0.362305 6.71418C0.362305 4.14201 2.43702 2.05347 5.00046 2.05347C7.56391 2.05347 9.63862 4.14201 9.63862 6.71418C9.63862 7.71421 9.32476 8.64184 8.79044 9.40148C8.67129 9.57087 8.43737 9.61161 8.26797 9.49245C8.09858 9.3733 8.05784 9.13939 8.17699 8.96999C8.62519 8.33279 8.88862 7.55505 8.88862 6.71418C8.88862 4.55248 7.14597 2.80347 5.00046 2.80347Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.81464 1.68653C7.96147 1.54047 8.19891 1.5411 8.34497 1.68793L9.76602 3.1165C9.91208 3.26333 9.91145 3.50077 9.76462 3.64683C9.61779 3.79289 9.38035 3.79226 9.23429 3.64543L7.81324 2.21686C7.66718 2.07002 7.6678 1.83259 7.81464 1.68653Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M2.18536 1.68653C2.03853 1.54047 1.80109 1.5411 1.65503 1.68793L0.233982 3.1165C0.0879224 3.26333 0.0885489 3.50077 0.235381 3.64683C0.382214 3.79289 0.61965 3.79226 0.76571 3.64543L2.18676 2.21686C2.33282 2.07002 2.3322 1.83259 2.18536 1.68653Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M5 0.863037C5.20711 0.863037 5.375 1.03093 5.375 1.23804V2.42851C5.375 2.63562 5.20711 2.80351 5 2.80351C4.79289 2.80351 4.625 2.63562 4.625 2.42851V1.23804C4.625 1.03093 4.79289 0.863037 5 0.863037Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.67773 1C3.67773 0.792893 3.84563 0.625 4.05273 0.625H5.94747C6.15458 0.625 6.32247 0.792893 6.32247 1C6.32247 1.20711 6.15458 1.375 5.94747 1.375H4.05273C3.84563 1.375 3.67773 1.20711 3.67773 1Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M5 3.24414C5.20711 3.24414 5.375 3.41203 5.375 3.61914V6.95247C5.375 7.15958 5.20711 7.32747 5 7.32747C4.79289 7.32747 4.625 7.15958 4.625 6.95247V3.61914C4.625 3.41203 4.79289 3.24414 5 3.24414Z" fill={color}/>
        </svg>
    );
}

export default StopwatchIcon;
