import React, {useContext} from 'react';
import * as css from './Operations.css';
import Btn from '../button/btn/Btn';
import BtnCircleIcon from '../button/btnCircleIcon/BtnCircleIcon';
import OptionsList from '../../pages/components/optionsList/OptionsList';
import {PageWrapperContext} from '../../pages/components/pageWrapper/PageWrapper';

function Operations() {

    const {operations={}} = useContext(PageWrapperContext);

    return (
        <css.operations $cssStyle={operations.cssStyle||''} >
            {operations.children?operations.children:<></>}

            {operations.icons?.length&&<css.icons  className={operations.buttons?.length?'border':''}>{
                operations.icons.map((item,index)=>
                    <BtnCircleIcon key={index} onClick={item.onClick} icon={item.icon} color={item.color} tooltipText={item.tooltipText}/>)}
            </css.icons>}
            {operations.buttons?.length&&<css.buttons > {operations.buttons?.map((item,index)=>{
                if(item.Comp){
                    const Comp = item.Comp;
                    return <Comp key={index} {...item?.props}/>;
                } else {
                    return  <Btn key={index} className={index%2!==0&&'gray'} onClick={item.onClick}>
                        {item.children?item.children: !item.withoutPlus&& <css.plus>{'+'}</css.plus>}
                        {item.text}
                    </Btn>;}
            })}
            {operations.optionsList&&
                <OptionsList/>
            }
            </css.buttons>}
        </css.operations>
    );
}
export default Operations;
