const Clicks = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
    >
        <g clipPath="url(#clip0_4692_20949)">
            <path
                fill="#000"
                d="M13.633 0h-.366L6.89 2.126.44 4.325s-.44.44-.367.733c0 .146.22.293.367.366l5.864 2.419 2.418 5.79s.513.44.807.294c.146 0 .293-.22.366-.367l2.126-6.377L14 .806s0-.66-.367-.733z"
            />
            <path
                fill="#EFF4F5"
                d="m10.702 6.817-1.613 4.91-1.832-4.544s-.147-.22-.294-.293L2.42 5.058l4.91-1.613 5.278-1.759-1.759 5.278z"
            />
        </g>
        <defs>
            <clipPath id="clip0_4692_20949">
                <path fill="#fff" d="M0 0h14v14H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default Clicks;
