import {useTranslation} from 'react-i18next';
import Accordion from '../../../../package/componnent/accordion/Accordion';
import * as css from './marketingPromotionInsights.css';
import InsightsCard from './insightsCard/insightsCard';
import {openEye, symbolEventDark} from '../../../../../assets/symbols/symbols/Symbols.css';
import ButtonIcon from '../../../../../component/button/buttonIcon/buttonIcon';
import List from '../../../../../component/list/List';
import useShow from '../../../../../hooks/useShow';
import {useEffect, useMemo, useState} from 'react';
import Clicks from '../../../../../assets/icons/clicks';
import InsightsCardData from './insightsCardData/insightsCardData';
import {getPromotionMessageStats, getPromotionsStatsByMessage} from '../../../../../redux/event/eventActions';
import {useSelector} from 'react-redux';
import {formatDecimal, formatNumber} from '../../../../../library/numberFunction';
import {getStartAndEndOfTimes} from '../../../../../library/dates';
import PickDateRange from './PickDateRange';

function MarketingPromotionInsights({formRef}){
    const {t} = useTranslation('promotionMessage');
    const filterShow = useShow();
    const [filterBy, setFilterBy] = useState({title:'', getRange:null});
    const messagesStats = useSelector((s: any) => s.event.promotionMessageStats);
    const statsByMessage = useSelector((s: any) => s.event.promotionsStatsByMessage);

    const mainStats = messagesStats?[{number:formatNumber(messagesStats.events), text:'eventsText', icon: symbolEventDark.event},
        {number:formatNumber(messagesStats.views), text: 'adViews', icon:openEye},
        {number:formatNumber(messagesStats.clicks), text:'clicksText', icon:Clicks} ,
        {number:formatNumber(messagesStats.bounceRate), text:'bounceRate'} ]:[];

    const secondStats = messagesStats?
        [{number: messagesStats.inquiries, text: 'inquiries'}, 
            {number: formatNumber(messagesStats.activeCampaigns), text:'activeCampaigns'},
            {number: messagesStats.salesRevenue?(formatDecimal(messagesStats.salesRevenue) + '₪'):null, text: 'salesRevenue'},
            {number: formatNumber(messagesStats.orders), text: 'transactions'},
            {number: formatNumber(messagesStats.tickets), text: 'tickets'}]:[];

    const filterList = useMemo(()=> [
        {title: t('all'), text: 'all', getRange: ()=> ({})},
        {title: t('today'), text: 'today', getRange: ()=> (getStartAndEndOfTimes('day'))},
        {title: t('thisMonth'), text: 'thisMonth', getRange: ()=> (getStartAndEndOfTimes('month'))},
        {title: t('quarter'), text: 'quarter', getRange: ()=> (getStartAndEndOfTimes('quarter'))},
        {title: t('yearly'), text: 'yearly', getRange: ()=> (getStartAndEndOfTimes('year'))},
        {title: t('freeDate'), text: 'freeDate', classes: 'range-datePicker', getRange: ()=> (getStartAndEndOfTimes('day')), rightChildren: <PickDateRange close={filterShow.close} />, list:[]}
    ],[filterShow.isOpen]);

    useEffect(()=>{
        const range = filterBy?.getRange? filterBy.getRange() :null;
        getPromotionsStatsByMessage(range).then();
        getPromotionMessageStats(range).then();
    },[filterBy]);

    const onListClick = (item) => {
        setFilterBy(item);
    };
    const formatIndex=(index: number) =>`${index<10?'0':''}${index}`;

    if(!messagesStats){
        return (<></>);
    }
    const filterByDates = ()=>{};
    return (<css.container> 
        <Accordion title={t('marketingSummary')} dataCyTitle={undefined} forwardRef={formRef}>
            <css.subTitle> {t('generalSummery')}</css.subTitle>
            <css.filterWrapper>
                <ButtonIcon onClick={filterShow.isOpen? filterShow.close: filterShow.open} iconName='filter' text={filterBy?.title || t('filter')} />
                {filterShow.isOpen && <List arrowIntoClick={filterByDates} left={0} onClick={onListClick} {...filterShow} list={filterList} />}
            </css.filterWrapper>
            <InsightsCard>
                <InsightsCardData 
                    mainStats={mainStats}
                    secondStats={secondStats}/>
            </InsightsCard>     
        </Accordion>

        <Accordion title={t('messageStats')} dataCyTitle={undefined} forwardRef={null}>
            {statsByMessage && statsByMessage[0] && statsByMessage?.map((stats, i)=>{
                const mainStats = stats?[{number:formatNumber(stats.events), text:'eventsText', icon: symbolEventDark.event},
                    {number:formatNumber(stats.viewAmount), text: 'adViews', icon:openEye},
                    {number:formatNumber(stats.sendClickAmount), text:'clicksText', icon:Clicks},
                ]:[];
            
                return (<>
                    <css.subTitle>
                        <css.messageIndex>{formatIndex(i+1)}</css.messageIndex>
                        {`${t(`${stats.type}TitleFirst`)} ${t(`${stats.type}TitleSecond`)}`}
                    </css.subTitle>
                    <InsightsCard>
                        <InsightsCardData 
                            mainStats={mainStats}
                            secondStats={[]}/>
                    </InsightsCard>
                </> );})}
        </Accordion>
    </css.container>);
}
export default MarketingPromotionInsights;
