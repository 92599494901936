import * as css from './TicketsSection.css';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {TicketValidations} from '../ticketValidations/TicketValidations';

export const TicketsSection = () => {
    const {t} = useTranslation('realtime');
    const tickets = useSelector((s:any) => s.realtime?.eventDay?.tickets || []);
    
    return (
        <css.wrapper className='free-color'>
            <css.title>{t('ticket')}</css.title>
            <css.list>
                {tickets.map((ticket:any) => (
                    <TicketValidations key={ticket.tid} ticket={ticket} />
                ))}
            </css.list>
        </css.wrapper>
    );
};
