import {useTranslation} from 'react-i18next';
import List from '../../../../../component/list/List';
import useShow from '../../../../../hooks/useShow';
import * as css from './marketingPromotionsMenu.css';
import Tooltip from '../../../../../component/tooltip/Tooltip';
import MasterPermission from '../../../../components/rolePermission/MasterPermission';
import SideFormPopup from '../../../../../component/sideFormPopup/sideFormPopup';
import MarketingMessage from '../../../../../assets/icons/MarketingMessage';
import useMarketingPromotion from '../useMarketingPromotion';

function MarketingPromotionsMenu() {
    const {t} = useTranslation('realtime');
    const menuShow = useShow();
    const {promotionList, onListClick, page, body, packageName, marketingPromotionsShow} = useMarketingPromotion();

    return (
        <MasterPermission>
            <div>
                {marketingPromotionsShow.isOpen && <SideFormPopup menuClicked={onListClick} menuList={promotionList} t={t} bodyComponents={body} {...marketingPromotionsShow}  popupName={packageName} page={page} isShowOptions={false}/>}
                <Tooltip text={t('promotionMessage')} dataTitleIcon={undefined}>
                    <css.openPromotionMessagePopup onClick={()=>menuShow.open()} data-cy="openPromotionMessagePopup" >           
                        <MarketingMessage />
                    </css.openPromotionMessagePopup>
                </Tooltip>
                {menuShow.isOpen && <List left={0} right={0} onClick={onListClick} {...menuShow} close={menuShow.close} list={promotionList} width={'200px'} />}
            </div>
        </MasterPermission>
    );
}

export default MarketingPromotionsMenu;