import NewEventForm from '../components/newEventForm/NewEventForm';
import * as Yup from 'yup';
import {newEventInitialValues,newEventSteps, newEventValidationSchema} from '../globalSteps';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useEffect} from 'react';
import {getBudgetItems} from '../../../redux/account/accountActions';
import {getCategories} from '../../../redux/categories/categoriesActions';

function NewTicketsEvent() {
    const event=useSelector(s=>s.event);
    const isScheduled=useSelector(s=>s.ui.event.isScheduled);
    const budgetItems = useSelector(s=>s.account.budgetItems);
    const categories = useSelector(s=>s.categories.eventCategories);
    const {t} = useTranslation(['global', 'newEvent', 'errors', 'layout']);
    const {isOpenLocaleStep} = useSelector(s=>s.ui.event);

    const eventSteps = [
        newEventSteps.title,
        newEventSteps.category(t),
        newEventSteps.location,
        ...(isScheduled && event?.ready !== 5 ? [] : [newEventSteps.date]),
        ...(isOpenLocaleStep ? [newEventSteps.locale] : []),
        newEventSteps.artists,
        newEventSteps.description,
    ];
    const courseSteps = [
        newEventSteps.title,
        newEventSteps.location,
        newEventSteps.description,
    ];
    const steps = [
        ...(event.type !== 'course' ? eventSteps : courseSteps),
        ...(budgetItems.length ? [newEventSteps.budgetIndex] : []),
    ];

    useEffect(()=>{
        getBudgetItems();
        if(categories.length === 0){
            getCategories();
        }
    },[]);

    const initialValues = {
        ...newEventInitialValues(event),
    };

    return (
        <NewEventForm
            initialValues={initialValues}
            validationSchema={Yup.object().shape(newEventValidationSchema(t))}
            steps={steps}
        />
    );
}

export default NewTicketsEvent;
