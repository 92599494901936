import {useTranslation} from 'react-i18next';
import * as css from '../EventDay.css';
import Btn from '../../../../../component/button/btn/Btn';
import {ANDROID_APP, IOS_APP} from '../../../../../config/config';
import {androidIcon, iosIcon} from '../../../../../assets/symbols/symbols/appsIcons';
import {op, share} from '../../../../../assets/symbols/symbols/Symbols.css';
import DownloadIcon from '../components/icons/DownloadIcon';
import List from '../../../../../component/list/List';
import {useHistory} from 'react-router-dom';
import useShow from '../../../../../hooks/useShow';
import useMobile from '../../../../../hooks/useMobile';
import CloseStateIcon from './icons/CloseStateIcon';

interface EventDayHeaderProps {
    isDarkMode: boolean;
    event: any;
    getColor: (isDark: boolean) => string;
    closeEventDay: () => void;
}

export const EventDayHeader = ({isDarkMode, event, getColor, closeEventDay}: EventDayHeaderProps) => {
    const {t} = useTranslation('realtime');
    const showDownloadList = useShow();
    const showOpList = useShow();
    const history = useHistory();
    const isMobile = useMobile();

    const downloadList = [
        {
            title: t('androidApp'),
            icon: androidIcon,
            onClick: () => window.open(ANDROID_APP, '_blank')
        },
        {
            title: t('iosApp'),
            icon: iosIcon,
            onClick: () => window.open(IOS_APP, '_blank')
        }
    ];

    const eventPath = event.type === 'course' ? 'courses' : 'tickets';
    const opList = [
        {
            title: t('smsEnterContent'),
            onClick: () => history.push(`/e/${event.eid}/${eventPath}/branding/sms`)
        }
    ];

    return (
        <>
            <css.header>
                {!isMobile && <css.title>{t('eventDay')}</css.title>}
                <css.wrapButtons>
                    <css.buttons className='free-color'>
                        <Btn className={'yellow'} onClick={showDownloadList.open} disabled={false}>
                            <css.icon>
                                <DownloadIcon />
                            </css.icon>
                            {t('downloadApp')}
                        </Btn>
                        <Btn className={'yellow'} onClick={undefined} disabled={true}>
                            <css.icon image={share} />
                            {t('share')}
                        </Btn>
                    </css.buttons>
                    <css.buttons>
                        <css.button onClick={closeEventDay}>
                            <CloseStateIcon color={getColor(isDarkMode)}/>
                        </css.button>
                        <css.button image={op} onClick={showOpList.open} />
                    </css.buttons>
                </css.wrapButtons>
                {!!showDownloadList.isOpen && (
                    <List
                        list={downloadList}
                        showClass="show"
                        close={showDownloadList.close}
                        width="180px"
                        top={52}
                        left={'130px'}
                        classes={'free-color'}
                    />
                )}
                {!!showOpList.isOpen && (
                    <List
                        list={opList}
                        showClass="show"
                        close={showOpList.close}
                        width="180px"
                        top={52}
                        left={'20px'}
                        classes={'free-color'}
                    />
                )}
            </css.header>
            {!!isMobile && <css.title>{t('eventDay')}</css.title>}
        </>
    );
};
