import * as css from './ValidationPieChart.css';
import {useTranslation} from 'react-i18next';
import {useContext, useState} from 'react';
import GenericPieChart from '../../../../genericPieChart/GenericPieChart';
import {formatNumber} from '../../../../../../../library/numberFunction';
import {CompactEventDayContext} from '../../CloseEventDay/CloseEventDay';

interface ValidationPieChartProps {
    validatedTickets: number;
    notValidatedTickets: number;
    totalTickets: number;
}

export function ValidationPieChart({validatedTickets, notValidatedTickets, totalTickets}: ValidationPieChartProps) {
    const {t} = useTranslation('realtime');
    const [activeIndex, setActiveIndex] = useState(null);
    const isCompact = useContext(CompactEventDayContext)?.isCompact || false;
    const pieChartData = [
        {
            name: t('entered'),
            sold: validatedTickets,
            percentage: validatedTickets/totalTickets,
            color: '40C82B'
        },
        {
            name: t('waiting'),
            sold: notValidatedTickets,
            percentage: notValidatedTickets/totalTickets,
            color: 'FF1744'
        }
    ];

    const inlineRect = isCompact ? (
        <css.chartValue>
            {`${((pieChartData[0].percentage || 0) * 100).toFixed(0)}%`}
        </css.chartValue>
    ) : (
        <>
            <css.chartTitle>
                {t('entered')}
            </css.chartTitle>
            <css.chartValue>
                {`${((pieChartData[0].percentage || 0) * 100).toFixed(0)}%`}
            </css.chartValue>
            <css.chartAmount>
                {`${formatNumber(validatedTickets)}/${formatNumber(totalTickets)}`}
            </css.chartAmount>
            <css.chartAmount>
                {t('entered')}
            </css.chartAmount>
        </>
    );

    return (
        <css.chartSection>
            <GenericPieChart
                data={pieChartData}
                dataKey="percentage"
                activeIndex={{activeIndex, setActiveIndex}}
                onMouse={{
                    onMouseEnter: (index) => setActiveIndex(index),
                    onMouseLeave: () => setActiveIndex(null)
                }}
                radius = {{innerRadius: isCompact ? 30 : 60, outerRadius: isCompact ? 38 : 75}}
                inlineRect={inlineRect}
            />
        </css.chartSection>
    );
}
