import * as css from './RegularInput.css';

function RegularInput({value, setValue=()=>{} ,type='number',text = '', icon = '',description = '' ,dataCyName}) {

    return (
        <css.container>
            <css.wrapInput $types={{fieldType:'input'}} data-cy={`${dataCyName ? dataCyName : 'deafault'}Input`}>
                <css.icon icon={icon} />
                <input type={type} value={value} onChange={(e) => setValue(e)}/>
                <css.textWrap>
                    <css.titleWrap className={!value && ' empty'}>
                        <css.title>{text}</css.title>
                    </css.titleWrap>
                    <css.description>{description}</css.description>
                </css.textWrap>
            </css.wrapInput>
        </css.container>
    );
}
export default RegularInput;
