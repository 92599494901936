const Area = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201.85 122.23">
        <title>Asset 1</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M69.42,0h64.14a1.08,1.08,0,0,1,1.08,1.08v9.34a1.08,1.08,0,0,1-1.08,1.08H69.42a1.08,1.08,0,0,1-1.08-1.08V1.08A1.08,1.08,0,0,1,69.42,0Z" style={{fill:'#d22e27'}}/>
                <path d="M88.45,47.4a29.56,29.56,0,0,0,12.69,3.07c9.68,0,16.13-3,22.21-8.91,6.85-6.69,10.71-16.23,10.94-27a.58.58,0,0,0-.58-.6H68.58a.59.59,0,0,0-.59.59h0a38.16,38.16,0,0,0,9.89,24.92A34.4,34.4,0,0,0,88.45,47.4Z" style={{fill:'#52c2f0'}}/>
                <path d="M102.5,61.78c-.87,0-1.74,0-2.62-.08a42.7,42.7,0,0,1-15.51-4.15,45.83,45.83,0,0,1-22.7-24.5,58.34,58.34,0,0,1-3.29-12.82c-.06-.43-.44-4.37-.59-5.78a.48.48,0,0,1,.44-.53h0l7.06.1a.78.78,0,0,1,.8.74,46.26,46.26,0,0,0,2.56,13.88c3.71,10,10.48,16.45,19.3,20.68a32.44,32.44,0,0,0,32.75-2.54c9.16-6.58,14-17.74,15.46-28.66,0-.24.21-2.2.28-3.31a.79.79,0,0,1,.79-.75h6.8a.8.8,0,0,1,.8.8.13.13,0,0,1,0,.06c-.13,1.77-.71,5.67-.77,6-1.64,10.56-5.39,20.69-12.91,28.52A40.37,40.37,0,0,1,102.5,61.78Z" style={{fill:'#52c2f0'}}/>
                <path d="M175.53,13.7,146.89,14a.54.54,0,0,0-.54.53,56.14,56.14,0,0,1-5.43,24,.52.52,0,0,0,.22.69l25,13.89a.53.53,0,0,0,.73-.21v0c5.07-10.32,8.84-26,9.24-38.57a.54.54,0,0,0-.52-.55Z" style={{fill:'#52c2f0'}}/>
                <path d="M165.38,55l-25.13-14a.53.53,0,0,0-.72.21,46.92,46.92,0,0,1-8.06,10.52,44.3,44.3,0,0,1-6,4.88.55.55,0,0,0-.16.7l14.23,26a.54.54,0,0,0,.73.21l0,0a102.59,102.59,0,0,0,9.41-7.32c5.41-4.63,12.43-14.1,15.85-20.39a.54.54,0,0,0-.22-.73Z" style={{fill:'#52c2f0'}}/>
                <path d="M138,84.18,123.72,58.37a.56.56,0,0,0-.76-.21h0A41.38,41.38,0,0,1,104.2,63.8a.57.57,0,0,0-.53.55l-.22,29.51a.55.55,0,0,0,.55.56h0c7.25-.23,17.93-2.21,24.73-5a63.23,63.23,0,0,0,9.1-4.53A.56.56,0,0,0,138,84.18Z" style={{fill:'#52c2f0'}}/>
                <path d="M100.47,94.42h.27a.67.67,0,0,0,.67-.66h0l.28-29.23a.66.66,0,0,0-.66-.67h0a42.44,42.44,0,0,1-17.38-4.2c-.75-.35-1.48-.73-2.21-1.13a.67.67,0,0,0-.89.25L66.12,83.42a.66.66,0,0,0,.22.91h0c1.53.88,3.09,1.69,4.68,2.46C80.34,91.32,91.46,94.2,100.47,94.42Z" style={{fill:'#52c2f0'}}/>
                <path d="M64.22,82.05,78.86,57.76a.67.67,0,0,0-.22-.9,47.69,47.69,0,0,1-9.41-7.94,50.86,50.86,0,0,1-4.84-6.2.67.67,0,0,0-.89-.22L39.2,56.24a.67.67,0,0,0-.25.92v0a93.21,93.21,0,0,0,9,11.93A84.51,84.51,0,0,0,63.28,82.28a.66.66,0,0,0,.92-.2Z" style={{fill:'#52c2f0'}}/>
                <path d="M55.22,14H27a.6.6,0,0,0-.6.6h0a78.09,78.09,0,0,0,1.36,12c1.65,8.75,5.42,20.21,9.8,27.81a.59.59,0,0,0,.8.22L62.5,40.82a.59.59,0,0,0,.23-.79h0A52.64,52.64,0,0,1,55.8,14.57a.58.58,0,0,0-.56-.6Z" style={{fill:'#52c2f0'}}/>
                <path d="M201.29,13.75H178.51a.54.54,0,0,0-.54.51,95.2,95.2,0,0,1-9.41,39.58.54.54,0,0,0,.22.7l20.91,11.63a.54.54,0,0,0,.73-.21v0c7.19-14.9,11.35-32.3,11.42-51.66a.55.55,0,0,0-.54-.54Z" style={{fill:'#52c2f0'}}/>
                <path d="M152.67,75.87a75.78,75.78,0,0,1-11.18,9,.52.52,0,0,0-.18.69l12.08,21.46a.53.53,0,0,0,.73.21h0a100.45,100.45,0,0,0,34.92-38.58.54.54,0,0,0-.22-.72L167.92,56.31a.53.53,0,0,0-.73.2h0A79.89,79.89,0,0,1,152.67,75.87Z" style={{fill:'#52c2f0'}}/>
                <path d="M104,96.59a.67.67,0,0,0-.64.65l-.25,24.32a.67.67,0,0,0,.66.67h0a94.41,94.41,0,0,0,47.67-13.31.66.66,0,0,0,.24-.89L139.71,86.81a.65.65,0,0,0-.88-.26l0,0C132.77,90.17,125.27,94.89,104,96.59Z" style={{fill:'#52c2f0'}}/>
                <path d="M71.42,89.44c-2.09-1.08-4.22-2.13-6.25-3.34a.63.63,0,0,0-.87.21h0L52.1,107a.64.64,0,0,0,.22.87,98.34,98.34,0,0,0,48.14,14.32l.66-.64.24-24.39a.58.58,0,0,0-.58-.6C97.74,96.5,83.65,95.76,71.42,89.44Z" style={{fill:'#52c2f0'}}/>
                <path d="M62.44,84.4a.64.64,0,0,1,.2.85l-12.2,20.69a.63.63,0,0,1-.86.23l0,0A108,108,0,0,1,15.43,70.49a.64.64,0,0,1,.23-.88h0L36.26,57.94a.63.63,0,0,1,.85.22,85.36,85.36,0,0,0,9.82,13A81.56,81.56,0,0,0,62.44,84.4Z" style={{fill:'#52c2f0'}}/>
                <path d="M35.51,55.43a.59.59,0,0,1-.22.8L14.66,67.93a.59.59,0,0,1-.81-.23h0A111.33,111.33,0,0,1,0,14.34a.59.59,0,0,1,.59-.59h23a.57.57,0,0,1,.58.56,89.74,89.74,0,0,0,6.13,30A88.06,88.06,0,0,0,35.51,55.43Z" style={{fill:'#52c2f0'}}/>
            </g>
        </g>
    </svg>
);

export default Area;
