import * as css from './insightsCard.css';

function InsightsCard ({children}){

    return (
        <css.card>
            {children}
        </css.card>
    );
}

export default InsightsCard;
