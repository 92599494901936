import styled from 'styled-components';
import {getColor} from '../../pages/realtime/eventRealtime/EventRealtime.css';
import {maskImage} from '../../globalVariables/GlobalVariables';
export const item=styled.div<{ $icon?: string; }>`
        font-size: 14px;
        padding-top: 25px;
        position: relative;
        width: max-content;
        cursor: pointer;
        color: ${p=>getColor(p.theme.isDarkMode)};
        &::before {
            content: '';
            position: absolute;
            ${p=>maskImage(p.$icon,getColor(p.theme.isDarkMode),'contain')};
            -webkit-mask-image: url("${p=>p.$icon}");
            mask-image: url("${p=>p.$icon}");
            width: 25px;
            height: 25px;
            top: 0;
            right: calc(50% - 12.5px);
    }
`;

