import {useTranslation} from 'react-i18next';
import EnterButton from '../../components/enterButton/EnterButton';
import LoginInput from '../../components/loginInput/LoginInput';
import * as css from './LoginForm.css';
import LoginByGoogle from '../../components/loginByBtn/LoginByGoogle';
import LoginByBtn from '../../components/loginByBtn/LoginByBtn';
import {useSelector} from 'react-redux';
import {loginApple, loginUserGL} from '../../../../redux/user/userActions';
import LoginPasswordInput from '../../components/loginPasswordInput/LoginPasswordInput';
import SendToEmail from '../../components/sendToEmail/SendToEmail';
import {useField} from 'formik';
import {Link} from 'react-router-dom';
import LoginByApple, {isAppleDevice} from '../../components/loginByBtn/LoginByApple';
import ForgetPasswordLink from './forgetPasswordLink';
import setGlobalState from '../../../../redux/setGlobalState';
import {useHistory} from 'react-router-dom';

const isLoginViaApp = new URLSearchParams(window.location.search).get('login_source') === 'android';
setGlobalState('auth.isApplication', isLoginViaApp);
export const onSignIn = (response) => {
    return loginUserGL({glAccessToken: response.credential});
};

function LoginForm({isPassword, setIsPassword}) {
    const {t} = useTranslation('login');
    const [, , passwordHelper] = useField('password');
    const {loginByEmailXHR, loginUserXHR} = useSelector((s) => s.user);
    const existingUserEmail = useSelector((s) => s.ui.login.existingUserEmail);
    const showOnlyEnter=loginByEmailXHR||['LOADING','SUCCESS'].includes(loginUserXHR)||isPassword;
    const history = useHistory();

    const passwordClick = () => {
        setIsPassword(' password');
    };

    const backPasswordClick = () => {
        setIsPassword(false);
        passwordHelper.setValue('');
    };

    const googleSignIn = (response) => {
        onSignIn(response).then((res)=>{
            if(res.isNewUser) {
                history.push('/signUp');
            }
        });
    };

    return (<>
        {isPassword&&<css.back onClick={backPasswordClick} data-cy = 'goBackPassLogin'/>}
        <css.container className={isPassword +existingUserEmail} >
            <css.header data-cy = 'loginTitle'>
                {existingUserEmail?t('existingUserEnter'):t('comeAndEnter')}
            </css.header>
            <css.wrapGoogle>
                <LoginByGoogle callback={googleSignIn}/>
            </css.wrapGoogle>
            {isAppleDevice() && <css.wrapApple>
                <LoginByApple onSuccess={loginApple}/>
            </css.wrapApple>}
            <css.likeBefore>{t('likeBefore')}</css.likeBefore>
            <LoginInput name="email" type='email' text={t('email')} description={t('emailDesc')} toEmptyOnFocus/>
            <css.password className={isPassword}>
                {isPassword&&<LoginPasswordInput  name="password"  text={t('password')} description={t('')} toEmptyOnFocus/>}
                {isPassword&& <ForgetPasswordLink/>}
            </css.password>
            <css.button className={showOnlyEnter?'enter':''}>
                {/* <css.button className={isPassword || loginByEmailXHR?' password':''}>                                        */}
                <EnterButton actionName={isPassword?'loginUser':'loginByEmail'} text={t(isPassword?'getInside':'sendMeCode')} buttonType= 'login'/>
                {!showOnlyEnter&&<LoginByBtn text={t('password')} className="password" onClick={passwordClick} />}
            </css.button>
            {!showOnlyEnter&&<SendToEmail/>}

            <css.lineLink />
            <css.linkWarp>{t('linkQuestionText')}<Link to={'/signup'}>{t('linkText')}</Link></css.linkWarp>    
        </css.container>
    </>
    );
}

export default LoginForm;
