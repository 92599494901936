import * as css from './transaction.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {formatRealtimeNumber} from '../../../functions';
import {Insight, MainDetail} from '../../windowItems/windowItems';
import {useEffect} from 'react';
import {givechakMultiplier} from '../../../../../redux/realtime/realtimeActions';
import {XHR_LOADING_STATE} from '../../../../../assets/constants/constants';
import TransactionsTemplate from './transactionsTemplate';
import {formatNumber} from '../../../../../library/numberFunction';

const Multiplication = () => {
    const {t} = useTranslation('realtime');
    const {list,multiplied,realAmount,multipliedAmount,percent} = useSelector((state: any)=>state.realtime.event.multiplierData);
    const isLoading = useSelector((s:any)=>s.realtime?.event?.givechakMultiplierXHR === XHR_LOADING_STATE);
    
    const {eid,type, currencySymbol} = useSelector((s:any) => ({
        eid: s.event.eid,
        type: s.event.type,
        currencySymbol: s.event.currencySymbol,
    }));
    
    useEffect(()=>{
        if(eid) givechakMultiplier(eid);
    },[eid]);

    if(isLoading) {
        return <TransactionsTemplate />;
    }
    return (
        <css.container $isGivechak={type === 'givechak'}>
            <css.content>
                {!!multiplied && <MainDetail item={{title: 'multiplied',  value: multiplied}} />}
                <MainDetail item={{title: 'realAmount', value: realAmount}} />
                <MainDetail item={{title: 'multipliedAmount',value: multipliedAmount}} />
            </css.content>
            {list?.length > 1 && <css.tableWarp>
                <css.tableHeader>
                    <css.tableCell>{t('multiplied')}</css.tableCell>
                    <css.tableCell>{t('realAmount')}</css.tableCell>
                    <css.tableCell>{t('multipliedAmount')}</css.tableCell>
                </css.tableHeader>
                {list?.map((item,index) => (
                    <css.tableRow key={index}>
                        <css.tableCell >{`x${item.multiplier}`} </css.tableCell> 
                        <css.tableCell >{`${currencySymbol}${formatNumber(item.realAmount)}`} </css.tableCell>           
                        <css.tableCell >{`${currencySymbol}${formatNumber(item.multipliedAmount)}`} </css.tableCell>           
                    </css.tableRow>
                ))} 
            </css.tableWarp>}
            {100 - percent > 0 && list?.[0]?.realAmount > 0 && <Insight item={{
                value: `${formatRealtimeNumber(100 - percent)}`,
                text: t('donationDoubled', {amount: formatRealtimeNumber(100 - percent)})
            }} />}
        </css.container>
    );
};

export default Multiplication;