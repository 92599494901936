import styled from 'styled-components';
import {dFlexCenter, ellipsis, mobileMedia, tabletMedia} from '../../../../../../../globalVariables/GlobalVariables';
import zIndex from '../../../../../../../globalVariables/zIndex';
import realtimeFonts from '../../../../../fonts';

export const chartSection = styled.div`
    ${dFlexCenter};
    width: ${p => p.theme.$compact ? '100' : '40'}%;
    height: 100%;
    position: relative; 
    inset-inline-start: ${p => p.theme.$compact ? '0' : '10px'};
    z-index: ${zIndex.baseRaised};
    *:focus {
        outline: none;
    }
    ${mobileMedia}{
        inset-inline-start: unset;
        width: 100%;
        height: 200px;
    }
    ${tabletMedia}{
        ${p => p.theme.$compact && 'display: none;'}
    }
`;

export const chartTitle = styled.div`
    ${realtimeFonts.textBold}
    ${ellipsis};
    text-align: center;
`;

export const chartValue = styled.div`
    font-size: 24px;
    font-weight: bold;
`;

export const chartAmount = styled.div`
    ${realtimeFonts.subText}
`;
