import * as css from './TicketValidations.css';
import {useTranslation} from 'react-i18next';
import TicketProgress from '../../../../../tickets/components/ticketRow/ticketProgress/TicketProgress';

export const TicketValidations = ({ticket}) => {
    const {t} = useTranslation('realtime');

    return (
        <css.item key={ticket.tid}>
            <css.color color={ticket.color}>
                <TicketProgress onClick={undefined} ticket={ticket} totalValue={ticket.entered + ticket.waiting} partialValue={ticket.entered}>{null}</TicketProgress>
            </css.color>
            <css.right>
                <css.ticketTitle data-cy='titleColumnValue'>{ticket.title}</css.ticketTitle>
                <css.details>
                    <css.bold data-cy='typeColumnValue'>{ticket.entered || 0}</css.bold>/
                    <css.value data-cy='typeColumnValue'>{ticket.entered + ticket.waiting || 0}</css.value>
                    <css.text>{t('entered')}</css.text>
                </css.details>
            </css.right>
        </css.item>
    );
};
