import {useTranslation} from 'react-i18next';
import TickchakIcon from '../icons/TickchakIcon';
import * as css from './Terminal.css';
import {formatNumber} from '../../../../../../library/numberFunction';

interface TerminalProps {
    name: string;
    active: boolean;
    count: number;
}

export function Terminal({name, active, count}: TerminalProps) {
    const {t} = useTranslation('realtime');
    return (
        <css.wrapper>
            <css.profile>
                <TickchakIcon />
            </css.profile>
            <css.status $isActive={active} />
            <css.content>
                <css.name>{t(name)}</css.name>
                <css.validations>{formatNumber(count)}</css.validations>
            </css.content>
        </css.wrapper>
    );
}
