import styled from 'styled-components';
import * as scroll  from '../../../../component/scrollbar/Scrollbar.css';
import zIndex from '../../../../globalVariables/zIndex';

export const UserProfileTop = styled.div`
    height:max-content;
`;
export const UserNameStyled = styled.div`
    // position: absolute;
    // width:180px;
    height: auto;
    // top: 80%;
    // left:calc(50% - 90px);
    cursor: default;
    text-align: center;
    font-size:20px;
    font-weight : 700;
    &.bold{
        font-weight : 700;
        font-size:20px;
    }
`;

export const UserConnectionTimeStyled = styled.div`
    // position: absolute;
    // width:180px;
    height: auto;
    // top: 95%;
    left:calc(50% - 90px);
    cursor: default;
    text-align: center;
    font-size: 12px;
`;

export const ProfileMenuStyledCtr = styled.div`
        height: calc(${props=>props.theme.vh*100}px - 50px);
        width:auto;
        position: absolute;
        top: calc(-${props=>props.theme.vh*100}px + 50px);
        left: ${props => (props.theme.rtl ? 'auto' : '0')};
        right: ${props => (props.theme.rtl ? '0' : 'auto')};
        background: #FAFAFA;
        box-shadow: -5px 0px 6px #0000000d;
        transition:all 400ms;
        z-index:${zIndex.dropDown};
        @media screen and (max-width: 1000px){
            width:100%;
        }
        @media screen and (max-width: 1400px){
            height: calc(${props=>props.theme.vh*100 * 1.25}px - 50px);
        }
        &.show{
            top: 50px;            
        }
    `;

export const ProfileMenuStyledHeaderCtr = styled.div`
        position: relative;
        width: 100%;
        height: 30%;
        // height: 20%;
        // margin-top:20%;
        transition: transform 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height:120px;
        @media screen and (max-width: 1000px){
              height: 35%
        }
    `;

export const ProfileMenuStyledSideCtr = styled.div`
        width: 230px;
        height: 100%;
        position:relative;
        float: ${props=>(props.theme.rtl?'right':'left')};
        background: #F5F4F4;
        &.edit ${ProfileMenuStyledHeaderCtr}{
            transform:scale(0);
        };
        @media screen and (max-width: 1000px){
            width:100%;
        }
    `;
export const ProfileMenuStyledClose = styled.div`
    position:absolute;
    width:20px;
    height:20px;
    top:10px;
    ${props=>props.theme.rtl?'left':'right'}:5px;
     background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.344' height='6.434' viewBox='0 0 8.344 6.434'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: none; stroke: %233a4143; stroke-linecap: round; stroke-width: 2px; %7D %3C/style%3E%3C/defs%3E%3Cg id='Component_154_1' data-name='Component 154 – 1' transform='translate(1.406 1.406)'%3E%3Cpath id='Path_2879' data-name='Path 2879' class='cls-1' d='M233.982,21l2.766,3.434L239.514,21' transform='translate(-233.982 -21)'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: center center;
    transform: rotate(180deg);    
    cursor: pointer;
    z-index:${zIndex.baseRaised}; 
    transition: all 400ms;
   &.closeEdit{
        transform: rotate(${props=>props.theme.rtl?270:90}deg);
        ${props=>props.theme.rtl?'left':'right'}: calc(100% - 25px);
   }
    @media screen and (max-width: 1000px){
        display:none;
        &.closeEdit{
            display:block;
        }
    }
    
`;

export const WrapperEditProfileDiv = styled.div`
    position: relative;
    width: 90%;
    height: 100%;
    margin: 0 auto;
    
`;
export const EditUserTop = styled.div`
    height: max-content;
    width:100%;
    position:relative;
`;
export const EditUserSave = styled.div`
    height:30px;
    width:100%;
    font-size:16px;
    padding-top: 10px;
    .save{
        float:  ${props=>(props.theme.rtl?'left':'right')};
        height: 25px;
        padding: 0 14px;
        line-height: 24px;
        margin-top: 5px;  
    }
    .success{
        font-size:16px !important;
        background-position:right center !important;
        background-size: 16px 16px !important;
        padding: 0 18px;
    }
    
`;
export const EditUserImgBtn = styled.button`
    position: absolute;
    width: 35px;
    height: 35px;
    top: 5px;
    left: -7px;
    background: #B5B5B5;
    border-radius: 50%;
    border: none;
    box-shadow: 0px 0px 3px 1px #00000029;
    cursor: pointer;
    outline: none;
    transition: transform 0.3s;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.06' height='19.054' viewBox='0 0 19.06 19.054'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23484848;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M276.243,390.056a2.939,2.939,0,0,0-.855-2.073l-3.327-3.333a2.963,2.963,0,0,0-2.085-.861v.006h0a2.887,2.887,0,0,0-2.068.855q-5.259,5.233-10.495,10.489l-.006.017H257.4l-.012.012h0l-.012.011v.012h-.011l-.006.011v.011l-.011.012v.011h-.012l-.011.006-.012.017v.012l-.011.006v.006l-.012.011v.017l-.017.012v.011l-.006.011-.006.011h-.006l-.006.012h0l-.006.017-.006.006v.011l-.006.006v.012l-.011.011v.029l-.012.012v.006l-.011.023v.046l-.012.011v.023h0v6.475a.789.789,0,0,0,.791.791h6.428l.017-.006h.064l.011-.006.023-.006.023-.006h.035l.035-.023h.017l.023-.011.012-.012.017-.011.017-.012.017-.006.017-.011.023-.012.012-.011.023-.006.011-.011.012-.006.023-.011.011-.011.017-.012.011-.023.012-.017c3.5-3.506,6.994-6.989,10.494-10.489a2.945,2.945,0,0,0,.855-2.073Zm-9.669-1.825h0l.947.947-7.468,7.474-.947-.941,7.468-7.479Zm-7.8,13.03h0v-3.644l3.644,3.644Zm1.964-3.939h0l7.468-7.468,1.981,1.987-7.479,7.468-1.969-1.987Zm3.61,3.615h0l-.959-.953,7.479-7.479.947.959-7.468,7.474Zm10.321-10.881h-.011a1.328,1.328,0,0,1-.4.959l-1.317,1.323L267.7,387.11l.121-.133.479-.479.722-.722a1.374,1.374,0,0,1,.953-.4v.006a1.323,1.323,0,0,1,.947.393l3.333,3.333a1.305,1.305,0,0,1,.41.947Z' transform='translate(-383.789 276.243) rotate(-90)'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px 15px;
    &:hover{
        transform: scale(1.1);
    }
    @media screen and (max-height: 600px){
        width: 30px;
        height: 30px;
        background-size: 12px 12px;
    }    

`;

export const EditProfileHeaderCtr = styled.div`
    position: relative;
    // padding-top:30px;
    width: 100%;
    height: 30%;
    transition: all 1s;
    transform: scale(0);
    display: flex;
    align-items: center;
    justify-content: center;    
    min-height: 210px;
    @media screen and (max-height: 600px){
         height: 40%;
          min-height: 180px;
    }
`;

export const EditProfileBodyCtr = styled(scroll.Scrollbar)`
    position: relative;
    width: 100%;
    height: 70%;
    max-height: calc(100% - 220px);
    @media screen and (max-height: 600px){
        height: 60%;
        max-height: calc(100% - 180px);
    }
`;

export const EditProfileDetailsCtr = styled(scroll.Scrollbar)`
    position: relative;
    width: 100%;
    height: calc(100% - 10px);
`;

export const EditProfileDetailsHeader = styled.div`
    position: relative;
    width: 100%;
    height: 50px;
    font-size: 14px;
    text-align: ${props=>(props.theme.rtl?'right':'left')};
    color: #484848;
    opacity: 0.67;
    letter-spacing:2.4px;
    line-height: 50px;
`;
export const LanguageTime = styled(EditProfileDetailsHeader)`
    inset-block-start: 20px;
    margin-block: 15px;
`;
export const EditProfileDetailsFieldsCtr = styled.div`
    position: relative;
    width: 100%;
    height: 40%;
`;

export const ProfileMenuStyledEditCtr = styled.div`
        width: 0;
        height: 100%;
        position:relative;
        float: left;
        background:#fff;
        transition: all 0.3s;
        opacity:0;
        pointer-events:none;
        @media screen and (max-width: 1000px){
            position: absolute;
        }
        &.open{
             width:400px;
            opacity:1;
            pointer-events:all;
            @media screen and (max-width: 1000px){
                position: absolute;
                width: 100%;
            }
        };
        &.edit{
            ${EditProfileHeaderCtr}{
                transform: scale(1);
            }
        };
          
    `;

export const MenuBtnList = styled(scroll.Scrollbar)`
    position: relative;
    width: 100%;
    // height:auto;
    margin-top:15px;
    float:right;
    height: 70%;
    max-height: calc(100% - 120px);
    @media screen and (max-width: 1000px){
        height: 65%;
    }
`;