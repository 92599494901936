import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import SelectItem from '../../../../component/selectItem/SelectItem';
import * as css from './CategoryStep.css';
import useEffectOnUpdate from '../../../../hooks/useEffectOnUpdate';
import store from '../../../../store';
import getSpecialTranslation from '../../../../functions/getSpecialTranslation';
import {sortByReferenceArray} from '../../../../library/sorts';
import useShow from '../../../../hooks/useShow';
import AddCategory from '../addCaregory/AddCategory';
import StepContext from '../stepContext/StepContext';
import StepRight from '../stepRight/StepRight';
import StepLeft from '../StepLeft/StepLeft';

export const getTopCategory = (value, t) => {
    const categories = store.getState().categories?.eventCategories;
    const _t = (text) => t(`layout:${text}`);
    return value.map(val => getSpecialTranslation(`category${val}`, _t) || categories.find(c => c.catId === val)?.title || '').join(', ');
};

function CategoryStep() {
    const {t} = useTranslation('newEvent');
    const tLayout = useTranslation('layout');
    const categories = useSelector((s) => s.categories.eventCategories);
    const referenceCategories = [1,7,949,950,951,3,5,952,2,4,6,49,17,0];
    const categoriesList = sortByReferenceArray(categories||[], referenceCategories);
    const [categoryField, ,categoryHelpers] = useField('category');
    const editShow = useShow();
    const iconList= {1:'speaker',2:'tours',3:'cake',4:'arts',5:'conventions',6:'ball',7:'smilingFace',17:'fundraiser',49:'sunHat',0:'generalMenu',949:'shows',950:'childrenShows',951:'concert',952:'lecture'};
    useEffectOnUpdate(()=>{
        categoryHelpers.setTouched(true);
    },[categoryField.value]);

    const handleSelected = (key) => {
        const current = categoryField.value || [];
        if(!current.includes(key)) {
            categoryHelpers.setValue([...current, key]);
        }
    };
    return (
        <StepContext>
            {!editShow.isOpen && <> <StepRight name="category">
                <css.wrapper>
                    {categoriesList?.map((c,index)=>
                        <SelectItem key={c.catId}
                            catId={c.catId}
                            index={index}
                            text={getSpecialTranslation(`category${c.catId}`,tLayout.t)||c.title}
                            selectedArray={categoryField.value}
                            icon={iconList[c.catId]||'packageImg'}
                            isEditable={!iconList[c.catId]}
                            editShow={editShow}
                            onSelected={(key) => handleSelected(key)}
                        />)}
                    <css.addButton onClick={editShow.open}>
                        <css.icon />
                        <css.text>{t('addCategory')}</css.text>
                    </css.addButton>
                </css.wrapper>
            </StepRight>
            <StepLeft name="category"/></>}
            {editShow.isOpen && <AddCategory categoriesList={categoriesList} close={editShow.close} />}
        </StepContext>
    );
}

export default CategoryStep;