import * as css from './QueueProcessStatus.css';
import TicketProgress from '../../pages/tickets/components/ticketRow/ticketProgress/TicketProgress';
import Cookies from 'js-cookie';
import {ReactNode} from 'react';
import setGlobalState from '../../redux/setGlobalState';
import store from '../../store';

let intervalId: NodeJS.Timeout | null = null;

export const getTaskState = (time, action, id) => {
    const tickchakQueue = store.getState().ui.tickchakQueue;
    const cookieValue = Cookies.get('tickchakQueue');

    if(tickchakQueue?.[id]){
        action().then(res=>{
            if(res?.success){
                const dataObject = JSON.parse(cookieValue);
                delete dataObject[id];
                if(Object.keys(dataObject).length === 0) {
                    Cookies.remove('tickchakQueue');
                } else {
                    Cookies.set('tickchakQueue', JSON.stringify(dataObject));
                }
                setGlobalState('ui.tickchakQueue', dataObject);
                if(intervalId) {
                    clearInterval(intervalId);
                    intervalId = null;
                }
            } else if(!intervalId) {
                intervalId = setInterval(() => {
                    getTaskState(time, action, id);
                }, time);
            }
        });
    }
};

function QueueProcessStatus ({children, amount, sold}:{children: ReactNode, amount: number, sold: number}) {

    return <>
        <css.progressWrapper>
            <TicketProgress ticket={{color: '76D6E8', amount, sold}} onClick={()=>{}} styleProps={{strokeTrailWidth: '0'}} >
                {children}
            </TicketProgress>
        </css.progressWrapper>
    </>;
}
export default QueueProcessStatus;
