import * as css from './transaction.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {XHR_LOADING_STATE} from '../../../../../assets/constants/constants';
import {formatRealtimeNumber, formatRealtimeDecimal} from '../../../functions';
import TransactionsTemplate from './transactionsTemplate';
import approximateFraction from '../../../../../functions/approximateFraction';
import {CircleProgress, Insight, MainDetail} from '../../windowItems/windowItems';
import {eventTypes} from '../../../eventRealtime/eventTypes';

const Transactions = () => {
    const {t} = useTranslation('realtime');
    const {all: stats, today: {allVisitors: visitsToday} = {allVisitors: null}, mostFrequentTicketsAmount, now: {allVisitors: visitsNow} = {allVisitors: null}} = useSelector((state: any)=>state.realtime.event.eventStats) || {};
    const {currencySymbol, type} = useSelector((state: any)=>state.event);
    const isLoading = useSelector((s:any)=>s.realtime?.event?.getEventStatsXHR === XHR_LOADING_STATE);
    const [numerator, denominator] = approximateFraction(stats?.amountOrders || 0, stats?.uniqueUsers || 1);
    const insightArray = eventTypes[type]?.donationsArrayTitles || eventTypes.default.donationsArrayTitles;
   
    const insightObject = {
        visitorsFraction: {
            text: t('visitorsFraction', {numerator, denominator}),
            value: t('know')
        },
        visitedToday: {
            text: 'visitedToday',
            value: (visitsToday||0) + (visitsNow||0)
        },
        visitDuration: {
            text: 'visitDuration',
            value: `${formatRealtimeDecimal(stats.averageVisitDuration/60, true) || 0} ${t('minutesShort')}`
        },
        mostFrequentTicketsAmount:{
            text: t('mostFrequentTicketsAmount', {amount: mostFrequentTicketsAmount}),
            value: t('know')
        },
        averageTickets: {
            text: 'averageTickets',
            value: ((stats.ticketsSold || 0) / (stats.amountOrders || 1)).toFixed(1)
        },
        averagePrice: {
            text: 'averagePrice',
            value: `${formatRealtimeNumber((stats.totalPrice || 0) / (stats.amountOrders || 1)||0, true)} ${currencySymbol} `
        }
    };
    const showUsers = stats.uniqueUsers > 0 ?  stats.uniqueUsers : insightObject.visitedToday.value;
    const showViews = Math.max(stats.allVisitors, stats.uniqueUsers, insightObject.visitedToday.value);

    if(isLoading) {
        return <TransactionsTemplate />;
    }
    if(!stats)
        return <></>;
    return (
        <css.container $isGivechak={type === 'givechak'}>
            <css.content>
                <MainDetail item={{title: 'users', value: showUsers}} />
                <MainDetail item={{title: 'views',value: showViews}} />
                <MainDetail item={{title: 'transactionsTitle',  value: stats.amountOrders}} />
            </css.content>
            <css.details>
                <css.stats isListFull={stats?.uniqueUsers || mostFrequentTicketsAmount} >
                    {insightArray?.map((item) => {
                        if(item === 'visitorsFraction') {
                            return stats?.uniqueUsers ? <Insight key={item} item={insightObject[item]} /> : null;
                        }
                        if(item === 'mostFrequentTicketsAmount') {
                            return mostFrequentTicketsAmount ? <Insight key={item} item={insightObject[item]} /> : null;
                        }
                        return <Insight key={item} item={insightObject[item]} />;
                    })}

                </css.stats>
                <CircleProgress amount={stats.uniqueUsers} sold={stats.amountOrders} text={'conversion'} />
            </css.details>
        </css.container>
    );
};

export default Transactions;