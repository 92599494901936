import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import SMSCode from '../../../../component/SMSCode/SMSCode';
import {loginUser, loginByEmail} from '../../../../redux/user/userActions';
import * as css from './Code.css';
import {useField} from 'formik';
import setGlobalState from '../../../../redux/setGlobalState';
import LoginButton from '../../components/loginButton/LoginButton';
import {useEffect} from 'react';
import SendToEmail from '../../components/sendToEmail/SendToEmail';
import SendToSMS from '../../components/sendToSMS/sendToSMS';
import useTimeout from '../../../../hooks/useTimeout';
import UserMessage, {bounceMessage} from '../../../../layout/userMessages/UserMessage';
import {maskEmail} from '../../../../functions/maskEmail';

function Code({setCurrentPage}) {
    const {t} = useTranslation('login');
    const globalT = useTranslation('global').t;
    const [emailField]=useField('email');
    const {loginUserXHR,loginByEmailXHR}=useSelector(s=>s.user);
    const errorMessage=useSelector(s=>s.auth.message);

    const [sendToEmailField,,sendToEmailHelper]=useField('sendToEmail');

    const onUpdate=(code) => {
        const user = {
            email:emailField.value,
            password:code};
        loginUser(user,false).then((res) => {
            if(res?.isInactiveUser){
                resetMessage();
                setGlobalState('ui.codeInput.reset',true);
                bounceMessage(t('inactiveUserError', {email: maskEmail(emailField.value)}), 'warning');
            }
        });
    };

    const resetXHR=()=>{
        if(loginByEmailXHR)
            setGlobalState('user.loginByEmailXHR',null);
        if(loginUserXHR)
            setGlobalState('user.loginUserXHR',null);
    };

    const resetMessage=()=>{
        setGlobalState('user.loginUserXHR',null);
        setGlobalState('auth.message',null);
    };

    const timer=useTimeout();

    useEffect(()=>()=>{
        if(sendToEmailField.value)
            sendToEmailHelper.setValue(false);
    },[]);
    
    useEffect(()=>{
        if(loginUserXHR==='FAILED'){
            sendToEmailHelper.setValue(false);
            timer.current=setTimeout(resetMessage, 3500);}
    },[loginUserXHR]);

    const sendAgainClick=() => {
        loginByEmail(emailField.value,sendToEmailField.value);      
    };

    const sendAgain=()=>{
        sendAgainClick();
        resetMessage();
        setGlobalState('ui.codeInput.reset',true);
    };

    useEffect(()=>{
        setGlobalState('ui.codeInput.isWrong',false);
        resetXHR();
        return resetXHR;
    },[]);

    const onClick=()=>{
        setCurrentPage('signIn');
    };
    const onClickSend=()=>{
        resetMessage();
        setGlobalState('ui.codeInput.reset',true);
    };
    return (
        <css.container id="passwordSquaresArea" data-cy="passwordSquaresArea">
            <css.title>{t`sendSMS`+t(sendToEmailField.value?'message':'sms')}
                <css.text>{t('lookPhone',{sendTo:sendToEmailField.value?t('email'):t('phone')})}</css.text>
            </css.title>                
            <SMSCode onUpdate={onUpdate} sendAgainClick={sendAgainClick} address={sendToEmailField.value?emailField.value:null} text={sendToEmailField.value && globalT('EmailSent')}/>
            <css.bottom>
                <LoginButton className={loginUserXHR||loginByEmailXHR} onClick={errorMessage?sendAgain:onClick}  errorText={`${errorMessage}, ${globalT('sendAgain')}`} text={t('return')}  buttonType="goBack"/>
                {sendToEmailField.value ?
                    <SendToSMS onClick={onClickSend}/>:<SendToEmail onClick={onClickSend}/>}
            </css.bottom>
            <UserMessage/>
        </css.container>
    );
}

export default Code;
