import styled from 'styled-components';
import {error, vi} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, dFlexCenter, ellipsis} from '../../../../globalVariables/GlobalVariables';
import zIndex from '../../../../globalVariables/zIndex';

export const loginEllipse=styled.div` 
    height:25px ;
    border-radius: 50px;
    line-height: 30px;
    padding-inline-start: 5px;
    font-size:14px;
`;

export const input = styled(loginEllipse)`
    flex-shrink: 0;
    position: relative;
    height:35px;
    background-color: #FFF;
    border:2px solid #FFF;
    display: flex;
    align-items:center;
    margin-bottom: 20px;
    &:hover:not(.disabled){
        border:2px solid #FFDA0D;
    }
    &.disabled{
        opacity: 0.5;
    }
    input{
        border:none;
        font-size: 14px;
        width:calc(100% - 30px);
        padding-inline-start: 5px;
        height: 100%;
        z-index:${zIndex.baseRaised};
        background-color: transparent;
    }
`;

export const validateSign=styled.div` 
    transform: scale(0);
    width: 21px;
    height: 21px;
    border-radius: 50%;
    transition: all 300ms;
    ${dFlexCenter}; 
    &.invalid{
        transform: scale(1);
        background-color: #DD2F21;
        ${BGImageCenter(error, '12px 12px')}
    }
    &.valid{
        transform: scale(1);
        background-color:#AED11C ;
        ${BGImageCenter(vi, '12px 12px')}
    }
`;

export const errorMessage=styled.div`   
    color:red;
    position: absolute;
    bottom:-24px;
    inset-inline-start:25px;    
    font-weight: 500;
    font-size: 10px;
    ${ellipsis}
`;

export const text=styled.div`   
    ${ellipsis};
    position: absolute;
    bottom:-24px;
    inset-inline-start:25px;
    font-weight: 500;
    font-size: 12px;
    transition: all 300ms;
    &::after{
        content: "${p=>p.description}";
        font-size: 11px;
        font-weight: 400;
        margin-inline-start: 3px;
    }
    &.empty{
        overflow: visible;
        bottom:0 ;
        inset-inline-start: 32px;
        font-size: 14px;
        font-weight: 600;
        &::after{
            margin-top:24px;
            position: absolute;
            inset-inline-start: 0;
            width: 200px;
            margin-inline-start: 0px;
            ${ellipsis}
        }
    }

`;
