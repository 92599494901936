import {useTranslation} from 'react-i18next';
import * as css from './FacebookPixelPopContent.css';
import {Else, If, Then} from 'react-if';
import useMobile from '../../../hooks/useMobile';
import Input from '../../../component/input/Input';
import DomainInput from '../../../component/input/domainInput/DomainInput';
import Btn from '../../../component/button/btn/Btn';
import {useSelector} from 'react-redux';
import ModeBtn from '../../../component/modeBtn/ModeBtn';
import {useEffect} from 'react';
import {useFormikContext} from 'formik';
import DomainVerificationSection from './DomainVerificationSection';

function FacebookPixelPopContent({submitForm,tickchakDomain,pixel}) {
    const {t} = useTranslation('publish');
    const isMobile = useMobile();
    const form = useFormikContext();
    const {addSpecialPixelXHR,updateSpecialPixelXHR} = useSelector((state) => state.publish);
    const {setIsTickchakDomain,isTickchakDomain}= tickchakDomain;
    const {type}=useSelector(s=>s.event);

    useEffect(form.validateForm, [isTickchakDomain]);

    const hasHubDomain = pixel?.domain && (type==='hub');
    
    return (
        <>
            <If condition={isMobile}>
                <Then>
                    <css.mobileIcon title={t('facebookPixel')}/>
                </Then>
                <Else>
                    <css.background>
                        <css.title>{t('facebookPixel')}</css.title>
                        <css.icon />
                    </css.background>
                </Else>
            </If>
            <css.content>
                <Input name="id" text={t('serialNumber')}/>
                {(type !=='hub' || hasHubDomain) && <> 
                    <css.wrap>
                        <css.text>{t('domainSettings')}</css.text>
                        <ModeBtn status={!isTickchakDomain} className={'small'} text={t('privateDomain')} click={()=>setIsTickchakDomain(!isTickchakDomain)} dataCyName="privateDomain"  {...(hasHubDomain && {disabled: true})}/>
                    </css.wrap>
                    <DomainInput name="domain" descText={!isTickchakDomain&&t('domainMessage')} text={t(isTickchakDomain?'changeSubDomain':'changeDomain')} isSubDomain={isTickchakDomain}  {...(hasHubDomain && {disabled: true})}/>
                    <DomainVerificationSection />
                </>}
            </css.content>
            <css.bottom>
                <Btn className={addSpecialPixelXHR||updateSpecialPixelXHR} onClick={submitForm}>{t('save')}</Btn>
            </css.bottom>
        </>
    );
}

export default FacebookPixelPopContent;
