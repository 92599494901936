import * as css from './TimeDisplay.css';
import StopwatchIcon from '../icons/StopwtchIcon';
import {BLACK} from '../../../../../../globalVariables/GlobalVariables';
import Timer from '../../../timer/Timer';
import {getDateFormat, getTs} from '../../../../../../library/dates';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import useMobile from '../../../../../../hooks/useMobile';

export function TimeDisplay() {
    const {timeStart, timeEnd, timeZone} = useSelector((s: any) => ({
        timeStart: s.event.timeStart,
        timeEnd: s.event.timeEnd,
        timeZone: s.event.timeZone
    }));
    const {t} = useTranslation('realtime');
    const isMobile = useMobile();
    const now = getTs();
    const textDate = (now >= timeStart && now < timeEnd) ? 'eventWillFinish':(now >= timeEnd?'eventFinished':'eventWillStart');

    return (
        <>
            <StopwatchIcon 
                color={BLACK} 
                width={isMobile ? 13 : 37} 
                height={isMobile ? 15 : 40} 
            />
            <css.timer>
                <css.timeText>{t(textDate)}</css.timeText>
                {textDate === 'eventFinished' ? (
                    <css.date>{getDateFormat(now, 'DD.MM.YYYY', timeZone)}</css.date>
                ) : (
                    <Timer />
                )}
            </css.timer>
        </>
        
    );
}
