import request, {requestWithXHRReset} from '../../library/request';
import store from '../../store';
import setGlobalState from '../setGlobalState';

const getVideos = ()=> store.getState().management.videos;
export const getSupportVideos = async (pageType, routeType, id, search) => {
    const routeMap = {package: 'package', event: 'event'};
    const baseRoute = routeMap[routeType] || 'login';
    const path = `${baseRoute}/${id ? id + '/' : ''}supportCenter`;
    const res = await request('management.videos', 'get', path, {page: pageType, search});
    if(res?.videos)
        setGlobalState('management.videos', res.videos);
};

export const createVideo = async (newVideo)=>{
    const res = await requestWithXHRReset('management.createVideo', 'post', '/management/supportCenter', newVideo);
    if(res?.newVideo)
        setGlobalState('management.videos', [...getVideos(), res.newVideo]);
};
export const editVideo = async (videoId, newVideo)=>{
    const res = await requestWithXHRReset('management.editVideo', 'put', `/management/supportCenter/${videoId}`, newVideo);
    if(res?.newVideo){
        const videos = getVideos();
        const newVideos = videos.map(video=>video.id===newVideo.id?newVideo:video);
        setGlobalState('management.videos', newVideos);
    }
};

export const deleteVideo = async (videoId)=>{
    await requestWithXHRReset('management.deleteVideo', 'delete', `/management/supportCenter/${videoId}`);
    const videos = getVideos();
    setGlobalState('management.videos', videos.filter(video=>video.id!==videoId));
};