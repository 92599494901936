import * as css from './GridItemDrag.css';

function GridItemDrag ({children, isSourceDragging, isFloatingDragging, style,forwardRef, ...props} ) {

    return <css.item
        ref={forwardRef}
        $withOpacity={isSourceDragging}
        style={style}
        className={isFloatingDragging?'isDragging':''}
    >
        <css.dragHandle  {...props}/>
        <css.warpChildren>
            {children}
        </css.warpChildren>
    </css.item>;
}

export default GridItemDrag;
