export const emptyObject = (object) => {
    // if(typeof(object) === 'boolean')
    //     return false;
    // if(typeof(object) === 'number')
    //     return 0;
    if(typeof(object) !== 'object')
        return '';
    if(Array.isArray(object))
        return [];
    const empty = object ? {} : null;
    empty && Object.entries(object).forEach(([key,value]) => empty[key] =  emptyObject(value));
    return empty;
};