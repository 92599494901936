import React, {useCallback} from 'react';
import CodeMirror from '@uiw/react-codemirror';
import {javascript as Js} from '@codemirror/lang-javascript';
import {css as Css} from '@codemirror/lang-css';
import {html as Html} from '@codemirror/lang-html';
import {useField} from 'formik';

const languages = {js:Js, html:Html, css:Css};

function Editor({type, isExpanded, name, dataCyName}) {

    const [field, , helper]=useField(name);
    const onChange = useCallback((value) => {
        helper.setValue(value);
    }, [type]);

    return (
        <>
            {/*<css.globalStyle/>*/}
            <CodeMirror
                data-cy={dataCyName}
                value={field.value}
                height={isExpanded ? '300px' : '60px'}
                extensions={
                    [languages[type]({jsx: true})]
                }
                onChange={onChange}
            />
        </>
    );
}

export default Editor;