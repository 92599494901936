import {useTranslation} from 'react-i18next';
import InputList from '../../../../component/input/inputList/InputList';
import TimeZoneList from './timeZoneList/TimeZoneList';
import {useSelector} from 'react-redux';
import {updateEvent} from '../../../../redux/event/eventActions';
import store from '../../../../store';
import * as css from './LocaleStep.css';
import {DEFAULT_LANG, DEFAULT_TIME_ZONE} from '../../../../globalVariables/locals';
import DisabledInput from '../../../../component/input/disabledInput/DisabledInput';
import {changeTimezoneKeepLocalTime, combineDateTime} from '../../../../library/dates';

export const  onClickLocale= async(fields,_value,form)=>{
    const updateField = {...fields.locale};

    if(updateField.timeZone){
        const {timeOpen,endTime,startTime,endDate,startDate} = form.values.date;
        const timeZone = fields.locale.timeZone;
        const oldTimeZone = store.getState().event.timeZone;
        updateField.timeStart = changeTimezoneKeepLocalTime(combineDateTime(startDate,startTime),oldTimeZone,timeZone);
        updateField.timeEnd = changeTimezoneKeepLocalTime(combineDateTime(endDate,endTime),oldTimeZone,timeZone);
        updateField.timeOpen = changeTimezoneKeepLocalTime(combineDateTime(startDate,timeOpen),oldTimeZone,timeZone);

    }
    updateEvent(updateField).then((res)=>{
        form.setValues({...form.values, date:{...form.values.date,startDate: res.timeStart,startTime: res.timeStart, endDate: res.timeEnd, endTime: res.timeEnd, timeOpen:  res.timeOpen}});
    });
};

export const getTopLocale=(value)=>
     `${value.timeZone||DEFAULT_TIME_ZONE}, ${store.getState().languages.allLanguages?.find(l=>l.lang===value.lang)?.nativeName||DEFAULT_LANG}`;

function LocaleStep() {
    const {t} = useTranslation('newEvent');

    const event = useSelector(s=>s.event);
    const isReady = event.ready===5;
    const allLanguages=useSelector(s=>s.languages.allLanguages);

    const langList=allLanguages.map(lang=>({key:lang.lang,text:lang.nativeName}));

    return (
        <>
            <css.globalStyle/>
            <TimeZoneList/>
            {isReady ? <DisabledInput value={langList?.find(l=>l.key=== event.lang)?.text} text={t('lang')} /> :
                <InputList className="timeZoneList" list={langList} name="locale.lang" text={t('lang')}/>}

        </>

    );
}

export default LocaleStep;
