import React from 'react';

function NoEntriesImage({width='169', height='114'}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 169 114" fill="none">
            <g clipPath="url(#clip0_7813_12782)">
                <path d="M144.408 110.667C172.883 43.6009 118.346 41.5025 97.7262 16.5255C65.0369 -23.0699 -25.2445 36.9166 22.6732 110.667H144.409H144.408Z" fill="#EEEEEE"/>
                <path d="M22.7266 111.272C22.7266 69.0019 49.6325 66.377 49.6325 62.0321C49.6325 57.6871 22.7266 57.9291 22.7266 14.3608H92.4046C92.4046 57.9282 65.4986 57.6871 65.4986 62.0321C65.4986 66.377 92.4046 69.0019 92.4046 111.272H22.7266Z" fill="white"/>
                <path d="M28.2637 108.767H86.5532C86.5532 89.8189 73.4048 74.9741 73.4048 74.9741C73.4048 74.9741 67.8051 73.668 63.9266 75.7145C60.0482 77.7601 60.5639 80.4432 58.7948 81.8539C57.0267 83.2647 53.3612 82.9327 50.8811 84.5089C48.4009 86.0852 48.4762 87.7287 46.498 89.4569C44.5197 91.185 41.7598 89.5906 36.8436 92.8395C33.9845 94.7286 34.4571 97.4563 33.3496 97.6628C32.2412 97.8693 30.8585 96.1984 29.8382 97.7838C28.7646 99.451 28.7215 101.485 28.2637 108.767Z" fill="#FCE733"/>
                <path d="M95.4157 12.5122H20.9452C20.0883 12.5122 19.3936 12.9921 19.3936 13.584V14.2209C19.3936 14.8128 20.0883 15.2927 20.9452 15.2927H95.4157C96.2726 15.2927 96.9673 14.8128 96.9673 14.2209V13.584C96.9673 12.9921 96.2726 12.5122 95.4157 12.5122Z" fill="#8EEDFF"/>
                <path d="M95.4157 109.829H20.9452C20.0883 109.829 19.3936 110.309 19.3936 110.901V111.538C19.3936 112.13 20.0883 112.61 20.9452 112.61H95.4157C96.2726 112.61 96.9673 112.13 96.9673 111.538V110.901C96.9673 110.309 96.2726 109.829 95.4157 109.829Z" fill="#8EEDFF"/>
                <path d="M106.344 12.6427C106.36 17.6598 113.244 19.4034 124.857 22.3294C133.012 24.384 134.697 38.9486 138.167 39.3552C142.609 39.8755 147.791 28.3642 143.574 20.1283C131.526 -3.40446 106.325 7.02078 106.344 12.6427Z" fill="#EEEEEE"/>
                <path d="M102.646 34.9313C102.526 33.7607 102.413 30.9629 104.123 28.3452C106.47 24.7525 111.577 22.5305 115.197 24.2468C116.928 25.0681 118.951 27.09 118.592 29.2229C118.18 31.6805 114.756 33.3777 112.539 32.7356C109.188 31.7651 107.446 25.1236 109.438 21.1725C111.669 16.7503 119.139 14.5892 127.304 17.5134" stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M124.601 20.7258L129.954 18.3628L126.364 13.8433" stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11.291 60.5587C11.291 61.4273 10.5808 62.1313 9.70459 62.1313C8.82834 62.1313 8.11816 61.4273 8.11816 60.5587C8.11816 59.6901 8.82834 58.9861 9.70459 58.9861C10.5808 58.9861 11.291 59.6901 11.291 60.5587Z" fill="#DDAF56"/>
                <path d="M15.7617 60.5587C15.7617 61.4273 15.0515 62.1313 14.1753 62.1313C13.299 62.1313 12.5889 61.4273 12.5889 60.5587C12.5889 59.6901 13.299 58.9861 14.1753 58.9861C15.0515 58.9861 15.7617 59.6901 15.7617 60.5587Z" fill="#DDAF56"/>
                <path d="M20.2315 60.5587C20.2315 61.4273 19.5213 62.1313 18.645 62.1313C17.7688 62.1313 17.0586 61.4273 17.0586 60.5587C17.0586 59.6901 17.7688 58.9861 18.645 58.9861C19.5213 58.9861 20.2315 59.6901 20.2315 60.5587Z" fill="#DDAF56"/>
                <path d="M141.551 15.7043C141.087 15.7043 140.712 15.3314 140.712 14.872V11.2338C140.712 10.7736 141.088 10.4016 141.551 10.4016C142.015 10.4016 142.391 10.7745 142.391 11.2338V14.872C142.391 15.3323 142.015 15.7043 141.551 15.7043Z" fill="#0097B3"/>
                <path d="M141.551 26.4859C141.087 26.4859 140.712 26.113 140.712 25.6537V22.0155C140.712 21.5553 141.088 21.1824 141.551 21.1824C142.015 21.1824 142.391 21.5553 142.391 22.0155V25.6537C142.391 26.1139 142.015 26.4859 141.551 26.4859Z" fill="#A5ECF9"/>
                <path d="M148.824 19.276H145.154C144.69 19.276 144.314 18.9031 144.314 18.4438C144.314 17.9845 144.691 17.6116 145.154 17.6116H148.824C149.288 17.6116 149.664 17.9845 149.664 18.4438C149.664 18.9031 149.288 19.276 148.824 19.276Z" fill="#00AACA"/>
                <path d="M137.947 19.276H134.277C133.813 19.276 133.438 18.9031 133.438 18.4438C133.438 17.9845 133.814 17.6116 134.277 17.6116H137.947C138.411 17.6116 138.787 17.9845 138.787 18.4438C138.787 18.9031 138.411 19.276 137.947 19.276Z" fill="#51DFF9"/>
                <path d="M146.694 24.374C146.479 24.374 146.264 24.2931 146.1 24.1303L143.505 21.5581C143.178 21.2324 143.178 20.7058 143.505 20.3802C143.834 20.0546 144.365 20.0546 144.694 20.3802L147.288 22.9524C147.616 23.278 147.616 23.8046 147.288 24.1303C147.124 24.2931 146.91 24.374 146.695 24.374H146.694Z" fill="#8EEDFF"/>
                <path d="M139.003 16.7502C138.789 16.7502 138.573 16.6693 138.41 16.5065L135.815 13.9343C135.487 13.6087 135.487 13.082 135.815 12.7564C136.143 12.4308 136.675 12.4308 137.003 12.7564L139.598 15.3286C139.926 15.6542 139.926 16.1809 139.598 16.5065C139.434 16.6693 139.219 16.7502 139.004 16.7502H139.003Z" fill="#2BDCFD"/>
                <path d="M136.408 24.374C136.193 24.374 135.977 24.2931 135.814 24.1303C135.486 23.8046 135.486 23.278 135.814 22.9524L138.409 20.3802C138.737 20.0546 139.269 20.0546 139.597 20.3802C139.925 20.7058 139.925 21.2324 139.597 21.5581L137.002 24.1303C136.838 24.2931 136.623 24.374 136.409 24.374H136.408Z" fill="#77EAFF"/>
                <path d="M144.099 16.7501C143.885 16.7501 143.669 16.6692 143.506 16.5064C143.177 16.1808 143.178 15.6541 143.506 15.3285L146.101 12.7563C146.429 12.4316 146.96 12.4307 147.289 12.7563C147.617 13.0819 147.616 13.6086 147.289 13.9342L144.694 16.5064C144.53 16.6692 144.315 16.7501 144.1 16.7501H144.099Z" fill="#19B7D5"/>
                <path d="M64.4753 58.9761C70.9156 55.508 80.9681 55.2233 84.986 37.9401C84.986 37.9401 74.7344 44.6816 52.6308 32.9366C38.9677 25.6766 28.2637 32.9366 28.2637 32.9366C28.7215 40.2176 37.2785 52.8294 53.3208 58.766C59.6454 73.8954 50.881 84.5098 50.881 84.5098L62.9807 90.0735L64.679 75.367C64.679 75.367 58.0351 62.4451 64.4753 58.977V58.9761Z" fill="#FCE733"/>
                <path opacity="0.7" d="M62.0485 26.5015C61.8512 14.9612 79.2836 14.2117 79.0184 28.2742C78.7532 42.3367 60.6199 54.6093 59.5124 51.6296C58.4049 48.6499 62.3421 43.6956 62.0476 26.5015H62.0485Z" fill="white"/>
                <path d="M28.2637 108.767H86.5532C86.5532 89.8189 73.4048 74.9741 73.4048 74.9741C73.4048 74.9741 67.8051 73.668 63.9266 75.7145C63.1642 76.1165 63.9542 80.4869 63.9661 81.5438C69.9127 92.2046 78.588 102.479 28.2637 108.767Z" fill="#F7E34D"/>
                <path d="M64.4757 58.976C70.916 55.5079 80.9685 55.2232 84.9864 37.9399C84.9864 37.9399 82.0797 39.8509 76.0257 39.8655C76.2542 44.2522 73.3236 56.1964 60.5358 57.5916C56.6271 69.3812 63.705 79.6163 63.9665 81.5446L64.6804 75.3669C64.6804 75.3669 58.0364 62.445 64.4767 58.9769L64.4757 58.976Z" fill="#F7E34D"/>
                <path d="M82.8467 96.3576C83.233 98.3986 83.7973 101.243 86.6563 104.884C88.2014 106.852 90.6981 112.961 97.9393 113.169C111 113.543 114.528 110.858 117.078 107.603C117.078 107.603 127.611 91.0367 131.811 79.7092C135.919 68.63 122.859 61.8266 114.421 68.3107C106.595 74.3256 93.7324 96.3585 93.7324 96.3585H82.8476L82.8467 96.3576Z" fill="#3F0A20"/>
                <path d="M137.263 80.9206C134.02 72.1753 128.86 77.9118 127.979 84.7498L128.635 104.74L137.417 104.022C137.417 104.022 140.406 89.3939 137.263 80.9206Z" fill="#FBE6A4"/>
                <path d="M149.254 111.981C149.313 112.798 148.786 113.467 148.094 113.465L125.881 113.092C125.238 113.066 124.71 112.423 124.71 111.668C124.71 110.225 125.243 108.309 125.872 106.959C127.263 103.977 128.242 103.766 128.242 103.766L138.453 103.286C145.256 104.243 149.139 110.404 149.253 111.981H149.254Z" fill="#3F0A20"/>
                <path d="M149.254 111.981C149.313 112.798 148.786 113.467 148.094 113.465L125.881 113.092C125.238 113.066 124.71 112.423 124.71 111.668C124.71 110.225 125.243 108.309 125.872 106.959C127.263 103.977 128.242 103.766 128.242 103.766L138.453 103.286C145.256 104.243 149.139 110.404 149.253 111.981H149.254Z" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M140.663 108.621C140.663 108.621 140.456 105.33 142.523 103.848L140.663 108.621Z" fill="#3F0A20"/>
                <path d="M140.663 108.621C140.663 108.621 140.456 105.33 142.523 103.848" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M143.377 108.692C143.377 108.692 143.238 106.498 144.617 105.51L143.377 108.692Z" fill="#3F0A20"/>
                <path d="M143.377 108.692C143.377 108.692 143.238 106.498 144.617 105.51" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M124.753 111.151L149.254 111.981" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M132.835 105.17L128.305 110.137" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M134.011 105.329L129.48 110.296" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M135.569 105.308L131.038 110.275" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M126.949 99.0469L140.068 98.9542C140.068 98.9542 140.577 82.0093 134.898 71.3348C129.343 60.895 116.548 65.7111 115.632 76.2555C115.064 82.7897 126.949 99.0469 126.949 99.0469Z" fill="#3F0A20"/>
                <path d="M119.54 98.8658V97.6689C119.538 96.732 120.31 95.9653 121.256 95.9644L140.818 95.948C141.763 95.948 142.536 96.7129 142.537 97.6498V98.8467C142.539 99.7836 141.767 100.55 140.821 100.551L121.26 100.568C120.314 100.568 119.541 99.8027 119.54 98.8658Z" fill="white" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path d="M129.039 81.3637C125.796 72.6184 120.637 78.3549 119.755 85.1929L120.412 105.183L129.194 104.465C129.194 104.465 132.182 89.837 129.039 81.3637Z" fill="#FBE6A4"/>
                <path d="M98.5058 54.1281C95.6513 54.3437 91.4214 55.5516 88.0853 57.6945C78.3171 63.9676 73.2101 75.8591 72.1751 95.0724C72.0925 96.9597 88.6248 96.3585 88.6248 96.3585C93.3611 96.2057 98.0608 95.8173 102.683 96.3585C112.309 97.4854 109.157 59.4372 104.601 55.8863C101.747 53.6616 98.5058 54.1281 98.5058 54.1281Z" fill="#F5FDFF"/>
                <path d="M117.064 55.6134C113.514 55.6134 101.56 54.3892 101.56 54.3892L100.417 77.8582C100.417 77.8582 119.085 72.2063 124.379 71.0839C129.674 69.9615 122.816 55.6143 117.064 55.6143V55.6134Z" fill="#F5FDFF"/>
                <path d="M141.032 112.425C141.09 113.241 140.564 113.911 139.872 113.909L117.658 113.536C117.016 113.51 116.487 112.867 116.487 112.112C116.487 110.669 117.02 108.753 117.649 107.403C119.041 104.421 120.02 104.21 120.02 104.21L130.23 103.73C137.033 104.687 140.917 110.847 141.031 112.425H141.032Z" fill="#3F0A20"/>
                <path d="M141.032 112.425C141.09 113.241 140.564 113.911 139.872 113.909L117.658 113.536C117.016 113.51 116.487 112.867 116.487 112.112C116.487 110.669 117.02 108.753 117.649 107.403C119.041 104.421 120.02 104.21 120.02 104.21L130.23 103.73C137.033 104.687 140.917 110.847 141.031 112.425H141.032Z" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M132.44 109.065C132.44 109.065 132.233 105.774 134.3 104.291L132.44 109.065Z" fill="#3F0A20"/>
                <path d="M132.44 109.065C132.44 109.065 132.233 105.774 134.3 104.291" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M135.154 109.136C135.154 109.136 135.016 106.942 136.395 105.954L135.154 109.136Z" fill="#3F0A20"/>
                <path d="M135.154 109.136C135.154 109.136 135.016 106.942 136.395 105.954" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M116.529 111.594L141.031 112.425" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M124.612 105.613L120.082 110.58" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M125.789 105.772L121.258 110.74" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M127.346 105.751L122.815 110.719" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M94.7843 40.1193C94.0135 41.8319 94.7898 43.8402 96.5166 44.6042C98.2452 45.3692 100.271 44.5997 101.042 42.887C101.813 41.1743 101.036 39.166 99.3077 38.402C97.58 37.638 95.5541 38.4075 94.7834 40.1202L94.7843 40.1193Z" fill="#3F0A20"/>
                <path d="M94.3038 43.9639C93.5331 45.6766 94.3093 47.6849 96.0361 48.4489C97.7648 49.2129 99.7898 48.4444 100.561 46.7308C101.331 45.0181 100.557 43.0107 98.8282 42.2458C97.1005 41.4818 95.0745 42.2512 94.3038 43.9639Z" fill="#3F0A20"/>
                <path d="M104.318 43.7111C105.682 45.1409 105.552 48.3207 105.471 49.0493C104.789 55.1533 101.336 52.6938 101.336 52.6938C101.085 54.02 101.61 57.7964 101.61 57.7964C101.877 58.3103 101.07 62.2259 97.142 59.7392C96.0768 59.0643 94.2949 55.1705 94.2949 55.1705C94.8537 54.0281 96.4126 50.8957 98.7367 43.1736C99.4414 40.8306 102.495 41.7993 104.318 43.7111Z" fill="#FBE6A4"/>
                <path d="M98.9329 49.2375C98.9475 50.0306 99.6082 50.6618 100.408 50.6473C101.209 50.6327 101.845 49.9778 101.831 49.1847C101.817 48.3916 101.156 47.7604 100.356 47.7749C99.555 47.7895 98.9182 48.4443 98.9329 49.2375Z" fill="#FBDB98"/>
                <path d="M98.4443 50.9729C99.0261 52.561 100.157 53.7498 101.302 55.0122C101.128 53.4441 101.336 52.6938 101.336 52.6938C100.54 52.3354 99.3536 51.8333 98.4443 50.9729Z" fill="#DDAF56"/>
                <path d="M107.26 44.4376C107.192 47.6538 102.767 45.4163 102.562 45.3108C98.4326 43.1897 98.762 45.3481 98.762 45.3481C98.1408 46.2349 98.3683 49.7831 97.3673 48.2978C96.0571 46.355 95.6093 43.1179 96.1717 41.2469C96.7351 39.376 98.9712 38.6047 101.281 39.0686C104.909 39.798 107.327 41.2224 107.258 44.4376H107.26Z" fill="#3F0A20"/>
                <path d="M94.2631 47.9249C94.3705 46.959 95.2541 46.2486 96.2762 46.3423C97.2983 46.4351 97.0121 47.9304 98.0067 48.929C98.0067 48.929 96.6753 51.3348 96.7295 50.3188C96.7551 49.8349 93.9915 50.3688 94.264 47.9258L94.2631 47.9249Z" fill="#FBE6A4"/>
                <path d="M101.651 47.9504C101.674 48.1678 101.823 48.3297 101.984 48.3124C102.146 48.2961 102.258 48.1069 102.235 47.8895C102.212 47.673 102.063 47.5111 101.901 47.5275C101.74 47.5439 101.628 47.734 101.651 47.9504Z" fill="#3F0A20"/>
                <path d="M103.934 48.1696C103.96 48.4243 104.11 48.6171 104.27 48.6007C104.428 48.5844 104.535 48.3643 104.508 48.1096C104.482 47.8549 104.332 47.6621 104.172 47.6785C104.014 47.6948 103.907 47.9149 103.934 48.1696Z" fill="#3F0A20"/>
                <path d="M103.426 47.291C103.363 48.0105 103.169 48.8873 104.051 48.8955C104.685 48.9018 103.97 49.6904 103.359 49.5331M102.478 50.3762C103.102 51.1757 103.64 50.8492 103.64 50.8492" stroke="#DFAC5F" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path d="M100.874 39.478C100.467 40.383 100.878 41.4445 101.79 41.8474C102.703 42.2512 103.775 41.8456 104.182 40.9406C104.589 40.0356 104.178 38.9741 103.265 38.5703C102.353 38.1674 101.282 38.573 100.875 39.478H100.874Z" fill="#3F0A20"/>
                <path d="M103.019 40.9386C102.612 41.8436 103.021 42.9042 103.935 43.308C104.847 43.7109 105.917 43.3053 106.325 42.4003C106.732 41.4953 106.321 40.4338 105.409 40.0309C104.496 39.6271 103.426 40.0336 103.019 40.9386Z" fill="#3F0A20"/>
                <path d="M104.378 42.6085C103.971 43.5135 104.38 44.5741 105.294 44.9779C106.206 45.3808 107.277 44.9752 107.684 44.0702C108.092 43.1652 107.681 42.1037 106.769 41.7008C105.856 41.297 104.786 41.7035 104.378 42.6085Z" fill="#3F0A20"/>
                <path d="M97.8515 38.9641C97.3891 39.9919 97.8552 41.1979 98.892 41.6564C99.9279 42.1148 101.145 41.6527 101.607 40.6249C102.069 39.5971 101.603 38.3911 100.567 37.9327C99.5297 37.4743 98.314 37.9354 97.8506 38.9632L97.8515 38.9641Z" fill="#3F0A20"/>
                <path d="M118.725 99.4908L132.833 99.3907C132.833 99.3907 132.353 82.4531 126.673 71.7787C121.118 61.3389 108.323 66.155 107.407 76.6993C106.839 83.2335 118.724 99.4908 118.724 99.4908H118.725Z" fill="#3F0A20"/>
                <path d="M118.896 99.3099V98.1129C118.895 97.1761 119.666 96.4094 120.612 96.4085L132.595 96.3921C133.54 96.3921 134.314 97.157 134.315 98.0939V99.2908C134.317 100.228 133.545 100.994 132.599 100.995L120.616 101.012C119.671 101.012 118.897 100.247 118.896 99.3099Z" fill="white" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path d="M110.972 58.2546C111.351 58.7267 111.734 59.2597 112.104 59.8582C113.153 61.5572 113.723 63.1962 114.045 64.5087" stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M77.7402 96.3576C78.1265 98.3986 78.6908 101.243 81.5499 104.884C83.095 106.852 87.3753 111.587 94.5165 112.794C104.06 114.406 109.422 110.858 111.972 107.603C111.972 107.603 122.504 91.0367 126.705 79.7092C130.813 68.63 117.752 61.8266 109.315 68.3107C101.489 74.3256 88.6259 96.3585 88.6259 96.3585H77.7412L77.7402 96.3576Z" fill="#3F0A20"/>
                <path d="M114.045 104.052L120.082 93.7217" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M126.674 71.7788L126.761 71.9853C129.229 77.851 130.956 83.9977 131.903 90.2836L132.435 93.8181" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M131.409 103.508L131.746 101.234" stroke="#DFAC5F" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path d="M133.303 57.8191C130.198 55.658 114.342 47.9196 114.342 47.9196C114.342 47.9196 109.071 45.2437 107.095 46.0177C105.119 46.7917 103.014 52.2827 103.562 53.4806C103.929 54.2819 105.022 54.7567 106.538 55.145C106.538 55.145 107.605 54.5093 106.992 53.6807C106.377 52.853 107.197 52.2199 107.197 52.2199C107.197 52.2199 108.97 53.6697 110.397 53.2923C110.397 53.2923 120.428 65.9805 125.789 69.4067C131.856 73.2841 139.586 62.1913 133.304 57.8191H133.303Z" fill="#FBE6A4"/>
                <path d="M103.613 51.8806C102.591 54.5974 106.538 55.1459 106.538 55.1459C106.538 55.1459 107.941 54.1299 106.86 53.466" stroke="#DFAC5F" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path d="M105.395 47.6858L103.929 50.9147" stroke="#DFAC5F" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path d="M112.509 81.519L115.612 91.0538" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M73.3486 82.9836C73.3486 84.1923 77.2069 85.1728 81.9671 85.1728C86.7264 85.1728 91.4214 77.9592 91.4214 76.7495C91.4214 75.5407 87.6072 74.9668 82.847 74.9668C78.0877 74.9668 73.3496 81.7739 73.3486 82.9836Z" fill="#3F0A20"/>
                <path d="M89.7829 71.082C89.7829 71.082 91.8263 86.8527 92.5759 92.3282C92.5759 92.3282 86.3844 96.1101 82.8472 96.3584C80.335 96.5348 76.8685 89.2503 76.6116 88.2971C75.8776 85.5748 75.4399 84.3041 74.9353 80.4076C74.7096 78.6677 80.3616 66.779 82.1508 67.8604C88.0589 71.4304 89.7838 71.0829 89.7838 71.0829L89.7829 71.082Z" fill="#FBE6A4"/>
                <path d="M91.4205 76.7495C86.2392 76.322 82.302 81.058 73.3486 82.848C74.2524 70.9229 79.8035 62.3941 88.0843 57.6945C90.4956 56.3265 93.0611 68.8437 91.4205 76.7495Z" fill="#F5FDFF"/>
                <path d="M84.7133 90.8465C92.1123 100.569 103.782 78.8841 107.467 78.0628C111.153 77.2414 115.789 75.788 115.89 73.3267C115.934 72.2671 115.962 70.8637 115.916 69.6294C115.886 68.8145 113.465 66.6907 112.712 66.6661C108.439 66.5242 105.794 68.3833 104.606 69.3738C104.255 69.6667 90.1396 76.9231 83.5709 81.8374C80.5229 84.1176 84.7133 90.8474 84.7133 90.8474V90.8465Z" fill="#FBE6A4"/>
                <path d="M111.265 71.7133C111.078 72.6393 110.42 73.3714 113.473 73.326C119.618 73.2341 122.516 77.2234 123.763 75.608C124.619 74.4993 121.569 70.5819 118.542 69.9797C118.542 69.9797 111.434 70.8766 111.265 71.7133Z" fill="#FBE6A4"/>
                <path d="M111.739 66.6679C111.552 67.5938 114.322 71.5604 117.358 71.2384C120.924 70.8609 125.216 75.3095 126.401 73.6487C127.065 72.7173 124.206 68.6225 121.179 68.0204C121.179 68.0204 111.909 65.8311 111.74 66.6679H111.739Z" fill="#FBE6A4"/>
                <path d="M113.914 72.4063C111.961 71.7923 112.475 72.4326 112.191 73.022C111.877 73.6742 107.952 78.1182 110.16 77.3706C115.58 75.5351 119.463 78.4438 120.695 77.628C121.749 76.9304 119.857 74.2763 113.914 72.4063Z" fill="#FBE6A4"/>
                <path d="M116.697 64.2904C113.854 63.6192 111.72 64.234 106.98 67.8331C105.11 69.2529 109.736 69.6231 109.397 70.1834C109.022 70.8019 115.176 67.2074 116.085 67.553C121.023 69.4321 124.844 72.6092 125.808 71.082C127.002 69.1911 118.246 64.6561 116.697 64.2904Z" fill="#FBE6A4"/>
                <path d="M122.22 72.4308L122.089 72.3144C120.468 70.8791 118.413 70.0187 116.245 69.8686L114.27 69.7322" stroke="#DFAC5F" strokeMiterlimit="10"/>
                <path d="M122.453 68.4898C120.577 67.1555 118.372 66.3488 116.072 66.1559L115.94 66.145" stroke="#DFAC5F" strokeMiterlimit="10"/>
                <path d="M91.9536 75.2678V72.1417C91.9536 69.7123 91.7224 67.2883 91.2627 64.9026" stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M132.595 96.3921C133.54 96.3921 134.313 97.157 134.314 98.0939V99.2908C134.316 100.228 133.544 100.994 132.598 100.995" stroke="#3F0A20" strokeWidth="2" strokeMiterlimit="10"/>
            </g>
            <defs>
                <clipPath id="clip0_7813_12782">
                    <rect width="169" height="114" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default NoEntriesImage;
