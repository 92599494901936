import styled from 'styled-components';
import {BGImageCenter, boxShadow} from '../../globalVariables/GlobalVariables';
import {drag} from '../../assets/symbols/symbols/Symbols.css';
import zIndex from '../../globalVariables/zIndex';

export const warpChildren =styled.div`
        transition: all 300ms;
        z-index: ${zIndex.baseRaised};
`;

export const dragHandle =styled.div`
    position: absolute;
    inset-inline-start: 0;
    height: 100%;
    width: 5%;
    background-color: white;
    top:0;
    ${boxShadow}
    ${BGImageCenter(drag,'12px')}
    border-radius: 0 5px 5px 0;
`;

export const item =styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
    width:max-content;
    transform-origin: 50% 50%;
    background-color: white;
    opacity: ${p=>p.$withOpacity?'0.5':'1'};
    cursor: grab;
    border-radius: 5px;
    transform:  scale(1);
    &.isDragging{
        cursor:grabbing;
        box-shadow:  rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px ;
        transform:  scale(1.1) ;
    }
    &:hover{
            ${warpChildren}{
            transform: scaleX(0.95);  
            margin-left: -2.5%;

        }
    }
    `;

