import styled from 'styled-components';
import {DARK_GREEN2, GRAY3, GRAY3_BRIGHT, LIGHT_GREEN2, mobileMedia} from '../../../../../../globalVariables/GlobalVariables';
import {dFlexCenter} from '../../../../../../globalVariables/GlobalFlex';

export const animation = styled.div`
    position: relative;
    width: 40px;
    height: 100%;
    ${dFlexCenter};
`;

export const status = styled.div<{ $active: boolean }>`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${props => props.$active ? DARK_GREEN2 : GRAY3};
    box-shadow: 0 0 0 6px ${props => props.$active ? LIGHT_GREEN2 : GRAY3_BRIGHT};
    position: relative;
    ${mobileMedia}{
        width: 6px;
        height: 6px;
        box-shadow: 0 0 0 3px ${props => props.$active ? LIGHT_GREEN2 : GRAY3_BRIGHT};
    }
`;
