import styled from 'styled-components';
import {BGImageCenter} from '../../../globalVariables/GlobalVariables';

export const tooltip = styled.div`
  opacity: ${p => p.$show ? 1 : 0};
  width: 100%;
  height: 100%;
  left:0;
  top:0;
  ${p => p.$show && ' pointer-events: none;'}
  position:absolute;
  &:hover {
    opacity: 1;
  }
  ${p => (p.$icon ? `
        ${BGImageCenter(p.$icon,'10px 10px')}
        padding-inline-end:26px;
        background-position:`+ (p.theme.rtl ? '9px' : 'calc(100% - 9px)') + ` center;
    `: '')}

  
  &::before {
    content: "${p=>p.$dataText}";
    position: absolute;
    visibility: visible;
    ${p=>p.$bottom?'top':'bottom'}: 20px;
    font-weight: 600;
    border-radius: 3px;
    box-sizing: border-box;
    font-size: 11px;
    height: max-content;
    max-width: 240px;
    width: max-content;
    min-width: 50px;
    text-align: center;
    padding: 2px 6px;
    overflow-wrap: break-word;
    background-color:#000000 ;
    color: #FFFFFF;
    inset-inline-end: -15px;
  }
  &::after {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    background-color: #000000;
    transform: rotate(45deg);
    inset-inline-end: 5px; 
    ${p=>p.$bottom?'top':'bottom'}: 17px;
  }
  
`;

