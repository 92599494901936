import {useState, useRef} from 'react';
import * as css from './searchVideos.css';
import useTimeout from '../../../hooks/useTimeout';
import Ex from '../../../assets/icons/ex';
import {useTranslation} from 'react-i18next';
import Search from '../../../assets/icons/search';
import useEffectOnUpdate from '../../../hooks/useEffectOnUpdate';

function SearchVideos({getSearch, title}) {
    const {t} = useTranslation('layout');

    const [showInput, setShowInput] = useState(false);
    const [value, setValue] = useState('');
    const timer :any= useTimeout();

    const ref=useRef(0);
    const handleSearchClick = (e) => {
        if(e.target!==e.currentTarget && showInput) return;
        setShowInput(prev=>!prev);
        e.stopPropagation();
    };
    const closeInput = () => {
        setImmediate(()=>setShowInput(false));
    };
    const handleSearch=(e)=>{
        setValue(e.target.value);
        ref.current++;
    };
    const resetSearch = ()=>{
        setValue('');
    };
    useEffectOnUpdate(() => {
        const thisCount=ref.current;
        timer.current=setTimeout(function() {
            if(thisCount !== ref.current)
                return;
            if(getSearch)
                getSearch(value);
        },700);
    },[value]);
    
    return (
        <css.container data-cy={'input-search'} className={'search ' + (showInput && 'focus')} onClick={handleSearchClick}>
            {showInput ? <css.input
                autoFocus={true} 
                value={value}
                onChange={handleSearch} 
            />
                : <css.titles>
                    <css.title>{title}</css.title>
                    <css.titleExplain>{t('videoExplanation')}</css.titleExplain>
                </css.titles>}
            <css.actionButton onClick={resetSearch} className={'searchClose ' + (showInput && 'close')} >     
                {showInput? <span onClick={closeInput}> 
                    <Ex width={12} height={12} color='#000000' />
                </span> :<>{t('searchVideos')}<Search  /></>}
               
            </css.actionButton>
        </css.container>
    );

}

export default SearchVideos;
