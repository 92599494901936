import * as css from './Ambassadors.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {XHR_LOADING_STATE} from '../../../../../assets/constants/constants';
import TicketsTemplate from '../tickets/TicketsTemplate';
import {useEffect, useRef, useState} from 'react';
import {getGivechakLeads} from '../../../../../redux/event/eventActions';
import GenericPieChart from '../../genericPieChart/GenericPieChart';
import {formatNumber} from '../../../../../library/numberFunction';
import AmbassadorRow from './components/AmbassadorRow';
import CustomScrollbar from '../../../../../component/customScrollbar/CustomScrollbar';
import useMobile from '../../../../../hooks/useMobile';
import zIndex from '../../../../../globalVariables/zIndex';

const Ambassadors = () => {
    const {t} = useTranslation('realtime');
    const {eid, givechakLeads, currencySymbol} = useSelector((s:any) => ({
        eid: s.event.eid,
        givechakLeads: s.event.givechakLeads,
        currencySymbol: s.event.currencySymbol
    }));
    const achievements = useSelector((s:any) => (s.realtime?.event?.targets?.withRef));
    const getGivechakLeadsXHR = useSelector((s:any) => s.event.getGivechakLeadsXHR);
    const getTargetsXHR = useSelector((s:any)=>s.realtime?.event?.getTargetsXHR);

    const isLoading = (getGivechakLeadsXHR === XHR_LOADING_STATE || getTargetsXHR === XHR_LOADING_STATE);
    const [activeIndex, setActiveIndex] = useState(null);
    const tableRef = useRef(null);
    const isMobile = useMobile();

    const totalLeads = givechakLeads?.references?.allRefs || 0;
    const activeLeads = givechakLeads?.references?.activeRefs || 0;
    const target = givechakLeads?.totalTarget || 0;

    const [offset, setOffset] = useState(0);
    
    const onScroll = (e) => {
        const scrollHeight = Math.round(e.target.scrollHeight - e.target.scrollTop);
        const clientHeight = Math.round(e.target.clientHeight);
        if((scrollHeight === clientHeight || Math.abs(scrollHeight - clientHeight) === 1) && !isLoading) {
            setOffset(givechakLeads?.leads?.length || 0);
        }
    };

    const ambassadorData = [
        {
            sold: achievements, 
            percentage: achievements/(target==0 ? achievements : target),
            color: '76D6E8',
            title: t('reached')
        },
        {
            sold: target - achievements, 
            percentage: (target==0 ? 0: target - achievements)/(target==0 ? 1 : target),
            color: 'FFEB00',
            title: t('leftToGoal')
        }
    ];
    const inlineRect = (
        <>
            <css.chartValue $bigValue={ambassadorData[0].percentage > 100}>
                {`${((ambassadorData[0].percentage || 0) * 100).toFixed(ambassadorData[0].percentage < 1 ? 1 : 0)}%`}
            </css.chartValue>
            <css.chartAmount>
                {`${formatNumber(achievements)}${currencySymbol}`}
            </css.chartAmount>
        </>
    );
    useEffect(() => {
        if(getGivechakLeadsXHR !== XHR_LOADING_STATE)
            getGivechakLeads(eid, 4, offset);
    }, [eid, offset]);

    if(isLoading && !givechakLeads)
        return <TicketsTemplate />;

    return (
        <css.container>
            <css.title>{t('ambassadors')}
                <css.ticketsPage className={'free-color'}>
                    <Link to={`/e/${eid}/publishGivechak`}>
                        {t('leadsPage')}  
                    </Link>
                </css.ticketsPage>
            </css.title>       
            <css.data>
                <css.chartWrap>
                    <css.chart>
                        <GenericPieChart
                            data={ambassadorData}
                            dataKey="percentage"
                            activeIndex={{activeIndex, setActiveIndex}}
                            onMouse={{onMouseEnter: (index) => setActiveIndex(index), onMouseLeave: () => setActiveIndex(null)}}
                            hoverState={true}
                            inlineRect={inlineRect}
                        />
                    </css.chart>
                    <css.summaryContainer>
                        <css.summaryTitle>
                            <css.dot $color={ambassadorData[1].color}/> 
                            {`${((ambassadorData[1].percentage || 0) * 100).toFixed(0)}% ${t('leftToGoal')} ${formatNumber(target, true)}${currencySymbol}`}
                        </css.summaryTitle>
                        <css.summaryTitle>
                            <css.dot $color={ambassadorData[0].color}/>
                            {`${((ambassadorData[0].percentage || 0) * 100).toFixed(0)}% ${t('reached')} ${formatNumber(achievements)}${currencySymbol}`}
                        </css.summaryTitle>
                        <css.summary className='free-color'>
                            <span>{totalLeads}</span>{` ${t('ambassadors')} `}<span><css.smallDot $color={'40C82B'}/>{activeLeads}</span>{` ${t('activeLeads')}`}
                        </css.summary>
                    </css.summaryContainer>
                </css.chartWrap>
                <css.table onScroll={onScroll} ref={tableRef}>
                    {givechakLeads?.leads?.map((lead, index) => (
                        !!lead.title && <AmbassadorRow lead={lead} key={index} />
                    ))}
                </css.table>
                {!isMobile && <CustomScrollbar contentRef={tableRef} cssStyle={`z-index: ${zIndex.baseHigh}; inset-inline-start: 0px; inset-block-start: 20px;`} />}
            </css.data>
        </css.container>
    );
};

export default Ambassadors;