import CircleProgress, {percentage} from '../../../../../component/circleProgress/CircleProgress';
import * as css from './TicketProgress.css';

function TicketProgress({ticket, onClick, children, styleProps: styleP = {}, totalValue = null, partialValue = null}) {
    const color = `#${ticket.color}`;
    const styleProps = {pathColor: color, trailColor: '#FFF', pathTransitionDuration: 0.7, strokeWidth: 7, strokeTrailWidth: 0, ...styleP};
    totalValue = totalValue || (ticket.totalSold ?? ticket.amount);
    partialValue = partialValue || ticket.sold;
    return (
        <>
            <css.circleWrap className='circleWrap' color={color} onClick={onClick}>
                <CircleProgress totalValue={totalValue} partialValue={partialValue} styleProps={styleProps}>
                    <css.circle className='ticketProgress_circle' color={color}>{percentage(totalValue, partialValue)}
                        <css.percent>%</css.percent>
                        {children}
                    </css.circle>
                </CircleProgress>
            </css.circleWrap>
        </>

    );
}
export default TicketProgress;
