import styled from 'styled-components';
import {BGImageCenter, GRAY2, maskImage, mobileMedia, VERY_PALE_GREEN, VERY_PALE_BLUE, DARK_GREEN2, tabletMedia} from '../../../../globalVariables/GlobalVariables';
import realtimeFonts from '../../fonts';
import {getColor} from '../../eventRealtime/EventRealtime.css';
import {animate} from '../../../../component/animation/Animation.css';
import {alignEnd} from '../../../../globalVariables/GlobalFlex';

export const wrapper = styled.div<{$compact?: boolean}>`
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 443px;
    ${tabletMedia}{
        height: 100%;
    }
    ${mobileMedia}{
        width: 100%;
        padding-inline: 10px;
    }
    ${p => p.$compact && `
        height: 120px;
        padding: 15px 20px;
        ${tabletMedia}{
            height: 120px;
            padding: 10px;
        }
        ${mobileMedia}{
            height: 187px;
        }
    `}
    transition: all 400ms;
    overflow: hidden;
`;

export const header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const title = styled.div`
    ${realtimeFonts.windowTitle}
`;

export const buttons = styled.div`
    display: flex;
    gap: 10px;
`;
export const wrapButtons = styled.div`
    display: flex;
    gap: 10px;
    ${mobileMedia}{
        width: 100%;
        justify-content: space-between;
    }
`;
export const button = styled.div<{ image?: string}>`
    position: relative;
    inset-block-start: 5px;
    height: 20px;
    width: 20px;
    ${p=> p.image && maskImage(p.image, getColor(p.theme.isDarkMode), '20px 30px')};
    cursor: pointer;
    &.disabled{
        opacity: 0.3;
        cursor: default;
    }
`;
export const icon = styled.div<{image?: string}>`
    height: 20px;
    width: 20px;
    ${p=>BGImageCenter(p.image, '15px 15px')}
    margin-inline-end: 5px;
`;

export const content = styled.div`
    display: grid;
    grid-template-areas: 
    "autoUpdate update ."
    "chart terminals tickets";
    grid-template-columns: 5fr 2fr 3fr;
    grid-template-rows: 1fr 8fr;
    gap: 10px;
    height: 400px;
    ${alignEnd};
    ${tabletMedia} {
        grid-template-areas: 
        "autoUpdate"
        "chart"
        "update"
        "terminals"
        "tickets";
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        height: 100%;
    }
`;
export const update = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    grid-area: update;
    width: max-content;
    height: 30px;
    padding: 4px 10px 4px 12px;
    border-radius: 16px;
    background: ${VERY_PALE_BLUE};
    color: ${GRAY2};
    text-align: center;
    ${realtimeFonts.text}
    transition: all 1.5s;
`;
export const autoUpdate = styled(update)<{$newEnter: number}>`
    grid-area: autoUpdate;
    ${p=>p.$newEnter ? `
        background: ${VERY_PALE_GREEN};
        color: ${DARK_GREEN2};
    ` : ''}
    ${animate}{
        top: 0px;
        height: 50px;
        width: 100px;
    }
`;

export const link = styled.span`
    cursor: pointer;
    text-decoration: underline;
`;

export const section = `
    background: ${VERY_PALE_BLUE};
    border-radius: 10px;
    ${mobileMedia}{
        height: 200px;
    }
`;
