import {useTranslation} from 'react-i18next';
import * as css from './DateTopStep.css';
import {useField} from 'formik';
import {getHourMinutes, tsToDate} from '../../../../../library/dates';
import {useSelector} from 'react-redux';

function DateTopStep() {
    const {t} = useTranslation('newEvent');

    const [dateField]=useField('date');
    // const [{value:timezone}]=useField('locale.timeZone');
    const timezone=useSelector(s=>s.event.timeZone);
    const {startDate,startTime,endDate,endTime,duration}=dateField.value;
    return (
        <css.wrapper>
            <css.startText>{t('start')}</css.startText>
            <css.startDate>
                <span>{duration?getHourMinutes(startTime,timezone):''}</span>
                {tsToDate(startDate,'',timezone)}
            </css.startDate>
            <css.endText>{t('end')}</css.endText>
            <css.endDate>
                <span>{duration?getHourMinutes(endTime,timezone):''}</span>
                {tsToDate(endDate,'',timezone)}
            </css.endDate>
        </css.wrapper>

    );
}

export default DateTopStep;
