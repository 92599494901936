import styled from 'styled-components';
import {BLACK, dFlex, mobileMedia, scrollbar} from '../../../../globalVariables/GlobalVariables';
import {wrapper} from '../stepRight/StepRight.css';
import {wrapper as wrapperLeft} from '../StepLeft/StepLeft.css';
import {iframe} from '../../../../layout/myIframe/MyIframe.css';
import {checkbox} from '../../../../component/checkbox/Checkbox.css';
import {btn} from '../../../../component/button/btn/Btn.css';
import {text, wrapCheckbox} from '../../../../component/checkboxFormik/CheckboxFormik.css';
import {alignStart, flexRow, justifyBetween, justifyEnd} from '../../../../globalVariables/GlobalFlex';

export const wrap = styled.div`
    width: 100%;
    padding-block-end: 15px;
    ${p => p.$hasPop && `
        .arrow {
            display: none;
        }
    `}
    input{
         color: ${BLACK};
    }
    ${wrapper}{
        width: 100%;
    }
    ${btn}{
        border-color:${BLACK} ;
        &:hover{
            color:${BLACK};
            border-color:${BLACK};
        }
    }
    ${wrapperLeft}{
        width: 100%;
        ${flexRow};
        ${alignStart};
        ${p=>p.$hasPop?justifyEnd:justifyBetween};
        ${mobileMedia}{
            ${dFlex};
            ${flexRow};
            gap: 10px;
        }
    }
`;
export const wrapContent=styled.div`
    height:350px;
    position: relative;
    ${iframe}{
        height: calc(100% - 40px);
    }
    ${mobileMedia}{
        height:250px;
    }
`;

export const wrapSvg=styled.div`
    display:flex;
    padding-block:15px;
    ${mobileMedia}{
    display:grid;
    grid-template-columns: repeat(3 , 180px);
    gap: 10px;
        ${scrollbar};
        padding-inline:10px;
    }
`;
export const wrapButtons = styled.div`
    ${btn}{
        margin-bottom: 10px;
    }
    ${wrapCheckbox}{
        padding-inline-start:0;
    }
    ${mobileMedia}{
        display:unset;
        ${btn}{
            width: 90px;
        }
    }
    ${text}{
        font-weight: 600;
        font-size: 12px;
        width:max-content;
        margin-inline-start: 5px;
    }
    ${wrapCheckbox}{
        width:unset;
        input[type=checkbox]:before {
         content: unset;
        }
    }
    ${checkbox}{
        &.selected{
            &::before{
                background:black;
                right: calc(50% - 3px);
                top: calc(50% - 3px);
            }
        }
    }
`;

