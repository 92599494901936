import styled from 'styled-components';
import {BLACK, desktopMedia, dFlexCenter, GREEN, mobileMedia, SHADOW_COLOR, tabletMedia, WHITE} from '../../../../../../../globalVariables/GlobalVariables';
import realtimeFonts from '../../../../../fonts';
import {ticketsInfo} from '../ValidationsChart.css';
import {dFlexColumnCenter, justifyBetween} from '../../../../../../../globalVariables/GlobalFlex';

export const statsSection = styled.div`
    position: relative;
    padding-block-start: ${p => p.theme.$compact ? '0' : '50px'};
    padding-inline-end: ${p => p.theme.$compact ? '0' : '20px'};
    width: ${p => p.theme.$compact ? '100%' : '50%'};
    ${mobileMedia}{
        padding-block-start: 0px;
        padding-inline-end: unset;
        width: 70%;
        position: relative;
        inset-inline-start: 15%;
    }
    ${tabletMedia}{
        ${p => p.theme.$compact && 'display: none;'}
    }
`;
export const validationsInfo = styled(ticketsInfo)`
    ${mobileMedia}{
        width: 100%;
        flex-direction: row-reverse;
        position: relative;
        inset-inline-start: 0px;
    }
`;
export const validationsRow = styled.div`
`;
export const totalValidations = styled.div`
    ${realtimeFonts.windowTitle}
    font-weight: 700;
    height: 24px;
    ${mobileMedia}{
        height: 18px;
    }
`;

export const validationText = styled.div`
    ${realtimeFonts.windowTitle}
`;

// eslint-disable-next-line spellcheck/spell-checker
export const validationGraph = styled.div`
    position: relative;
    max-height: 80px;
    margin-top: 30px;
    & .recharts-cartesian-axis-line, .recharts-cartesian-axis-tick-line {
        display: none;
    }
    & .recharts-cartesian-axis-tick-value{
        color: ${BLACK};
    }
    & .recharts-cartesian-axis-ticks {
        position: relative;
        inset-block-start: 10px;
    }
    & .recharts-responsive-container{
        height: 80px !important;
    }
`;

export const customTooltip = styled.div<{$isLast?: boolean }>`
    background-color: ${BLACK};
    border-radius: 3px;
    padding: 5px;
    position: relative;
    padding-inline: 10px;
    width: 'max-content';
    border-radius: 2px;
    box-shadow: 2px 4px 4px 2px ${SHADOW_COLOR};
    right: ${props => props.$isLast ? '-15px' : '15px'};
    &::before{
        content: '';
        display: block;
        height: 10px;
        width: 10px;
        transform: rotate(45deg);
        background-color: ${BLACK};
        position: absolute;
        inset-block-start: -3px;
        left: calc(50% - 5px);
    }
`;

export const tooltipText = styled.div`
    color: ${WHITE};
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
    text-align: center;
    position: relative;
    inset-inline-start: 5px;
    &::before{
        content: '';
        display: block;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: ${GREEN};
        position: absolute;
        inset-block-start: 4px;
        inset-inline-start: -10px;
    }
`;
export const noEntries = styled.div`
    line-height: 24.206px;
    letter-spacing: 1.32px;
    text-align: center;
    height: 100%;
    width: 90%;
    inset-inline-start: 5%;
    ${p => p.theme.$compact ? dFlexCenter: dFlexColumnCenter};
    ${justifyBetween};
    ${desktopMedia}{
        ${p=>!p.theme.$compact ? 'padding-bottom: 30px' : ''};
    }
    ${mobileMedia}{
        position: relative;
        width: 70%;
        inset-inline-start: 15%;
        gap: 20px;
        margin-bottom: 20px;
    }
`;
export const text = styled.div`
    ${p => p.theme.$compact ? realtimeFonts.text : realtimeFonts.windowTitle}
    ${mobileMedia}{
        font-size: 16px;
    }
`;