import styled from 'styled-components';
import {dFlex, mobileMedia, scrollbar} from '../../../../../../globalVariables/GlobalVariables';
import {firstPart} from '../../../../../tickets/components/ticketRow/rightPart/RightPart.css';
import {section} from '../../EventDay.css';
import realtimeFonts from '../../../../fonts';

export const wrapper = styled.div`
    grid-area: tickets;
    height: 314px;
    ${section}  
    padding: 15px;
    ${dFlex}
    flex-direction: column;
    gap: 5px;
    ${mobileMedia}{
        height: max-content;
    }
`;

export const title = styled.div`
    ${realtimeFonts.textBold}
`;

export const list = styled.div`
    ${dFlex}
    flex-direction: column;
    gap: 10px;
    ${scrollbar}
    ${firstPart}{
        height: 58px;
    }
`;
