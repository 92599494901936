import {useSelector} from 'react-redux';
import * as css from './EventMenuMenu.css';
import {menuEvent} from '../../menu';
import MenuItem from '../menuItem/MenuItem';
import {hasEventRoleAccess} from '../../../functions/permission';

function EventMenuMenu(props) {

    const menu=Object.entries(menuEvent).filter(([,item]) => item.menu);
    const event=useSelector(state=>state.event);

    return (
        <css.Menu ref={props.containerRef}>
            {menu.map(([key, item]) => {
                if(item.menu.indexOf('main')!==-1
                 && (!item.events || item.events && item.events.indexOf(event.type) !== -1)
                 && hasEventRoleAccess(item.access) )
                    return <MenuItem miniDisplay={props.miniDisplay} closeMenu={props.closeMenu} item={item} key={key} />;

            })}
        </css.Menu>
    );
}

export default EventMenuMenu;
