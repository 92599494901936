import styled from 'styled-components';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';
import {btn} from '../../../../component/button/btn/Btn.css';
import {ex} from '../../../../assets/symbols/symbols/Symbols.css';
import {flexColumn} from '../../../../globalVariables/GlobalFlex';
import {icon} from '../../../../component/classes/input';

export const wrap = styled.div`
  ${flexColumn};
  margin-block: 35px;
  padding: 10px;
  width: 100%;
  height: 115px;
  margin-block-end: 10px;
  ${btn}{
    margin-right: auto; 
  }
  ${icon}{
    width:unset;
    height:unset;
  }
`;

export const title = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const back = styled.div`
    cursor: pointer;
    width: 14px;
    height: 14px;
    ${BGImageCenter(ex,'14px')}
`;
