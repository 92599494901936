import * as css from './supportVideos.css';
import {useState} from 'react';
import Camera from '../../../assets/icons/camera';
import Video from '../../../component/video/Video';
import {TSupportVideos} from '../../../redux/management/management.type';
import {useTranslation} from 'react-i18next';
import {deleteVideo} from '../../../redux/management/managementActions';
import MasterPermission from '../../../pages/components/rolePermission/MasterPermission';
import {pen, remove} from '../../../assets/symbols/symbols/Symbols.css';

export const getPageTranslation = (t, pageTitle)=>pageTitle.split('_').map(item=>t(item)).join(' > ');

function VideoCard({video, editVideo, openWidget}:{video:TSupportVideos; editVideo: any; openWidget: any;}) {
    const [expended, setExpended] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const {t} = useTranslation('layout');
    
    const openFullscreen = (e, isOpen) => {
        if(e.target===e.currentTarget)        
            setFullscreen(isOpen);
    };
    const deleteV=()=>{
        deleteVideo(video.id);
    };
    
    return (
        <css.video $hide={Boolean(video.hide)} $expended={expended} onClick={()=>setExpended(prev=>!prev)} key={video.id}>
            <css.videoTitle>{video.title}
                <div>
                    <MasterPermission>
                        <css.iconBtn expended={expended} icon={remove} onClick={deleteV}/>
                        <span onClick={()=>editVideo(video.id)}>
                            <css.iconBtn expended={expended} icon={pen}/>
                            {expended?t('edit'):''}</span> 
                    </MasterPermission>
                    {!expended && <Camera />}
                </div>
            </css.videoTitle>
            <css.videoContent $expended={expended}> 
                <css.videoWrapper onClick={e=>openFullscreen(e, false)} className={fullscreen && 'show'} $fullscreen={fullscreen}>
                    <div>
                        <Video video={video.videoLink}/>
                        <css.overlay $fullscreen={fullscreen} onClick={e=>openFullscreen(e, !fullscreen)} />
                    </div>
                </css.videoWrapper>
                {video.description}
                <css.permissions>{t('permissionsDesc')}-{video.permission.map(per=>t(per)).join('#')}</css.permissions>
                <css.openWidgetLink  onClick={openWidget}>{t('contactSupport2')}</css.openWidgetLink>
            </css.videoContent>
        </css.video>
    );
}
export default VideoCard;
