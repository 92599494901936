import styled from 'styled-components';
import {DARK_GRAY, DARK_GRAY2, dFlexCenter, resolveImage, SHADOW_COLOR, SKY_BLUE} from '../../globalVariables/GlobalVariables';
import {arrowDown} from '../../assets/symbols/symbols/Symbols.css';

export const container = styled.div`
    position: relative;
`;

export const customScrollbar = styled.div<{ $cssStyle?: string }>`
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    width: 17px;
    height: 100%;
    ${p=>p.$cssStyle}
`;

export const scrollbarThumb = styled.div`
    position: absolute;
    inset-inline-end: 0px;
    width: 100%;
    height: 36px;
    background-color: ${DARK_GRAY2};
    border-radius: 50px;
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 3px 6px ${SHADOW_COLOR};
`;

export const arrow = styled.div`
    width: 100%;
    height: 15px;
    background: none;
    border: none;
    cursor: pointer;
    ${dFlexCenter};
    background-color: ${SKY_BLUE};
    -webkit-mask-image: url("${resolveImage(arrowDown)}");
    mask-image: url("${resolveImage(arrowDown)}");
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 6px;
    &.up{
        transform: rotate(180deg);
    }
    &.disabled{
        opacity: 0.2;
        background-color: ${DARK_GRAY};
        cursor: default;
    }
`;
