const Headphones = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
    >
        <path
            fill="#fff"
            d="M13.5 6.4a3 3 0 0 1-.592 5.46A4 4 0 0 1 9 15H7.5a.5.5 0 0 1 0-1H9a3 3 0 0 0 2.83-2h-.33a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 .5-.5h.5q.243 0 .477.038a4.5 4.5 0 0 0-8.954 0Q3.761 6 4 6h.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5H4a3 3 0 0 1-1.5-5.6 5.5 5.5 0 1 1 11 0M4 7a2 2 0 0 0 0 4zm10 2a2 2 0 0 0-2-2v4a2 2 0 0 0 2-2"
        />
    </svg>
);

export default Headphones;
