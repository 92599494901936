import SettingItem from '../settingItem/SettingItem';
import MasterPermission from '../../components/rolePermission/MasterPermission';
import AddGooglePingItem from '../addGooglePingItem/AddGooglePingItem';
import {useSelector} from 'react-redux';
import {bounceMessageBlock} from '../../../layout/userMessagesBlock/UserMessageBlock';
import {updateEventSettings} from '../../../redux/event/eventActions';
import store from '../../../store';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {isMaster} from '../../../functions/permission';
import ModeBtn from '../../../component/modeBtn/ModeBtn';
import * as css from '../settingItem/SettingItem.css';

export const onConfirmPortalRequest=(portalType)=>{
    bounceMessageBlock(i18next.t('event:confirmPortalTitle'), i18next.t('event:confirmPortalText',{eventName:store.getState().event.title}),
        {text:i18next.t('event:approve'),onClick:()=>updateEventSettings(`${portalType}Index`, 1, 'event')},{text:i18next.t('event:reject'),onClick:()=>updateEventSettings(`${portalType}Index`, 0, 'event')});
};

function MailingForm() {
    const {t} = useTranslation('event');
    const showInLive = useSelector(s=>s.event.settings.event.showInLive);
    const tickchakIndex = useSelector(s=>s.event.settings.event.tickchakIndex);
    const glatticketIndex = useSelector(s=>s.event.settings.event.glatticketIndex);
    
    const getGlatticketStatus = () => {
        if(glatticketIndex==2)
            return 'message';
        else
            return glatticketIndex;
    };
    
    const handleConnectedToggle = (primarySetting, secondarySetting, primaryValue, secondaryValue) => {
        updateEventSettings(primarySetting, !primaryValue, 'event');
        if(!primaryValue && !secondaryValue) {
            updateEventSettings(secondarySetting, true, 'event');
        }
    };
    
    return (
        <>
            <SettingItem
                name={'tickchakIndex'}
                settingChildren={
                    <css.toggle>
                        <ModeBtn
                            click={() => handleConnectedToggle('tickchakIndex', 'showInLive', tickchakIndex, showInLive)}
                            status={tickchakIndex ? 'active' : 'inactive'}
                            requestStatus={false}
                            disabled={false}
                            dataCyName={'tickchakIndex'}
                        />
                    </css.toggle>
                }
            >
                {isMaster() && <SettingItem name={'promotionInTickchak'}/>}
            </SettingItem>
            <MasterPermission>
                <SettingItem
                    name={'showInLive'}
                    settingChildren={
                        <css.toggle>
                            <ModeBtn
                                click={() => handleConnectedToggle('showInLive', 'tickchakIndex', showInLive, tickchakIndex)}
                                status={showInLive ? 'active' : 'inactive'}
                                requestStatus={false}
                                disabled={false}
                                dataCyName={'showInLive'}
                            />
                        </css.toggle>
                    }
                >
                    <SettingItem name={'promotionInLive'} />
                </SettingItem>
            </MasterPermission>
            <SettingItem name={'glatticketIndex'} getStatus={getGlatticketStatus} modeBtnText={glatticketIndex==2?t('isWait'):''}>
                {isMaster() && <SettingItem name={'promotionInGlatticket'}/>}
            </SettingItem>
            <SettingItem name={'isIndexed'}>
                <AddGooglePingItem />
            </SettingItem>
        </>
    );
}

export default MailingForm;