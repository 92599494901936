import styled from 'styled-components';
import {BLACK} from '../../globalVariables/GlobalVariables';

export const videoContainer = styled.div`
  width: 280px;
  height: 140px;
  iframe{
    border-radius: 5px;
  }
`;

export const videoIframe = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  iframe{
    background-color: ${BLACK};
  }
`;