import * as css from './TransactionsQueue.css';
import QueueProcessStatus, {getTaskState} from '../queueProcessStatus/QueueProcessStatus';
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import {getSuspendedQueue, getTransactionsDetails} from '../../redux/event/eventActions';
import TransactionsReport from './transactionsReport/TransactionsReport';
import useShow from '../../hooks/useShow';
import {useSelector} from 'react-redux';

function TransactionsQueue() {
    const {t} = useTranslation('realtime');
    const suspended=useSelector((state: any) => state.ui.tickchakQueue?.suspended);

    const reportShow = useShow();

    const [data, setData] = useState({amount:0, sold:0});

    const action = (async ()=>{
        const res = await getSuspendedQueue(suspended.eid, suspended.tsqid);
        if(res) {
            if(res?.success){
                await getTransactionsDetails(suspended.eid, suspended.tsqid);
                reportShow.open();
            }
            setData({amount: res?.allTransactions, sold: res?.executedTransactions});
        }
        return res;
    });

    useEffect(()=>{
        if(suspended?.tsqid && suspended?.eid ){
            getTaskState(3000, action, 'suspended');
        }
    }, [suspended?.eid]);

    return <>
        {!!suspended && !!suspended.tsqid && !!suspended.eid &&
        <QueueProcessStatus amount={data.amount} sold={data.sold}>
            <css.title>{t('transactionProcessTitle')}</css.title>
            <css.goalText>{data.sold}/{data.amount}</css.goalText>
        </QueueProcessStatus>}
        {!!reportShow.isOpen && <TransactionsReport show={reportShow}/>}
    </>;
}
export default TransactionsQueue;
