import React from 'react';

function TickchakIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="27" viewBox="0 0 16 27" fill="none">
            <g clipPath="url(#clip0_7486_14009)">
                <path d="M13.0156 4.64969L13.7963 4.06489L13.9557 3.49097L13.6869 2.74548L13.1426 2.48419C13.1628 2.33799 12.8339 2.10117 12.7519 2.21958L12.4238 2.1927L12.5805 3.99723L13.0156 4.64969Z" fill="#71C3CF"/>
                <path d="M5.59578 3.99776L4.7972 4.64962L4.74609 9.45576L5.34057 12.1535L6.57185 14.5014L10.7126 13.4687H12.636C12.636 13.4687 13.4709 12.4281 13.5232 12.2906C13.5755 12.1532 14.0726 9.40078 14.0726 9.40078L13.8676 7.74517L13.4056 4.64931L12.4229 3.83374L5.59578 3.99776Z" fill="#D6F0F6"/>
                <path d="M13.4126 4.50666C13.159 4.32754 12.9603 4.08131 12.8389 3.79563C12.7176 3.50995 12.6783 3.19613 12.7256 2.88941C12.7399 2.73505 12.7196 2.10404 12.6513 2.23967L12.3837 2.27199L11.9141 3.73005L12.6159 4.84769L12.8551 5.42795L12.8345 4.96791L13.4126 4.50666Z" fill="#92D5D4"/>
                <path d="M4.86616 4.40503L4.79692 4.65121L4.34184 5.23359L4.22754 5.90538L4.71134 9.16767L4.74612 9.46732C4.74612 9.46732 5.09325 9.08853 5.14465 9.0254C5.21452 8.62422 5.26277 8.21957 5.28919 7.81322L5.1894 5.90448L4.96867 4.83638L4.86616 4.40503Z" fill="#3F8FB4"/>
                <path d="M13.7962 4.06543L14.3018 4.16028L14.5999 4.97132L14.5171 6.59612L14.2195 7.74578L14.0218 5.23351L13.5234 4.36387L13.7962 4.06543Z" fill="#3F8FB4"/>
                <path d="M13.0159 4.83967L12.6367 5.10066L12.9482 5.79541L14.0222 9.34526L14.22 8.37866L14.0222 5.89086L13.7331 4.75329L13.3621 4.53882L13.0159 4.83967Z" fill="#71C3CF"/>
                <path d="M15.1701 17.9686V20.1133L13.7299 22.4392L11.7417 24.2935L9.40918 26.5143L12.8109 21.7526L14.0204 19.6294V17.9529L13.8596 16.7332C13.3023 13.9143 14.2808 15.199 14.6216 15.1054L15.1701 17.9686Z" fill="#51A5CC"/>
                <path d="M14.0724 12.2307L14.6793 15.4024C14.3513 15.4622 14.0158 15.4671 13.6862 15.4169V15.4108L12.9766 12.6291L14.0736 9.45532L14.0724 12.2307Z" fill="#51A5CC"/>
                <path d="M13.6867 15.4173L14.0214 17.9519V19.6284L12.8119 21.7516L9.41016 26.5133L11.9631 21.5425L13.0169 19.3906V17.8818L12.8588 15.03" fill="#3F8FB4"/>
                <path d="M13.6857 15.4123V15.4183C13.406 15.3767 13.1314 15.3058 12.8665 15.2068L12.7305 12.7656L12.9745 12.6296L13.6857 15.4123Z" fill="#3F8FB4"/>
                <path d="M13.0159 17.6514V19.3916L11.9622 21.5435L9.40918 26.5143L11.7786 19.1364V17.5391L11.9631 13.0196C12.3184 13.1214 12.4638 12.9728 12.7317 12.7659L13.0159 17.6514Z" fill="#92D5D4"/>
                <path d="M5.45934 0.914274L12.4231 2.19321C12.4231 2.19321 11.4522 3.03658 12.8056 5.09998C12.8104 5.10688 12.813 5.11512 12.8129 5.12354C12.8128 5.13061 12.8109 5.13755 12.8074 5.1437C12.8039 5.14985 12.7989 5.15502 12.7929 5.15873C12.7868 5.16244 12.7799 5.16456 12.7729 5.1649C12.7658 5.16525 12.7588 5.1638 12.7524 5.16069C12.2157 4.87464 10.3963 3.96572 9.41051 4.24695C8.24636 4.57922 6.14272 4.74716 5.12371 4.43936C3.65052 3.99382 3.9142 0.739077 5.02604 0.318604L5.45934 0.914274Z" fill="#92D5D4"/>
                <path d="M10.3431 13.5039L10.3008 13.4236L10.4822 13.329L11.9639 12.9143" fill="#3F8FB4"/>
                <path d="M11.9612 13.0681L11.8191 16.5497L11.7786 19.1366L9.40918 26.5145L10.2323 19.5719L10.2909 16.3147L10.2326 13.5886C11.1318 12.857 11.7157 13.2092 11.9612 13.0681Z" fill="#71C3CF"/>
                <path d="M10.7129 13.4687L10.2896 16.3145L9.40879 16.8455L8.40852 16.2414H8.40641C8.46204 16.0571 7.5032 16.3954 7.52376 16.3347C7.76022 16.2187 8.26368 13.2234 8.6474 13.3721C8.7883 13.4117 8.91923 13.4806 9.03163 13.5743C9.14402 13.668 9.23534 13.7844 9.29962 13.9158C9.29962 13.9158 10.133 13.4168 10.7129 13.4687Z" fill="#51A5CC"/>
                <path d="M10.2914 16.3145L10.2327 19.5716L9.40966 26.5143L8.68395 25.0991C8.48106 24.6551 8.51704 24.6901 8.563 24.5717C8.91225 23.6957 8.20983 21.426 7.95825 19.8012C7.76957 18.593 8.18171 16.9866 8.40637 16.2405H8.40849L9.40875 16.8446L10.2896 16.3136L10.2914 16.3145Z" fill="#51A5CC"/>
                <path d="M8.48102 24.6553L9.40931 26.5145L8.37125 25.0679C8.21281 24.8568 8.41812 24.7102 8.48102 24.6553Z" fill="#3F8FB4"/>
                <path d="M8.32021 24.9038L9.40302 26.4156L8.38854 25.5366L8.0127 25.2901C8.0765 25.2198 8.27395 25.0754 8.32021 24.9038Z" fill="#71C3CF"/>
                <path d="M6.69852 21.2205L7.27758 21.9285C7.27758 21.9285 7.0387 20.1696 7.0387 19.9047C7.0387 19.7041 7.05321 19.4416 7.08406 19.142C7.17344 18.1122 7.43499 17.1048 7.85784 16.1615C8.10518 16.0636 8.06467 15.9618 8.26151 15.8643C8.24095 15.9247 8.4626 16.0567 8.40696 16.2409C8.18229 16.987 7.77015 18.5946 7.95883 19.8017C8.21132 21.4268 8.80549 23.8472 8.45745 24.7229C8.40516 24.8529 8.3432 24.9788 8.2721 25.0996C8.23062 25.1717 8.18519 25.2415 8.13602 25.3086C8.08341 25.3817 7.97456 25.3246 7.91075 25.3935C6.98911 26.4054 6.29576 26.0112 5.71277 25.64C5.44892 25.4695 5.21412 25.2579 5.0173 25.0132L3.63574 23.3216C4.28807 23.2482 4.90547 22.9888 5.41424 22.5744C5.92301 22.16 6.30167 21.6081 6.505 20.9845L6.69762 21.2202L6.69852 21.2205Z" fill="#3F8FB4"/>
                <path d="M8.58732 13.3288L8.6478 13.3711C8.13171 13.8895 7.55436 14.3433 6.92848 14.7223L6.85742 12.9355L7.69198 13.1866C7.98629 13.2571 8.28564 13.3046 8.58732 13.3288Z" fill="#3F8FB4"/>
                <path d="M7.27712 21.9285L6.69807 21.2204L6.5239 20.8655L5.80273 19.3929V17.8841L5.95755 15.103C6.35064 15.0638 6.57349 14.8203 6.92788 14.7236L7.04006 17.5404V19.1353L7.0833 19.1404C7.05307 19.4398 7.03794 19.7023 7.03794 19.9031C7.03825 20.1695 7.27712 21.9285 7.27712 21.9285Z" fill="#71C3CF"/>
                <path d="M8.69044 13.2761C8.24625 14.1521 7.17523 18.2523 7.08361 19.1416L7.04037 19.1365L7.01013 16.8073L6.9043 14.1491C6.9179 15.1402 8.30279 13.4993 8.69044 13.2761Z" fill="#3F8FB4"/>
                <path d="M6.85732 12.9362L6.92838 14.723C6.6181 14.9106 6.29164 15.0701 5.95291 15.1996L6.08777 12.7668L5.84375 12.6309L6.85732 12.9362Z" fill="#71C3CF"/>
                <path d="M5.69935 21.2092C5.96415 21.0765 6.23785 20.9623 6.51849 20.8675L6.52454 20.8645L6.69871 21.2194L6.5061 20.9838C6.30275 21.6073 5.92413 22.1591 5.41543 22.5735C4.90672 22.9879 4.2894 23.2474 3.63714 23.3209L3.57031 23.2907C3.82771 22.7206 4.22248 22.2231 4.71935 21.8426C5.02167 21.596 5.35033 21.3836 5.69935 21.2092Z" fill="#3F8FB4"/>
                <path d="M5.80326 19.3915L6.52442 20.8641L6.51838 20.8671C6.23775 20.9619 5.96404 21.076 5.69924 21.2087L4.79785 19.6289V17.9525L5.15496 15.3278C5.30766 15.3417 5.46143 15.3178 5.60263 15.2581C5.74384 15.1984 5.86811 15.1048 5.96442 14.9856L5.80326 17.6211V19.3915Z" fill="#51A5CC"/>
                <path d="M6.08772 12.7673L5.95286 15.2001C5.68778 15.3014 5.41299 15.3751 5.13281 15.4203V15.4131L5.84249 12.6313L6.08772 12.7673Z" fill="#51A5CC"/>
                <path d="M4.74661 9.4563L5.84394 12.6301L5.13426 15.4118V15.419C4.82798 15.4693 4.51567 15.4706 4.20898 15.423L4.2111 15.4118L4.74782 12.2308L4.74661 9.4563Z" fill="#3F8FB4"/>
                <path d="M4.79814 19.6295L5.69953 21.2093C5.35057 21.384 5.02201 21.5967 4.71983 21.8436L3.64941 20.1158V17.9711L3.99866 16.523C4.10449 16.5532 4.29408 15.1477 4.5127 14.63C4.96264 13.5643 5.53534 12.9249 5.75245 12.9384L4.79935 17.9554L4.79814 19.6295Z" fill="#3F8FB4"/>
                <path d="M6.23145 1.48193H7.97223L8.32178 1.9157L8.40766 3.03696L8.99155 4.16034V4.35124L7.64869 4.61857C7.64869 4.61857 7.12739 4.70677 7.12376 4.58836C6.97952 4.06001 6.80053 3.54175 6.58795 3.03696C6.55348 3.05025 6.58795 1.76829 6.58795 1.76829L6.23145 1.48193Z" fill="#71C3CF"/>
                <path d="M4.42383 1.59644H6.16583L6.51537 2.0302L6.60125 3.32908L7.18514 4.26548V4.45638L5.84228 4.72371C5.84228 4.72371 5.32098 4.81191 5.31735 4.6935C5.1747 4.22502 4.99554 3.76841 4.78154 3.32787C4.74707 3.33995 4.78154 1.88128 4.78154 1.88128L4.42383 1.59644Z" fill="#92D5D4"/>
                <path d="M7.85938 1.39941H9.60016L9.94971 1.83318L10.0356 2.95444L10.6195 4.08416V4.27507L9.31774 4.34937C9.31774 4.34937 8.75502 4.63059 8.75139 4.51218C8.60712 3.98178 8.42813 3.46141 8.21558 2.95444C8.18111 2.96773 8.21558 1.68577 8.21558 1.68577L7.85938 1.39941Z" fill="#3F8FB4"/>
                <path d="M9.40956 13.9559L8.61521 19.674L9.46882 26.6166L8.94207 24.5711L7.98535 19.9613L8.65482 13.5823L9.40956 13.9559Z" fill="#8ED4D7"/>
                <path d="M7.26521 21.7524C7.24434 22.4471 7.35592 23.4826 6.76296 23.4826C6.46421 23.4826 5.62965 22.3194 5.62965 22.3194L5.04395 23.0141C5.04395 23.0141 6.11285 24.4743 6.15457 24.5C6.1963 24.5256 7.2129 24.963 7.34262 24.8473C7.47234 24.7316 7.94737 24.4356 7.97368 24.1912C7.99999 23.9469 7.97368 22.5574 7.97368 22.5574C7.97368 22.5574 7.58724 20.9293 7.58724 20.8685C7.58724 20.8078 7.14789 19.009 7.14789 19.009L7.07017 20.2819L7.26521 21.7524Z" fill="#51A5CC"/>
                <path d="M8.15435 16.0251C7.94429 16.5532 7.77813 17.0977 7.65754 17.653C7.35517 19.1736 7.6134 20.6546 8.08329 22.1154C8.24049 22.6356 8.31438 23.1773 8.30221 23.7205C8.29617 24.2621 8.10295 24.9502 7.57469 25.2309C6.65093 25.7238 5.69451 24.6905 5.69451 24.6905L4.4209 23.1372L5.10246 22.7983C5.10246 22.7983 6.19706 24.4597 6.81512 24.4464C7.43318 24.4331 7.96113 23.9963 7.79392 22.5041C7.72709 21.9094 6.80998 19.0071 7.26597 17.8342L8.15435 16.0251Z" fill="#71C3CF"/>
                <path d="M4.87047 22.2336C5.20475 21.9843 5.55284 21.7541 5.91306 21.5439C5.65435 21.8779 5.3581 22.181 5.03012 22.4474C4.74267 22.667 4.42914 22.8502 4.09668 22.9929C4.32327 22.7098 4.58306 22.4549 4.87047 22.2336Z" fill="#92D5D4"/>
                <path d="M6.18193 4.58792C4.24671 3.09995 5.45955 0.914217 5.45955 0.914217L4.95065 0.490723C4.95065 0.490723 3.54459 3.0924 5.4786 4.58792H6.18193Z" fill="#71C3CF"/>
                <path d="M7.83371 4.48491C5.8985 3.0202 6.9166 1.38392 6.9166 1.38392L5.95262 1.13623C5.697 1.73874 5.65684 2.41079 5.83889 3.0394C6.02095 3.66801 6.41411 4.21483 6.95228 4.58792L7.83371 4.48491Z" fill="#3F8FB4"/>
                <path d="M9.55693 4.24733C7.62172 2.90284 8.63983 1.42062 8.63983 1.42062L7.77805 1.3457C7.77805 1.3457 6.88725 2.92218 8.82125 4.4038L9.55693 4.24733Z" fill="#51A5CC"/>
                <path d="M12.8037 5.10065C11.8186 3.85826 12.4106 2.79711 12.6498 2.2398C12.6592 2.21971 12.6638 2.19769 12.6632 2.17551C12.6627 2.15333 12.6569 2.13159 12.6464 2.11202C12.6359 2.09246 12.621 2.07561 12.6029 2.06281C12.5847 2.05002 12.5638 2.04163 12.5419 2.03832L11.9395 1.98577C11.9182 1.98403 11.8968 1.98776 11.8774 1.99658C11.8579 2.00541 11.841 2.01905 11.8283 2.03621C11.5709 2.37422 10.3466 3.54351 12.0913 4.87954L12.8037 5.10065Z" fill="#51A5CC"/>
                <path d="M11.4134 4.54096C9.3984 3.16415 10.1689 1.517 10.1689 1.517L9.45858 1.44873C9.45858 1.44873 8.44168 2.91435 10.3757 4.24736L11.4134 4.54096Z" fill="#71C3CF"/>
                <path d="M11.9961 4.80149C11.0841 2.58947 11.9788 1.98534 11.9788 1.98534L10.8987 1.54614C9.76724 3.41894 11.6381 4.61904 11.6381 4.61904L11.9961 4.80149Z" fill="#3F8FB4"/>
                <path d="M14.0902 9.03171C14.1933 4.65903 13.4053 4.64996 13.4053 4.64996L13.6435 4.16032C13.6435 4.16032 14.6084 4.06426 14.2005 8.59009" fill="#92D5D4"/>
                <path d="M4.79745 9.10059C4.61058 7.68967 4.59647 6.26126 4.75542 4.84692L4.49961 4.89707L4.34237 5.3272L4.28522 5.56886L4.22656 5.90536L4.37775 7.00396L4.79745 9.10059Z" fill="#71C3CF"/>
                <path d="M4.98833 9.02655C4.90033 8.1119 4.98833 5.77331 4.98833 5.77331L5.15373 5.73193L5.23113 6.69854V8.07021L4.98833 9.02655Z" fill="#92D5D4"/>
                <path d="M13.0153 4.83953L12.751 5.16092L13.4056 7.60764L14.0216 9.1678C13.6608 5.0401 13.0153 4.83862 13.0153 4.83862" fill="#92D5D4"/>
                <path d="M8.69126 15.3154C8.69126 15.3154 7.70611 18.2439 7.9586 19.8687C8.21108 21.4935 8.80707 23.7083 8.45692 24.5846C8.13186 25.398 7.27765 26.5145 5.71315 25.7067C5.44101 25.5476 5.20434 25.3345 5.01768 25.0806L3.63672 23.389C4.28891 23.3154 4.90615 23.056 5.41483 22.6416C5.92352 22.2273 6.30218 21.6756 6.50568 21.0522L7.27644 21.9965C7.27644 21.9965 7.03756 20.2376 7.03756 19.9727C7.18101 18.4405 7.57159 16.9417 8.19415 15.5341" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinejoin="round"/>
                <path d="M14.6002 14.7224L15.1705 18.0352V20.1816L13.7302 22.5075L11.7412 24.361L9.40956 26.5817L8.0791 25.2959" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path d="M4.66419 21.8197L3.64941 20.1816V18.0352L4.29499 14.7224" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path d="M12.8055 5.16788C12.7752 5.20051 12.7897 5.24884 12.751 5.2283C12.2137 4.94164 10.3949 4.03333 9.40947 4.31455C8.24441 4.64682 6.14108 4.81477 5.12206 4.50666C3.64888 4.06112 3.71389 1.44011 4.9491 0.490723C4.9491 0.490723 5.2536 1.32533 8.2813 1.46186C15.2937 1.77782 13.8989 3.96234 13.8989 3.96234C13.8989 3.96234 13.3612 4.5411 12.8055 5.16788Z" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path d="M12.8105 5.14446L13.7963 4.06519C14.7479 4.06519 14.652 5.81082 14.652 5.81082L14.073 9.46821L12.901 5.71476L12.8105 5.14446Z" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path d="M5.1217 4.50684C5.00666 4.68977 4.94562 4.90142 4.94562 5.11746C4.94562 5.3335 5.00666 5.54515 5.1217 5.72808C5.49786 6.47629 5.1217 8.88677 5.1217 8.88677L4.74585 9.46854L4.24995 6.14372C4.17596 5.81185 4.22315 5.4645 4.38301 5.16434C4.54286 4.86418 4.80486 4.63099 5.1217 4.50684Z" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path d="M9.81055 8.021L11.5589 8.26657" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path d="M9.83008 12.7659H9.96101" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round"/>
                <path d="M9.18562 12.7659H9.05469" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round"/>
                <path d="M3.62402 23.3633C3.62402 23.3633 4.13806 21.8327 6.49116 21.0347" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round"/>
                <path d="M4.75092 22.3723C4.65514 22.4418 4.56579 22.5198 4.48392 22.6052C4.37295 22.7218 4.37748 22.762 4.39018 22.7562C4.39018 22.7562 4.46336 22.7774 4.69589 22.6411C4.78542 22.5907 4.86864 22.5299 4.94383 22.4599" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round"/>
                <path d="M3.10459 12.4112C2.59055 13.2983 1.53041 12.3021 1.07715 11.7638C1.1092 11.6805 1.14398 11.5983 1.18117 11.518C1.42519 10.9912 2.11551 9.93213 2.79133 10.5227C2.85896 10.5803 2.91431 10.6509 2.95409 10.7303C2.99388 10.8097 3.01729 10.8963 3.02293 10.9849C3.02857 11.0735 3.01633 11.1623 2.98693 11.2461C2.95753 11.3299 2.91158 11.4069 2.8518 11.4726C3.00979 11.5637 3.12515 11.7137 3.17254 11.8897C3.21994 12.0656 3.1955 12.2532 3.10459 12.4112Z" fill="#51A5CC"/>
                <path d="M3.10459 12.4112C2.59055 13.2983 1.53041 12.3021 1.07715 11.7638C1.1092 11.6805 1.14398 11.5983 1.18117 11.518C1.42519 10.9912 2.11551 9.93213 2.79133 10.5227C2.85896 10.5803 2.91431 10.6509 2.95409 10.7303C2.99388 10.8097 3.01729 10.8963 3.02293 10.9849C3.02857 11.0735 3.01633 11.1623 2.98693 11.2461C2.95753 11.3299 2.91158 11.4069 2.8518 11.4726C3.00979 11.5637 3.12515 11.7137 3.17254 11.8897C3.21994 12.0656 3.1955 12.2532 3.10459 12.4112Z" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path d="M4.40803 21.3821C4.65991 20.8854 4.75682 20.3247 4.68621 19.7724C4.6365 19.3918 4.52293 19.0224 4.35027 18.6795C3.43739 16.8508 1.11483 15.9277 0.840274 13.713C0.760589 13.0537 0.841927 12.3849 1.07734 11.7638C1.04317 11.7236 1.01233 11.6855 0.986625 11.6517C0.500536 11.0177 0.213253 10.2541 0.161002 9.45722C0.10875 8.66033 0.293869 7.86583 0.693016 7.1739C1.19616 6.30107 2.01031 5.64989 2.97294 5.35034" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round"/>
                <path d="M10.3359 8.60156L10.8485 8.70094" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path d="M8.96124 8.021L7.21289 8.26657" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path d="M8.43538 8.60156L7.92285 8.70094" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path d="M8.76855 15.1905L9.69323 14.8132L10.138 15.5705L9.45314 15.7907L8.85383 15.595L8.76855 15.1905Z" fill="#71C3CF"/>
                <path d="M3.39941 13.3659C3.39941 13.3659 5.23515 15.9033 9.61569 14.2776C9.61569 14.2776 13.8538 15.7513 15.5577 13.3659C13.7326 14.8935 10.9803 13.1726 10.9803 13.1726L9.51258 13.958L8.64778 13.3723L7.98527 13.1738C7.98527 13.1738 6.06638 14.7881 3.39941 13.3659Z" fill="#71C3CF"/>
                <path d="M4.49023 13.8128L4.74635 12.2977V9.52319L5.84368 12.6973L8.58685 13.3963L9.51272 13.9569L10.4803 13.3963L12.9752 12.6973L14.0726 9.52319V12.2977L14.4911 14.0176" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path d="M8.57812 15.3785L9.23458 15.7359C9.32189 15.7834 9.42015 15.8073 9.51957 15.8051C9.61898 15.8029 9.7161 15.7748 9.80125 15.7235L10.416 15.3525" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path d="M3.39941 13.366C3.53034 13.7514 5.54327 17.4665 9.61569 14.9343C9.78623 15.2823 13.4982 17.0566 15.4392 13.5831C13.7522 15.3523 10.7127 13.9154 10.3683 13.6556L9.53767 14.1296L9.1857 13.9505L8.76903 13.6801C8.76903 13.6801 5.4583 15.8024 3.39941 13.366Z" fill="#51A5CC"/>
                <path d="M7.7983 13.2825C7.12279 13.8978 4.73794 14.5955 3.35547 13.2761C3.35547 13.2761 5.44187 17.6923 9.57447 14.8339" stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11.2273 13.2073C11.9025 13.8226 14.3152 14.5889 15.6974 13.2695C15.6974 13.2695 13.7056 17.692 9.57422 14.8339" stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_7486_14009">
                    <rect width="16" height="27" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default TickchakIcon;
